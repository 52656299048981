import React from 'react'
import { Box, Divider, Grid, InputLabel, TextField, Typography, } from "@mui/material";
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import CommonButton from '../../Components/Common/Button/CommonButton';
import { makeStyles } from "tss-react/mui";
import { useTheme } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import AutoCompleteSearch from '../Common/commonAutoComplete';
import { globalAmountConfig } from '../../Utils/globalConfig';
import DataNotFound from '../Common/DataNotFound';
import Swal from 'sweetalert2';

const useStyles = makeStyles()((theme) => {
    return {
        customGridItem: {
            paddingTop: '5px !important', // Adjust the margin top as needed
        },
        dateBox: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '8px',
            minWidth: "50px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "12.5px 14px",
                fontSize: "14px !important",
                height: "18px",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: "8px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
            }
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
    };
});
const AddFinancialData = ({ data, error, handleChange, isEdit, onSubmit, setSelectedClient, selectedClient, clients, user, setUserPurchasePlanDelete, setUserPurchasePlanAdd, setData }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    {data?.isGenerateId && <TextLabel fontSize={"14px"} color={theme.palette.error.main} title={"UserId is already generateId !"} />}
                    <AutoCompleteSearch
                        fullWidth
                        backgroundColor="white"
                        text="Client"
                        placeholder={"Select Client"}
                        handleChange={(e, newValue) => setSelectedClient(newValue)}
                        options={clients?.map((e) => e?.name) || []}
                        name="selectedClient"
                        defaultValue={selectedClient || ""}
                        freeSolo
                        blurOnSelect
                        disabled={isEdit ? true : false}
                    />
                    <TextLabel fontSize={"12px"} color={theme.palette.error.main} title={!selectedClient ? error?.selectedClient : ""} />
                </Grid>
                <Divider />
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                        <TextLabel fontWeight={600} fontSize={16} title={'Investment Details'} />
                        <Box display={'flex'} gap={2} alignItems={'center'} >
                            <CommonButton
                                width={'120px'}
                                text={'Add Plan'}
                                onClick={() => setUserPurchasePlanAdd()}
                                startIcon={<AddIcon />}
                            />
                        </Box>
                    </Box>
                </Grid>
                {data?.userPurchasePlan?.length > 0 ? data?.userPurchasePlan?.map((e, i) => {
                    return (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                               <Box width={'100%'} border={`1px solid #e2e2e2`} borderRadius={"10px"} bgcolor={'#f9f9f9'}>

                                {e?._id == null ? 
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.customGridItem}>
                                    <Box display={"flex"} style={{ cursor: "pointer" }} margin={'0px 7px 0px'} justifyContent={'end'} onClick={() => setUserPurchasePlanDelete(i)}>
                                        <CloseIcon sx={{ color: theme.palette.bgWhite.main, bgcolor: '#F14336', borderRadius: 1, fontSize: "16px", marginRight: "1px", cursor: "pointer" }} onClick={() => setUserPurchasePlanDelete(i)} />
                                    </Box>

                                    {/* {isEdit && e?._id !== null ? <Box display={"flex"} style={{ cursor: "pointer" }} margin={'4px 1px'} justifyContent={'end'} onClick={() => Swal.fire({
                                        title: "<strong>Warning</strong>",
                                        icon: "warning",
                                        html: "Are you sure you want to delete plan?",
                                        showCancelButton: true,
                                        confirmButtonColor: "#0492c2",
                                        iconColor: "#0492c2",
                                        confirmButtonText: "Yes",
                                        cancelButtonColor: "#1A1B2F",
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            // deleteUserPlan(e?._id)
                                        }
                                    })}>
                                        <DeleteForeverIcon sx={{ color: "#F14336", borderRadius: 1, fontSize: "22px", marginRight: "1px", backgroundColor: "" }} />
                                    </Box> :
                                        <Box display={"flex"} style={{ cursor: "pointer" }} margin={'8px 8px'} justifyContent={'end'} onClick={() => setUserPurchasePlanDelete(i)}>
                                            <CloseIcon sx={{ color: "#F14336", borderRadius: 1, fontSize: "16px", marginRight: "1px", border: "0.5px dashed #F14336", cursor: "pointer" }} onClick={() => setUserPurchasePlanDelete(i)} />
                                        </Box>
                                    } */}
                                </Grid> : ""}

                                <Grid item container padding={'1px 5px 10px 5px'} spacing={1} >
                                    <Grid item xs={6} sm={3} md={3} lg={3} className={classes.customGridItem}>
                                        <CommonTextField
                                            fontWeight={400}
                                            text={'Investment Amount'}
                                            type='text'
                                            name='currentInvestment'
                                            value={globalAmountConfig(e?.currentInvestment)}
                                            onChange={(e) => handleChange(e, true, i)}
                                            fontSize='12px'
                                        />
                                        <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!e?.currentInvestment ? error?.currentInvestment : ""} />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={2} lg={3} className={classes.customGridItem}>
                                        <CommonTextField
                                            fontWeight={400}
                                            text={'Investment Days'}
                                            type='number'
                                            name='investmentDays'
                                            value={e?.investmentDays}
                                            onChange={(e) => handleChange(e, true, i)}
                                            fontSize='12px'
                                        />
                                        <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!e?.investmentDays ? error?.investmentDays : ""} />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={2} lg={3} className={classes.customGridItem}>
                                        <CommonTextField
                                            fontWeight={400}
                                            text={'Return Of Investment (%)'}
                                            type='text'
                                            name='returnOfPercentage'
                                            value={globalAmountConfig(e?.returnOfPercentage)}
                                            onChange={(e) => handleChange(e, true, i)}
                                            fontSize='12px'
                                        />
                                        <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!e?.returnOfPercentage ? error?.returnOfPercentage : ""} />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={4} lg={3} className={classes.customGridItem}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                                                <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Date</InputLabel>
                                            </Box>
                                            <DesktopDatePicker
                                                className={classes.dateBox}
                                                inputFormat="MM/DD/YYYY"
                                                value={dayjs(e?.planDate)}
                                                onChange={(newValue) => {
                                                    const modifyData = { ...data };
                                                    if (modifyData.userPurchasePlan && modifyData.userPurchasePlan[i]) {
                                                        modifyData.userPurchasePlan[i]['planDate'] = newValue;
                                                    }
                                                    setData(modifyData);
                                                }}
                                                name='planDate'
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!e?.planDate ? error?.planDate : ""} />
                                    </Grid>

                                </Grid>
                            </Box>
                        </Grid>
                    );
                }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataNotFound icon={<ErrorOutlineIcon color="error" style={{ fontSize: "3rem" }} />} elevation={0} title={'No Plan Added!'} />
                    <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={data?.userPurchasePlan?.length == 0 ? error?.validatePlan : ""} />
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'180px'}
                            text={`${isEdit ? "Update" : "Add"} Financial Data`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AddFinancialData