import React from 'react'

const InfoIcon = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 29.3333C23.3334 29.3333 29.3334 23.3333 29.3334 16C29.3334 8.66667 23.3334 2.66667 16 2.66667C8.66669 2.66667 2.66669 8.66667 2.66669 16C2.66669 23.3333 8.66669 29.3333 16 29.3333Z" stroke={props.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 10.6667V17.3333" stroke={props.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.9927 21.3333H16.0047" stroke={props.color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default InfoIcon