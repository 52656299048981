import React from 'react'

const CalenderIcon = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6667 2.66666V6.66666" stroke={props.color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.3333 2.66666V6.66666" stroke={props.color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.66669 12.12H27.3334" stroke={props.color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M25.6134 21.0267L20.8934 25.7467C20.7067 25.9333 20.5334 26.28 20.4934 26.5333L20.24 28.3334C20.1467 28.9867 20.6 29.44 21.2534 29.3467L23.0534 29.0934C23.3067 29.0534 23.6667 28.88 23.84 28.6933L28.56 23.9734C29.3734 23.16 29.76 22.2134 28.56 21.0134C27.3734 19.8267 26.4267 20.2133 25.6134 21.0267Z" stroke={props.color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.9333 21.7067C25.3333 23.1467 26.4534 24.2667 27.8934 24.6667" stroke={props.color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 29.3333H10.6667C6 29.3333 4 26.6667 4 22.6667V11.3333C4 7.33333 6 4.66666 10.6667 4.66666H21.3333C26 4.66666 28 7.33333 28 11.3333V16" stroke={props.color} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.994 18.2667H16.006" stroke={props.color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.0591 18.2667H11.071" stroke={props.color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.0591 22.2667H11.071" stroke={props.color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default CalenderIcon