import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, TableContainer, Box, Grid, TablePagination, Pagination, PaginationItem, useTheme, IconButton, Menu, MenuItem, Fab } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { Regex } from "../../Utils/regex";
import AddUser from "../../Components/User";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import swal from "sweetalert";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Roles, permissionStatus, roles, userType } from "../../Utils/enum";
import VisitorModel from "../../Components/VisitorModel";
import CommonButton from "../../Components/Common/Button/CommonButton";
import CustomerModel from "../../Components/CustomerModel";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";
import dayjs from "dayjs";
import AutoCompleteSearch from "../../Components/Common/commonAutoComplete";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import MoreVertIcon from '@mui/icons-material/MoreVert';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    padding: 8
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: theme.palette.bgLightExtraPrimary.main,
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgLightSuccess.main,
      backgroundColor: theme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: theme.palette.bgLightRed.main,
      backgroundColor: theme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
  };
});

const User = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader, user, menuList } = useAppContext();
  const location = useLocation()
  const { pathname } = location
  const theme = useTheme()
  //States
  const [model, setModel] = useState(false);
  const [visitorModel, setVisitorModel] = useState(false);
  const [customerModel, setCustomerModel] = useState(false);
  const [data, setData] = useState({ userPurchasePlan: [{ _id: null, investment: "", investmentDays: "", returnOfInvestment: "" }] });
  const [error, setError] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [multiSelectedBranch, setMultiSelectedBranch] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({})
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [filterByRole, setfilterByRole] = useState("")

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Validation

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!data?.name) {
      formIsValid = false;
      errors["name"] = "Please enter name.";
    }
    if (!data?.address) {
      formIsValid = false;
      errors["address"] = "Please enter address.";
    }
    if (!selectedCountry) {
      formIsValid = false;
      errors["country"] = "Please select country.";
    }
    if (!selectedState) {
      formIsValid = false;
      errors["state"] = "Please select state.";
    }
    if (!selectedCity) {
      formIsValid = false;
      errors["city"] = "Please select city.";
    }

    if (!data?.mobileNo) {
      formIsValid = false;
      errors["mobileNo"] = "Please enter Contact No.";
    } else if (!data?.mobileNo?.match(Regex.mobileNumberRegex)) {
      formIsValid = false;
      errors["invalidMobile"] = "Please enter valid Contact No.";
    }
    if (!visitorModel) {
      if (!data?.email) {
        formIsValid = false;
        errors["email"] = "Please enter email.";
      } else if (!data?.email?.match(Regex.emailRegex)) {
        formIsValid = false;
        errors["invalidEmail"] = "* Invalid email Address";
      }
      if (!data?.postalCode) {
        formIsValid = false;
        errors["postalCode"] = "Please enter Postal Code.";
      } else if (!data?.postalCode?.match(Regex.pinCodeRegex)) {
        formIsValid = false;
        errors["invalidPostalCode"] = "please enter valid postal code";
      }
    }

    if (selectedRole !== "Marketing") {
      if (model) {
        console.log("multiSelectedBranch")
        if (multiSelectedBranch?.length === 0) {
          formIsValid = false;
          errors["branchName"] = "Please select branch name.";
        }
      } else {
        if (!selectedBranch) {
          formIsValid = false;
          errors["branchName"] = "Please select branch name.";
        }
      }
    }

    if (model) {
      if (!selectedRole) {
        formIsValid = false;
        errors["userType"] = "Please select Assign Roles.";
      }
    }

    if ((visitorModel || customerModel) && !data?._id) {
      if (!data?.reference) {
        formIsValid = false;
        errors["reference"] = "Please enter reference.";
      }
      if (!data?.reason) {
        formIsValid = false;
        errors["reason"] = "Please enter reason.";
      }
    }

    if (!data?._id) {
      if (model) {
        if (!data?.password) {
          formIsValid = false;
          errors["password"] = "Please enter password.";
        } else if (!data.password?.match(Regex.passwordRegex)) {
          formIsValid = false;
          errors["strongPassword"] = "Please enter strong password";
        }
        if (!data?.confirmPassword) {
          formIsValid = false;
          errors["confirmPassword"] = "Please confirm your password.";
        } else if (data?.confirmPassword !== data?.password) {
          formIsValid = false;
          errors["matchPassword"] = "Passwords do not match.";
        }
      }
    }


    setError(errors);
    return formIsValid;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };


  const _getDefaultId = (data, name) => {
    return data?.length > 0 && data?.filter((e) => e?.name == name)?.[0]?.id;
  };

  const handleChange = (e, isInvestmentPlan, i) => {
    const { name, value } = e.target;

    if (isInvestmentPlan && name === 'returnOfInvestment') {
      if (value !== "" && (isNaN(value) || value < 0 || value > 100)) {
        return;
      }
    }


    if (isInvestmentPlan === true) {
      const modifyData = { ...data };
      if (modifyData.userPurchasePlan && modifyData.userPurchasePlan[i]) {
        modifyData.userPurchasePlan[i][name] = value?.replace(/,/g, '');
      }
      setData(modifyData);
    } else {
      setData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const setUserPurchasePlanDelete = (i) => {
    const modifyData = { ...data };
    if (modifyData.userPurchasePlan && modifyData.userPurchasePlan.length > i) {
      modifyData.userPurchasePlan.splice(i, 1);
      setData(modifyData);
    }
  };

  const setUserPurchasePlanAdd = () => {
    setData({
      ...data,
      userPurchasePlan: [
        ...data?.userPurchasePlan,
        { _id: null, investment: "", investmentDays: "", returnOfInvestment: "" },
      ],
    });
  };

  const _getUsers = async () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
    };
    if (filterByRole) {
      body.userType = [roles?.find((e) => e?.label == filterByRole)?.id]
    }
    await axios.post("/users", body)
      .then((res) => {
        if (res?.data?.data) setUserDetails(res?.data?.data);
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };


  const _getBranches = () => {
    let body = {
      limit: rowsPerPage,
      page: page,
    };
    if (isEdit && data?._id) {
      body.isAllBranch = true
    }
    axios
      .post(`/branch`, body)
      .then((res) => {
        if (res?.data?.data?.response) {
          setBranches(res?.data?.data?.response);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };

  const _getCountries = () => {
    toggleLoader();
    axios.get("/countries")
      .then((res) => {
        if (res?.data?.data) {
          setCountries(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => {
        toggleLoader();
      });
  };

  const _getStates = () => {
    toggleLoader();
    axios
      .post("/states", {
        country_id: _getDefaultId(countries?.response, selectedCountry),
      })
      .then((res) => {
        if (res?.data?.data) {
          setStates(res?.data?.data);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getCities = () => {
    toggleLoader();
    axios
      .post("/cities", {
        state_id: _getDefaultId(states?.response, selectedState),
        country_id: _getDefaultId(countries?.response, selectedCountry),
      })
      .then((res) => {
        if (res?.data?.data) {
          setCities(res?.data?.data);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };
  const handleClear = () => {
    setData({ userPurchasePlan: [{ _id: null, investment: "", investmentDays: "", returnOfInvestment: "" }] });
    setModel(false);
    setVisitorModel(false);
    setCustomerModel(false);
    setError({});
    setIsEdit(false);
    setSelectedBranch("");
    setSelectedState("");
    setSelectedCity("");
    setSelectedRole("");
    setMultiSelectedBranch([]);
    _getUsers()
  };

  const handleEdit = (row) => {
    const roleConfig = roles?.filter((e) => e?.id == row?.userType)?.[0];
    setData(row);
    setSelectedBranch(row?.branchDetails?.[0]?.branchName || "");
    setMultiSelectedBranch(row?.branchDetails);
    setSelectedCountry(row?.countryDetail?.name || "");
    setSelectedState(row?.stateDetail?.name || "");
    setSelectedCity(row?.cityDetail?.name || "");
    setSelectedRole(roleConfig?.label);
    setIsEdit(true);
    if (row?.userType === Roles.Visitor) {
      setVisitorModel(true);
    } else if (row?.userType === Roles.User) {
      setCustomerModel(true);
    } else {
      setModel(true);
    }
  };


  const _handleDelete = (deleteId) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to Delete User",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios
          .delete(`/users/delete/${deleteId}`)
          .then((res) => {
            swal("Success", res?.data?.message, "success");
            toggleLoader();
            setDeleteId("");
            _getUsers();
          })
          .catch((err) => {
            toggleLoader();
            OnUpdateError(err.data.message);
          });
      }
    });
  };

  const _addUpdateUser = () => {
    if (handleValidation()) {
      toggleLoader();
      let body = {
        name: data?.name,
        address: data?.address,
        country: _getDefaultId(countries?.response, selectedCountry),
        state: _getDefaultId(states?.response, selectedState),
        city: _getDefaultId(cities?.response, selectedCity),
        postalCode: data?.postalCode,
        mobileNo: data?.mobileNo,
        email: data?.email,
        branch: model ? multiSelectedBranch.map((item) => item._id) : [branches?.filter((e) => e?.branchName == selectedBranch)[0]?._id],
        userType: visitorModel ? Roles.Visitor : customerModel ? Roles.User : roles?.filter((e) => e?.label == selectedRole)[0]?.id,
      };
      // if(selectedRole !=="")
      if (visitorModel || customerModel) {
        body.reason = data?.reason || null;
        body.reference = data?.reference || null;
      }
      if (customerModel) {
        body.password = data?.password;
      }
      if (model) {
        body.password = data?.password;
      }
      if (data?._id) {
        body.id = data?._id;
        delete body.password;
      }
      // if (data?._id && data?.userType === 1)

      axios
        .post(`/users/${data?._id ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClear();
          }
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  // useEffect(() => {
  //     if (visitorModel || customerModel) _getMeetingWithList()
  // }, [visitorModel, customerModel])

  useEffect(() => {
    _getUsers();
  }, [page, rowsPerPage, search, filterByRole]);

  useEffect(() => {
    _getBranches();
  }, [isEdit]);


  useEffect(() => {
    if (model || customerModel || visitorModel) {
      _getCountries();
    }
  }, [model, customerModel, visitorModel]);

  useEffect(() => {
    if (countries?.response && selectedCountry) {
      _getStates();
    }
  }, [countries, selectedCountry]);

  useEffect(() => {
    if (states?.response && selectedCountry && selectedState) {
      _getCities();
    }
  }, [states, selectedState]);
  console.log(menuList, "menuList")
  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      console.log('menuPermissions :>> ', menuPermissions);
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);
  return (
    <>
      {<PaperContainer elevation={0} square={false}>
        <Grid container spacing={0} xs={12} md={12} sm={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>

            {user?.userType === Roles?.Admin && <Box sx={{ display: "flex", padding: "10px", gap: 1, flexWrap: "wrap", alignItems: 'center' }}>
              <TextLabel fontSize={"14px"} color={theme.palette.bgGray.main} fontWeight={"400"} title={"Filter By Role:"} />
              <AutoCompleteSearch
                // text="Filter"
                fullWidth
                backgroundColor="white"
                handleChange={(e, newValue) => {
                  setfilterByRole(newValue)
                }}
                options={roles?.map((e) => e?.label) || []}
                name="filterByRole"
                defaultValue={filterByRole || ""}
                freeSolo
                blurOnSelect

                width={'250px'}
                placeholder={"All"}
              />
            </Box>}

          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{ display: "flex", justifyContent: { xs: "center", sm: "center", md: "end", lg: "end" }, padding: "10px", gap: 1, flexWrap: "wrap", }}>
              {(user?.userType === Roles?.Receptionist || user?.userType === 0) &&
                <CommonButton
                  text={permissions?.create ? "Add Visitor" : ""}
                  onClick={() => {
                    handleClear();
                    setVisitorModel(true);
                    setCustomerModel(false);
                    setModel(false);
                  }}
                />}

              {console.log("permissions", permissions)}

              {(user?.userType === 0 || user?.userType === 4) && (
                <>
                  <CommonButton
                    text={permissions?.create ? "Add Client" : ""}
                    onClick={() => {
                      handleClear();
                      setCustomerModel(true);
                      setVisitorModel(false);
                      setModel(false);
                    }}
                  />

                  {user?.userType === 0 && <CommonButton
                    text={permissions?.create ? "Add Role" : ""}
                    onClick={() => {
                      handleClear();
                      setModel(true);
                      setVisitorModel(false);
                      setCustomerModel(false);
                    }}
                  />}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </PaperContainer>}

      <PaperContainer elevation={0} square={false}>
        {visitorModel && (
          <Grid item xs={12}>
            <TableHeading
              title={`${isEdit ? "Update" : "Add"} Visitor`}
              handleBack={() => {
                setVisitorModel(false);
                handleClear();
              }}
              removeSearchField={true}
            />
            <VisitorModel
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              branches={branches}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              roles={roles}
              cities={cities}
              states={states}
              onSubmit={_addUpdateUser}
              isEdit={isEdit}
              setSelectedState={setSelectedState}
              selectedState={selectedState}
              setSelectedCity={setSelectedCity}
              selectedCity={selectedCity}
              setSelectedRole={setSelectedRole}
              selectedRole={selectedRole}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              countries={countries}
            />
          </Grid>
        )}
        {customerModel && (
          <Grid item xs={12}>
            <TableHeading
              title={`${isEdit ? "Update" : "Add"} Client`}
              handleBack={() => {
                handleClear();
                setCustomerModel(false);
              }}
              removeSearchField={true}
            />
            <CustomerModel
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              branches={branches}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              roles={roles}
              cities={cities}
              states={states}
              onSubmit={_addUpdateUser}
              isEdit={isEdit}
              setSelectedState={setSelectedState}
              selectedState={selectedState}
              setSelectedCity={setSelectedCity}
              selectedCity={selectedCity}
              setSelectedRole={setSelectedRole}
              selectedRole={selectedRole}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              countries={countries}
              setUserPurchasePlanDelete={setUserPurchasePlanDelete}
              setUserPurchasePlanAdd={setUserPurchasePlanAdd}
            />
          </Grid>
        )}
        {model && (
          <Grid item xs={12}>
            <TableHeading
              title={`${isEdit ? "Update" : "Add"} Role`}
              handleBack={() => {
                setModel(false);
                handleClear();
              }}
              removeSearchField={true}
            />
            <AddUser
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              branches={branches}
              multiSelectedBranch={multiSelectedBranch}
              setMultiSelectedBranch={setMultiSelectedBranch}
              roles={roles}
              cities={cities}
              states={states}
              onSubmit={_addUpdateUser}
              isEdit={isEdit}
              setSelectedState={setSelectedState}
              selectedState={selectedState}
              setSelectedCity={setSelectedCity}
              selectedCity={selectedCity}
              setSelectedRole={setSelectedRole}
              selectedRole={selectedRole}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              countries={countries}
            />
          </Grid>
        )}
        {!model && !visitorModel && !customerModel && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <TableHeading title="User List" onClick={() => { setModel(true) }} handleSearch={(value) => { setPage(1); setSearch(value); }} />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  {!loading ? (
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Visit Date</StyledTableCell>
                          <StyledTableCell>Address</StyledTableCell>
                          <StyledTableCell>Contact No.</StyledTableCell>
                          <StyledTableCell>Email Id</StyledTableCell>
                          <StyledTableCell>Branch</StyledTableCell>
                          <StyledTableCell>Role</StyledTableCell>
                          {(permissions?.update || permissions?.delete) && <StyledTableCell align="right">Action</StyledTableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userDetails?.response?.length > 0 ? userDetails?.response?.map((row, index) => {
                          const getRoleName = (type) => { return roles.filter((e) => e?.id == type)?.[0]?.label; };
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell style={{ paddingLeft: "13px" }}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                              <StyledTableCell className={classes.paddedRow} component="th" scope="row">{row.name}</StyledTableCell>
                              <StyledTableCell className={classes.paddedRow} component="th" scope="row">{row.lastVisitDate ? dayjs(row.lastVisitDate).format("DD/MM/YYYY") : "-"}</StyledTableCell>
                              <StyledTableCell>
                                <Box width={{ xl: '220px', lg: '220px', md: '220px', sm: '220px', xs: '120px' }} sx={{ lineBreak: 'anywhere', wordWrap: 'wrap' }} fontSize={"13px"} textAlign={'start'}>
                                  {row?.address}
                                </Box>
                              </StyledTableCell>
                              <StyledTableCell>{row?.mobileNo}</StyledTableCell>
                              <StyledTableCell>
                                <Box width={{ xl: '220px', lg: '220px', md: '220px', sm: '220px', xs: '120px' }} sx={{ lineBreak: 'anywhere', wordWrap: 'wrap' }} fontSize={"13px"} textAlign={'start'}>
                                  {row?.email || "-"}
                                </Box>
                              </StyledTableCell>
                              <StyledTableCell>{row?.branchDetails?.map((e) => e?.branchName)?.join(",")}</StyledTableCell>
                              <StyledTableCell>{getRoleName(row.userType)}</StyledTableCell>
                              {(permissions?.update || permissions?.delete) && <StyledTableCell>
                                <Box display={"flex"} justifyContent={"right"} gap={1}>
                                  {permissions?.update && <Assets
                                    className={classes.writeBox}
                                    src={"/assets/icons/write.svg"}
                                    absolutePath={true}
                                    onClick={() => { handleEdit(row); }}
                                  />}
                                  {permissions?.delete && <Assets
                                    className={classes.deleteBox}
                                    src={"/assets/icons/delete.svg"}
                                    absolutePath={true}
                                    onClick={() => {
                                      // setDeleteId(row?._id);
                                      _handleDelete(row?._id);
                                    }}
                                  />}
                                </Box>
                              </StyledTableCell>}
                            </StyledTableRow>
                          );
                        }) :
                          <TableRow>
                            <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  ) : (
                    <WidgetLoader />
                  )}
                </TableContainer>
              </Grid>


            </Grid>
            {userDetails?.count > 0 &&
              <Grid item xs={12}>
                <CustomPagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={Math.ceil(userDetails?.count / rowsPerPage)}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage} />

              </Grid>
            }
          </>
        )}
      </PaperContainer>
    </>
  );
};

export default User;
