import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import { makeStyles } from "tss-react/mui";
import { Box, CardActionArea, CardActions, Divider, Grid, Typography, useTheme } from "@mui/material";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonModal from "../../Components/Common/CommonModel";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import swal from "sweetalert";
import { useAppContext } from "../../Context/context";
import AddOffer from "../../Components/Offer";
import Assets from "../../Components/Common/ImageContainer";
import { lightTheme } from "../../theme";
import PaperContainer from "../../Components/Common/PaperContainer";
import Swal from "sweetalert2";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WidgetLoader from "../../Components/Common/widgetLoader";

const useStyles = makeStyles()((theme) => {
  return {
    card: {
      padding: "20px",
      borderRadius: "16px",
      border: "1px solid #F8F9FA",
      boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.50)",
      background: "#FFF",
    },
    writeBox: {
      borderRadius: "6px",
      height: "35px",
      width: "35px",
      padding: "8px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
    },
    deleteBox: {
      height: "35px",
      width: "35px",
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
    cardImage: {
      height: "150px",
      border: "1px solid #EDF2F6",
      borderRadius: "16px",
      backgroundPosition: "top center",
    },
    cardContent: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 5px",
      gap: "5px",
    },
    cardDescription: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      minHeight: "50px",
    },
  };
});

const OfferPage = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader } = useAppContext();
  const theme = useTheme()
  const [model, setModel] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [offerDetails, setOfferDetails] = useState([]);
  const [description, setDescription] = useState("<p><br></p>");
  const [loading, setLoading] = useState(false)

  //Validation
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!data?.image) {
      formIsValid = false;
      errors["image"] = "Please upload image.";
    }
    if (!data?.title) {
      formIsValid = false;
      errors["title"] = "Please enter title.";
    }
    // if (!description ) {
    //     formIsValid = false;
    //     errors["description"] = "Please enter description.";
    //   }
    if (!description || description === "<p><br></p>") {
      formIsValid = false;
      errors["description"] = "Please enter description.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = async (val, key) => {
    const formData = new FormData();
    formData.append("image", val);
    toggleLoader();
    axios
      .post("/upload/image/attachment", formData)
      .then((res) => {
        if (res?.data?.data) {
          setData((prevData) => ({
            ...prevData,
            ["image"]: res?.data?.data?.image,
          }));
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getOffer = () => {
    setLoading(true)
    axios
      .get("offer")
      .then((res) => {
        if (res?.data?.data) {
          setOfferDetails(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };
  const handleClose = () => {
    setModel(false);
    setIsEdit(false);
    setData({});
    setError({});
    setDescription("");
    setDeleteId("");
    _getOffer();
  }
  const _handleDelete = (deleteId) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to Delete Offer",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (deleteId) {
          toggleLoader();
          axios
            .delete(`/offer/delete/${deleteId}`)
            .then((res) => {
              swal("Success", res?.data?.message, "success");
              handleClose()
            })
            .catch((err) => {
              OnUpdateError(err.data.message);
            }).finally(() => toggleLoader());
        }
      }
    });
  };


  const _addUpdateOffer = () => {
    if (handleValidation()) {
      toggleLoader();
      let body = {
        image: data?.image,
        title: data?.title,
        description: description,
      };
      if (data?._id) {
        body.id = data?._id;
      }
      axios
        .post(`offer/${data?._id ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClose()
          }
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => {
          toggleLoader();
        });
    }
  };

  useEffect(() => {
    _getOffer();
  }, []);

  return (
    <>
      <PaperContainer elevation={0} square={false} >
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableHeading
              title="Offer"
              removeSearchField
              buttonText={"Add Offer"}
              onClick={() => setModel(true)}
            />
          </Grid>
          <Grid item container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12} p={1}>
            {loading ?
              <Grid item xs={12} >
                <WidgetLoader />
              </Grid>
              : offerDetails?.response?.length > 0 ? offerDetails?.response?.map((item) => {
                console.log(item, "itemmm")
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Card elevation={0} sx={{ border: `1px solid rgba(0, 0, 0, 0.1)`, height: '310px', borderRadius: '20px' }} >
                      <CardMedia
                        sx={{ height: 140, margin: 1, border: `1px solid rgba(0, 0, 0, 0.1)`, borderRadius: '15px' }}
                        image={`${Image_BASE_URL}${item?.image}`}
                        title={item?.image}
                      />
                      <CardContent sx={{ padding: 1 }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Box width={'200px'}>
                            <TextLabel fontSize={"18px"} color={"#000"} fontWeight={"600"} title={item?.title} noWrap />
                          </Box>
                          <Box display={'flex'} gap={1}>
                            <Assets
                              className={classes.writeBox}
                              src={"/assets/icons/write.svg"}
                              absolutePath={true}
                              onClick={() => {
                                setData(item);
                                setIsEdit(true);
                                setDescription(item?.description);
                                setModel(true);
                              }}
                            />
                            <Assets
                              className={classes.deleteBox}
                              src={"/assets/icons/delete.svg"}
                              absolutePath={true}
                              onClick={() => {
                                _handleDelete(item?._id);
                              }}
                            />
                          </Box>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Box width={'280px'}>
                          <TextLabel className={classes.cardDescription} variant={"body2"} color={lightTheme.palette.bgLightExtraLightGray.main} fontWeight={"400"}
                            title={<div dangerouslySetInnerHTML={{ __html: item?.description }} noWrap />}
                          />
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              }) : <Grid item xs={12} >
                <DataNotFound title="No Offer Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
              </Grid>}
          </Grid>
        </Grid>
      </PaperContainer>
      <CommonModal
        open={model}
        onClose={handleClose}
        title={`${isEdit ? "Update" : "Add"} Offer`}
        content={
          <AddOffer
            data={data}
            setData={setData}
            error={error}
            handleChange={handleChange}
            onSubmit={_addUpdateOffer}
            isEdit={isEdit}
            handleImageUpload={handleImageUpload}
            description={description}
            setDescription={setDescription}
          />
        }
      />
    </>
  );
};

export default OfferPage;
