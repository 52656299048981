import React, { useEffect, useState } from "react";
import PaperContainer from "../../Components/Common/PaperContainer";
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, } from "@mui/material";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableHeading from "../../Components/Common/CommonTableHeading";
import axios from "../../APiSetUp/axios";
import { useAppContext } from "../../Context/context";
import { globalAmountConfig } from "../../Utils/globalConfig";
import DataNotFound from "../Common/DataNotFound";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WidgetLoader from "../Common/widgetLoader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: 5,
    border: 0,
    lineHeight: '1.1rem'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
    border: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    border: 0
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {

  };
});

const Investors = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader } = useAppContext();

  const [topInvestors, setTopInvestors] = useState([])
  const [loading, setLoading] = useState(false)

  const _getDashboardTopInvestors = () => {
    let body = {};
    setLoading(true)
    axios
      .post(`/top_invester`, body)
      .then((res) => {
        if (res?.data?.data) {
          setTopInvestors(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  useEffect(() => {
    _getDashboardTopInvestors();
  }, []);

  return (
    <Grid item sm={12} xs={12} md={6} lg={7}>
      <PaperContainer sx={{ height: "400px", overflow: "auto" }} elevation={0}>
        <Grid item sm={12} xs={12} md={12} lg={12}>
          <TableHeading
            variant={"h6"}
            title="Investors"
            removeSearchField
            style={{ padding: "10px 0px 0px 10px" }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={12}>
          <TableContainer>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.paddedRow}>No</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Contact No.</StyledTableCell>
                  <StyledTableCell>Email Id</StyledTableCell>
                  <StyledTableCell>Investment Amount</StyledTableCell>
                  <StyledTableCell>Active Plan</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? topInvestors?.length ? topInvestors.map((ele, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell className={classes.paddedRow} component="th" scope="row">{ele?.name}</StyledTableCell>
                    <StyledTableCell>{ele?.mobileNo}</StyledTableCell>
                    <StyledTableCell>{ele?.email}</StyledTableCell>
                    <StyledTableCell>{globalAmountConfig(ele?.totalInvestment)}</StyledTableCell>
                    <StyledTableCell>{ele?.totalPlans}</StyledTableCell>
                  </StyledTableRow>
                )) : <TableRow>
                  <StyledTableCell colSpan={12}>
                    <DataNotFound title="No Investor Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                  </StyledTableCell>
                </TableRow> :
                  <TableRow>
                    <StyledTableCell colSpan={12}><WidgetLoader /></StyledTableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </PaperContainer>
    </Grid>
  )
}

export default Investors
