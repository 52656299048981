import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useAppContext } from "../../Context/context";
import Summary from "../../Components/Dashboard/Summary";
import TotalRevenue from "../../Components/Dashboard/TotalRevenue";
import Investors from "../../Components/Dashboard/Investors";
import VisitorInsights from "../../Components/Dashboard/VisitorInsights";
import { Roles } from "../../Utils/enum";
import ScheduledMeeting from "../../Components/Dashboard/ScheduledMeeting";
import { Offers } from "../../Components/Dashboard/Offers";
import { Grid } from "@mui/material";
import LeadDetails from "../../Components/Dashboard/Lead";

const Dashboard = () => {
  const { user } = useAppContext();

  const showDashboardComponentRoleWise = () => {
    if (user?.userType === Roles.Admin) {
      return (
        <>
          <Summary />
          <ScheduledMeeting />
          <TotalRevenue />
          <Investors />
          <VisitorInsights />
          <Offers />
        </>
      )
    } else if (user?.userType === Roles.Receptionist) {
      return (<>
        <Summary />
        <VisitorInsights />
        <ScheduledMeeting />
      </>
      )
    } else if (user?.userType === Roles.Counsellor) {
      return (
        <>
          <ScheduledMeeting />
        </>
      )
    } else if (user?.userType === Roles.Accountant) {
      return (
        <>
          <ScheduledMeeting />
        </>
      )
    } else if (user?.userType === Roles.User) {
      return (
        <>
          <Summary />
          <Offers />
        </>
      )
    } else if (user?.userType === Roles.Marketing) {
      return (
        <>
          <Summary />
          <LeadDetails />
        </>
      )
    } else if (user?.userType === Roles.TeleCaller) {
      return (
        <>
          <Summary />
          <LeadDetails />
        </>
      )
    }

  }

  return (
    <>
      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12}>
        {showDashboardComponentRoleWise()}
      </Grid>
    </>
  );
};

export default Dashboard;
