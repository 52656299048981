import React from 'react'
import { Box, Checkbox, Collapse, Divider, FormControlLabel, FormGroup, Grid, Grow, InputLabel, Table, TableBody, TableContainer, TableHead, TableRow, TextField, useTheme } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AutoCompleteSearch from '../Common/commonAutoComplete';
import TextLabel from '../Common/Fields/TextLabel';
import CommonButton from '../Common/Button/CommonButton';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from '@mui/icons-material/Close';
import DataNotFound from '../Common/DataNotFound';
import { makeStyles } from "tss-react/mui";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CommonTextField from '../Common/Fields/TextField';
import { convertToNumber, globalAmountConfig } from '../../Utils/globalConfig';
import { styled } from "@mui/material/styles";
import FileUpload from '../Common/uploadButton';
import Assets from '../Common/ImageContainer';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 12,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        padding: 5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "5px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        customGridItem: {
            paddingTop: '5px !important',
        },
        deleteBox: {
            cursor: "pointer",
            height: '20px',
            marginTop: 2,
            "&:hover": {
                border: `2px solid ${theme.palette.bgLightExtraRed.main}`,
                borderRadius: "4px",
            }
        },
    };
});

const ForeclosureAccountModal = ({ data, setData, error, handleUpload, deleteUpcommingPlans, addtionalPlanDetails, deleteUploadFile, handleChange, setSelectedClient, selectedClient, clients, onSubmit, closureConfirmationLetterFile, setClosureConfirmationLetterFile }) => {
    console.log(selectedClient, "selectedClient")
    const theme = useTheme()
    const { classes } = useStyles();

    return (
        <Grid container spacing={2} xs={12} md={12} lg={12} sm={12} p={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <TextLabel fontWeight={500} fontSize={14} title={'Client Name :'} color={theme.palette.primary.main} />
                    <TextLabel fontWeight={500} fontSize={14} title={selectedClient} />
                </Box>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* Previous Plan Grid */}
                <TextLabel fontWeight={500} fontSize={14} title={'Investment Details:'} color={theme.palette.primary.main} style={{ marginBottom: 2 }} />
                <Box width={'100%'} border={`1px solid #b8c4f6`} borderRadius={"3px"} bgcolor={'#fafcff'} >
                    <TableContainer>
                        <Table sx={{ minWidth: 600 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Investment Amount </StyledTableCell>
                                    <StyledTableCell>Investment Days</StyledTableCell>
                                    <StyledTableCell>Return Of Percentage</StyledTableCell>
                                    <StyledTableCell align="center">Start Date</StyledTableCell>
                                    <StyledTableCell align="center">Maturity Date</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow>
                                    <StyledTableCell style={{ paddingLeft: "15px" }}>{globalAmountConfig(data?.currentInvestment)}</StyledTableCell>
                                    <StyledTableCell>{data?.investmentDays}</StyledTableCell>
                                    <StyledTableCell>{data?.returnOfPercentage}</StyledTableCell>
                                    <StyledTableCell align="center">{dayjs(data?.planDate).format('DD/MM/YYYY')}</StyledTableCell>
                                    <StyledTableCell align="center">{dayjs(data?.maturityDate).format('DD/MM/YYYY')}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* Previous Plan Grid */}
                <TextLabel fontWeight={500} fontSize={14} title={'Your Upcomming Plan Details:'} color={theme.palette.primary.main} style={{ marginBottom: 2 }} />

                <Box width={'100%'} border={`1px solid #b8c4f6`} borderRadius={"3px"} bgcolor={'#fafcff'} >
                    {addtionalPlanDetails?.length > 0 ?
                        <TableContainer>
                            <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>No.</StyledTableCell>
                                        <StyledTableCell>Investment Date </StyledTableCell>
                                        <StyledTableCell>Maturity Date </StyledTableCell>
                                        <StyledTableCell align="center">Investment Days</StyledTableCell>
                                        <StyledTableCell align="center">Return Amount Of Interest</StyledTableCell>
                                        <StyledTableCell align="center">Investment Amount</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addtionalPlanDetails?.map((row, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell style={{ paddingLeft: "15px" }}>{index + 1}</StyledTableCell>
                                                <StyledTableCell>{dayjs(row?.userparchesplansDetail?.planDate).add(parseInt(row?.investmentDays), 'day').format("MM/DD/YYYY")}</StyledTableCell>
                                                <StyledTableCell>{dayjs(row?.userparchesplansDetail?.maturityDate).add(parseInt(row?.investmentDays), 'day').format("MM/DD/YYYY")}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.investmentDays}</StyledTableCell>
                                                <StyledTableCell align="center">{`${row?.returnOfInvestment}(${row?.returnOfPercentage}%)`}</StyledTableCell>
                                                <StyledTableCell align="center">{row?.investment ? globalAmountConfig(row?.investment || "0") : 0}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Assets
                                                        className={classes.deleteBox}
                                                        src={"/assets/icons/delete.svg"}
                                                        absolutePath={true}
                                                        onClick={() => {
                                                            deleteUpcommingPlans(row?._id);
                                                        }}
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer> : <TextLabel fontWeight={500} fontSize={13} title={"No plan upcomming plan avaialble!"} color={theme.palette.bgDarkPrimary.main} textAlign={'center'} style={{ padding: 10 }} />}
                </Box>

            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
                <CommonTextField
                    fontWeight={400}
                    text={'Remaining Amount'}
                    placeholder={"Enter amount"}
                    type='text'
                    name='remainingAmount'
                    value={globalAmountConfig(data?.remainingAmount)}
                    onChange={(e) => {
                        setData((prevState) => ({ ...prevState, remainingAmount: e?.target?.value, }));
                    }}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.remainingAmount ? error?.remainingAmount : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputLabel sx={{ fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Upload Closure Confirmation Letter</InputLabel>
                </Grid>
                <FileUpload
                    handleFileChange={handleUpload}
                    selectedFile={closureConfirmationLetterFile}
                    OnDelate={deleteUploadFile}
                // acceptFile="image/png, image/gif, image/jpeg"
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.imageSizeValid} />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!closureConfirmationLetterFile ? error?.closureConfirmationLetter : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                    <CommonButton
                        width={'180px'}
                        text={`Submit For Foreclosure`}
                        type="submit"
                        onClick={onSubmit}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default ForeclosureAccountModal