import React, { useEffect, useState } from "react";
import PaperContainer from "../Common/PaperContainer";
import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Select,
} from "@mui/material";
import TextLabel from "../Common/Fields/TextLabel";
import axios from "../../APiSetUp/axios";
import DashboardSummaryBox from "../Common/DashboardSummaryBox";
import { useAppContext } from "../../Context/context";
import { Roles } from "../../Utils/enum";
import { globalAmountConfig } from "../../Utils/globalConfig";
import WidgetLoader from "../Common/widgetLoader";
import AutoCompleteSearch from "../Common/commonAutoComplete";
import SelectDropDown from "../Common/SelectDropDown";

const Summary = () => {
    const { OnUpdateError, toggleLoader, user } = useAppContext();

    const [dashboardSummary, setDashboardSummary] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedTodaySummaryBranch, setSelectedTodaySummaryBranch] = useState("");
    const [selectedOverallSummaryBranch, setSelectedOverallSummaryBranch] = useState("");
    const [loading, setLoading] = useState(false)

    const _getDashboardSummary = () => {
        setLoading(true)
        let body = {};
        if (selectedOverallSummaryBranch) {
            body.branchForAllOverSummary = branches?.response?.filter((e) => e?.branchName == selectedOverallSummaryBranch)[0]?._id
        }
        if (selectedTodaySummaryBranch) {
            body.branchForTodaySummary = branches?.response?.filter((e) => e?.branchName == selectedTodaySummaryBranch)[0]?._id
        }
        axios.post(`/dashboard_deatils`, body)
            .then((res) => {
                if (res?.data?.data) setDashboardSummary(res?.data?.data);
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setTimeout(() => {
                setLoading(false)
            }, [500]));
    };

    const _getBranches = () => {
        let body = {
            limit: 100,
            page: 1,
            isAll: true
        };
        axios.post(`/branch`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setBranches(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            })
    };

    useEffect(() => {
        _getDashboardSummary()
    }, [selectedTodaySummaryBranch, selectedOverallSummaryBranch]);

    useEffect(() => {
        _getBranches()
    }, [])

    return (
        <>
            {loading ? <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer sx={{ padding: '0px' }} elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid> : dashboardSummary?.todaySummary ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PaperContainer sx={{ padding: 1 }} elevation={0}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginBottom={1}>
                            <TextLabel variant={"h6"} fontWeight={"600"} title={"Today’s Summary"} />
                            <AutoCompleteSearch
                                fullWidth
                                backgroundColor="white"
                                handleChange={(e, newValue) => setSelectedTodaySummaryBranch(newValue)}
                                options={branches?.response?.map((e) => e?.branchName) || []}
                                name="selectedTodaySummaryBranch"
                                defaultValue={selectedTodaySummaryBranch || ""}
                                freeSolo
                                blurOnSelect
                                width={'250px'}
                                placeholder={"Search By Branch"}
                            />
                        </Box>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
                            {(dashboardSummary?.todaySummary?.todayVisitor || dashboardSummary?.todaySummary?.todayVisitor == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayVisitor)}
                                title={"Today's New Visitor"}
                                iconColor={"#FA5A7D"}
                                backgroundColor={"#FFE2E5"}
                                avtar={"/assets/icons/DashboardIcon1.png"}
                            /> : ""}
                            {(dashboardSummary?.todaySummary?.todayVisit || dashboardSummary?.todaySummary?.todayVisit == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayVisit)}
                                title={"Today's Total Visit"}
                                iconColor={"#858585"}
                                backgroundColor={"#dbdbdb"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.todaySummary?.todayMeeting || dashboardSummary?.todaySummary?.todayMeeting == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayMeeting)}
                                title={"Today's New Meetings"}
                                iconColor={"#FF947A"}
                                backgroundColor={"#FFF4DE"}
                                avtar={"/assets/icons/DashboardIcon2.png"}
                            /> : ""}
                            {(dashboardSummary?.todaySummary?.todayInvestment || dashboardSummary?.todaySummary?.todayInvestment == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayInvestment)}
                                title={"Today's Investment"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}
                            {(dashboardSummary?.todaySummary?.todayPayout || dashboardSummary?.todaySummary?.todayPayout == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayPayout)}
                                title={"Today's Payout"}
                                iconColor={"#BF83FF"}
                                backgroundColor={"#F3E8FF"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}
                            {(dashboardSummary?.todaySummary?.todayClient || dashboardSummary?.todaySummary?.todayClient == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayClient)}
                                title={"Today's New Client"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}
                            {(dashboardSummary?.todaySummary?.todayLead || dashboardSummary?.todaySummary?.todayLead == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayLead)}
                                title={"Today's New Lead"}
                                iconColor={"#4FA3F1"}
                                backgroundColor={"#E0F0FF"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}
                            {(dashboardSummary?.todaySummary?.todayActiveLead || dashboardSummary?.todaySummary?.todayActiveLead == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayActiveLead)}
                                title={"Today's Active Lead"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.todaySummary?.todayAssignToReceptionistLead || dashboardSummary?.todaySummary?.todayAssignToReceptionistLead == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.todaySummary?.todayAssignToReceptionistLead)}
                                title={"Today's Assigned Receptionist Lead"}
                                iconColor={"#4FA3F1"}
                                backgroundColor={"#E0F0FF"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}
                        </Grid>
                    </PaperContainer>
                </Grid>
            ) : <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid>}

            {loading ? <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer sx={{ padding: 1 }} elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid> : dashboardSummary?.allOverSummary ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PaperContainer sx={{ padding: 1 }} elevation={0}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginBottom={1}>
                            <TextLabel variant={"h6"} fontWeight={"600"} title={"All Over Summary"} />
                            <AutoCompleteSearch
                                fullWidth
                                backgroundColor="white"
                                handleChange={(e, newValue) => setSelectedOverallSummaryBranch(newValue)}
                                options={branches?.response?.map((e) => e?.branchName) || []}
                                name="selectedOverallSummaryBranch"
                                defaultValue={selectedOverallSummaryBranch || ""}
                                freeSolo
                                blurOnSelect
                                width={'250px'}
                                placeholder={"Search By Branch"}
                            />
                        </Box>
                        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                            {(dashboardSummary?.allOverSummary?.totalVisitor || dashboardSummary?.allOverSummary?.totalVisitor == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalVisitor)}
                                title={"Total Visitor"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon1.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalUser || dashboardSummary?.allOverSummary?.totalUser == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalUser)}
                                title={"Total Users"}
                                iconColor={"#BF83FF"}
                                backgroundColor={"#F3E8FF"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalReceptionist || dashboardSummary?.allOverSummary?.totalReceptionist == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalReceptionist)}
                                title={"Total Receptionist"}
                                iconColor={"#FA5A7D"}
                                backgroundColor={"#FFE2E5"}
                                avtar={"/assets/icons/DashboardIcon1.png"}
                            /> : ""}
                            {(dashboardSummary?.allOverSummary?.totalCounsellor || dashboardSummary?.allOverSummary?.totalCounsellor == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalCounsellor)}
                                title={"Total Counsellor"}
                                iconColor={"#FF947A"}
                                backgroundColor={"#FFF4DE"}
                                avtar={"/assets/icons/DashboardIcon2.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalAccountant || dashboardSummary?.allOverSummary?.totalAccountant == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalAccountant)}
                                title={"Total Accountant"}
                                iconColor={"#FA5A7D"}
                                backgroundColor={"#FFE2E5"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalMarketing || dashboardSummary?.allOverSummary?.totalMarketing == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalMarketing)}
                                title={"Total Marketing"}
                                iconColor={"#FF947A"}
                                backgroundColor={"#FFF4DE"}
                                avtar={"/assets/icons/DashboardIcon3.png"}
                            /> : ""}


                            {(dashboardSummary?.allOverSummary?.totalTelecaller || dashboardSummary?.allOverSummary?.totalTelecaller == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalTelecaller)}
                                title={"Total Telecaller"}
                                iconColor={"#858585"}
                                backgroundColor={"#f2f2f2"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalLead || dashboardSummary?.allOverSummary?.totalLead == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalLead)}
                                title={"Total Lead"}
                                iconColor={"#3CD856"}
                                backgroundColor={"#DCFCE7"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.totalActiveLead || dashboardSummary?.allOverSummary?.totalActiveLead == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalActiveLead)}
                                title={"Total Active Lead"}
                                iconColor={"#858585"}
                                backgroundColor={"#dbdbdb"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                            {(dashboardSummary?.allOverSummary?.todayAssignToReceptionistLead || dashboardSummary?.allOverSummary?.todayAssignToReceptionistLead == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.todayAssignToReceptionistLead)}
                                title={"Total Assigned Receptionist Lead"}
                                iconColor={"#FF947A"}
                                backgroundColor={"#FFF4DE"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}
                            {(dashboardSummary?.allOverSummary?.totalInvestmentAmount || dashboardSummary?.allOverSummary?.totalInvestmentAmount == 0) ? <DashboardSummaryBox
                                count={globalAmountConfig(dashboardSummary?.allOverSummary?.totalInvestmentAmount)}
                                title={"Total Investment"}
                                iconColor={"#4FA3F1"}
                                backgroundColor={"#E0F0FF"}
                                avtar={"/assets/icons/DashboardIcon4.png"}
                            /> : ""}

                        </Grid>
                    </PaperContainer>
                </Grid>
            ) : <Grid item xs={12} sm={12} md={12} lg={12}>
                <PaperContainer elevation={0}>
                    <WidgetLoader />
                </PaperContainer>
            </Grid>}
        </>
    )
}

export default Summary
