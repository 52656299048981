import React from 'react'
import { Box, Checkbox, Collapse, Divider, FormControlLabel, FormGroup, Grid, Grow, InputLabel, Table, TableBody, TableContainer, TableHead, TableRow, TextField, useTheme } from '@mui/material';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AutoCompleteSearch from '../Common/commonAutoComplete';
import TextLabel from '../Common/Fields/TextLabel';
import CommonButton from '../Common/Button/CommonButton';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from '@mui/icons-material/Close';
import DataNotFound from '../Common/DataNotFound';
import { makeStyles } from "tss-react/mui";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CommonTextField from '../Common/Fields/TextField';
import { convertToNumber, globalAmountConfig } from '../../Utils/globalConfig';
import { styled } from "@mui/material/styles";
import Assets from '../Common/ImageContainer';

const useStyles = makeStyles()((theme) => {
  return {
    customGridItem: {
      paddingTop: '5px !important',
    },
    dateBox: {
      background: theme?.palette?.bgWhite?.main,
      borderRadius: '12px',
      minWidth: "50px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "12.5px 14px",
        fontSize: "14px !important",
        height: "18px",
      },
      ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.primary?.main} !important`,
      },
      ".MuiInputBase-formControl.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.bgLightBlack?.main} !important`,
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
        borderRadius: "8px !important",
      },
      ".Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme?.palette?.primary?.main} !important`,
        borderWidth: "1px !important",
      },
      "& .MuiInputBase-formControl.Mui-disabled": {
        backgroundColor: "#e0e0e0",
        borderRadius: "8px !important",
        opacity: 0.8
      },

    },
    deleteBox: {
      cursor: "pointer",
      height: '20px',
      marginTop: 2,
      "&:hover": {
          border: `2px solid ${theme.palette.bgLightExtraRed.main}`,
          borderRadius: "4px",
      }
  },
  };
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const AddExisitngPlanModel = ({ data, setData, error, handleChange, deleteUpcommingPlans, setSelectedClient, selectedClient, clients, onSubmit, addExistingData, setAddExistingData, addtionalPlanDetails }) => {
  console.log(addExistingData, "addExistingData")
  const theme = useTheme()
  const { classes } = useStyles();

  // Previous
  const investmentAmount = data?.currentInvestment || 0;
  const returnPercentage = data?.returnOfPercentage || 0;
  const returnOnInvestmentAmount = Math.floor(investmentAmount * (returnPercentage / 100));

  // New Investment
  const newInvestmentAmount = addExistingData?.newCurrentInvestment ? convertToNumber(addExistingData?.newCurrentInvestment) : 0;
  const newReturnPercentage = parseFloat(addExistingData?.newReturnOfPercentage) || 0;
  const newReturnOnInvestmentAmount = Math.floor(newInvestmentAmount * (newReturnPercentage / 100));

  // For sub-total section
  const subTotalInvestmentAmount = investmentAmount + newInvestmentAmount;
  const subTotalInvestmentDays = data?.investmentDays;
  const subTotalReturnPercentage = newReturnPercentage ? newReturnPercentage : returnPercentage;
  const subTotalReturnOnInvestmentAmount = Math.floor(subTotalInvestmentAmount * (subTotalReturnPercentage / 100));

  console.log(newReturnOnInvestmentAmount, newInvestmentAmount, newReturnPercentage, "newReturnOnInvestmentAmount")
  return (
    <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <AutoCompleteSearch
          fullWidth
          backgroundColor="white"
          text="Client"
          placeholder={"Select Client"}
          handleChange={(e, newValue) => setSelectedClient(newValue)}
          options={clients?.map((e) => e?.name) || []}
          name="selectedClient"
          defaultValue={selectedClient || ""}
          freeSolo
          blurOnSelect
          disabled
        />
        <TextLabel fontSize={"12px"} color={theme.palette.error.main} title={!selectedClient ? error?.selectedClient : ""} />
      </Grid>
      <Divider />
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
          <TextLabel fontWeight={500} fontSize={16} title={'Investment Details'} />
        </Box>
      </Grid>
      {/* Previous Plan Grid */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box width={'100%'} border={`1px solid #e2e2e2`} borderRadius={"10px"} bgcolor={'#f9f9f9'}>
          <Grid item container padding={'15px 5px 15px 5px'} spacing={2} >
            <Grid item xs={12} sm={4} md={2} lg={2.6} className={classes.customGridItem}>
              <CommonTextField
                fontWeight={400}
                text={'Investment Amount'}
                type='text'
                name='currentInvestment'
                value={globalAmountConfig(data?.currentInvestment)}
                onChange={(e) => handleChange(e)}
                fontSize='12px'
                disabled
              />
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!data?.currentInvestment ? error?.currentInvestment : ""} />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2.1} className={classes.customGridItem}>
              <CommonTextField
                fontWeight={400}
                text={'Investment Days'}
                type='number'
                name='investmentDays'
                value={data?.investmentDays}
                onChange={(e) => handleChange(e)}
                fontSize='12px'
                disabled
              />
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!data?.investmentDays ? error?.investmentDays : ""} />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2.1} className={classes.customGridItem}>
              <CommonTextField
                fontWeight={400}
                text={'Return Of Percentage'}
                type='number'
                name='returnOfPercentage'
                value={data?.returnOfPercentage}
                onChange={(e) => handleChange(e)}
                fontSize='12px'
                disabled
              />
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!data?.returnOfPercentage ? error?.returnOfPercentage : ""} />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.6} className={classes.customGridItem}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                  <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>
                    Start Date
                  </InputLabel>
                </Box>
                <DesktopDatePicker
                  className={classes.dateBox}
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(data?.planDate)}
                  onChange={(newValue) => { setData({ ...data, planDate: newValue }); }}
                  renderInput={(params) => <TextField {...params} disabled />}
                  disablePast
                  disabled
                />
              </LocalizationProvider>
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!data?.planDate ? error?.planDate : ""} />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.6} className={classes.customGridItem}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                  <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>
                    Maturity Date
                  </InputLabel>
                </Box>
                <DesktopDatePicker
                  className={classes.dateBox}
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(data?.maturityDate)}
                  onChange={(newValue) => { setData({ ...data, maturityDate: newValue }); }}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                  disabled
                />
              </LocalizationProvider>
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!data?.maturityDate ? error?.maturityDate : ""} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox name='isExistingPlan' value={addExistingData?.isExistingPlan} onChange={(e) => handleChange(e, true)} defaultChecked={addExistingData?.isExistingPlan} />}
            label={<TextLabel fontWeight={500} fontSize={13} title={"Do you want to continue existing cycle?"} />}
          />
        </FormGroup>
      </Grid>
      {/* Addtional Investment Details */}
      {!addExistingData?.isExistingPlan &&
        <Grow in={!addExistingData?.isExistingPlan} style={{ transformOrigin: '0 0 0 0' }}
          {...(!addExistingData?.isExistingPlan ? { timeout: 800 } : {})}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextLabel fontWeight={500} fontSize={13} title={"Your upcomming plan details:"} color={theme.palette.bgDarkBlack.main} marginBottom={1} />
            <Box width={'100%'} border={`1px solid #b8c4f6`} borderRadius={"3px"} bgcolor={'#e8f3fd'} >
              {addtionalPlanDetails?.length > 0 ?
                <TableContainer>
                  <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>No.</StyledTableCell>
                        <StyledTableCell>Investment Date </StyledTableCell>
                        <StyledTableCell>Maturity Date </StyledTableCell>
                        <StyledTableCell align="center">Investment Days</StyledTableCell>
                        <StyledTableCell align="center">Return Amount Of Interest</StyledTableCell>
                        <StyledTableCell align="center">Investment Amount</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addtionalPlanDetails?.map((row, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell style={{ paddingLeft: "15px" }}>{index + 1}</StyledTableCell>
                            <StyledTableCell>{dayjs(row?.userparchesplansDetail?.planDate).add(parseInt(row?.investmentDays), 'day').format("MM/DD/YYYY")}</StyledTableCell>
                            <StyledTableCell>{dayjs(row?.userparchesplansDetail?.maturityDate).add(parseInt(row?.investmentDays), 'day').format("MM/DD/YYYY")}</StyledTableCell>
                            <StyledTableCell align="center">{row?.investmentDays}</StyledTableCell>
                            <StyledTableCell align="center">{`${row?.returnOfInvestment}(${row?.returnOfPercentage}%)`}</StyledTableCell>
                            <StyledTableCell align="center">{row?.investment ? globalAmountConfig(row?.investment || "0") : 0}</StyledTableCell>
                            <StyledTableCell align="center">
                              <Assets
                                className={classes.deleteBox}
                                src={"/assets/icons/delete.svg"}
                                absolutePath={true}
                                onClick={() => {
                                  deleteUpcommingPlans(row?._id);
                                }}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer> :
                <TextLabel fontWeight={500} fontSize={13} title={"No plan upcomming plan avaialble!"} color={theme.palette.bgDarkPrimary.main} textAlign={'center'} style={{ padding: 10 }} />}
            </Box>
          </Grid>
        </Grow>
      }
      {/* Add Existing Plan Grid */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box width={'100%'} border={`1px solid #e2e2e2`} borderRadius={"10px"} bgcolor={'#f9f9f9'}>
          <Grid item container padding={'15px 5px 15px 5px'} spacing={2} >
            {/* <Grid item xs={12} sm={12} md={12} lg={12} className={classes.customGridItem}>
              <Box display={"flex"} style={{ cursor: "pointer" }} margin={'0px 8px'} justifyContent={'end'} onClick={() => { }}>
                <CloseIcon sx={{ color: theme.palette.bgWhite.main, bgcolor: '#F14336', borderRadius: 1, fontSize: "16px", marginRight: "1px", cursor: "pointer" }} onClick={() => { }} />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={4} md={2} lg={2.6} className={classes.customGridItem}>
              <CommonTextField
                fontWeight={400}
                text={'Investment Amount'}
                type='text'
                name='newCurrentInvestment'
                value={globalAmountConfig(addExistingData?.newCurrentInvestment)}
                onChange={(e) => handleChange(e)}
                fontSize='12px'
                placeholder={'Enter Amount'}
              />
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!addExistingData?.newCurrentInvestment ? error?.newCurrentInvestment : ""} />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={1.8} className={classes.customGridItem}>
              <CommonTextField
                fontWeight={400}
                text={'Investment Days'}
                type='number'
                name='newInvestmentDays'
                value={addExistingData?.newInvestmentDays}
                onChange={(e) => handleChange(e)}
                fontSize='12px'
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2.4} className={classes.customGridItem}>
              <CommonTextField
                fontWeight={400}
                text={'Return Of Percentage'}
                type='text'
                name='newReturnOfPercentage'
                value={globalAmountConfig(addExistingData?.newReturnOfPercentage)}
                onChange={(e) => handleChange(e)}
                fontSize='12px'
                placeholder={'In Percentage'}
              />
              <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!addExistingData?.newReturnOfPercentage ? error?.newReturnOfPercentage : ""} />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.6} className={classes.customGridItem}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                  <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>
                    Start Date
                  </InputLabel>
                </Box>
                <DesktopDatePicker
                  className={classes.dateBox}
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(addExistingData?.newPlanDate) || null}
                  onChange={(newValue) => { setAddExistingData({ ...addExistingData, newPlanDate: newValue }); }}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                  disabled
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.6} className={classes.customGridItem}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                  <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "12px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>
                    Maturity Date
                  </InputLabel>
                </Box>
                <DesktopDatePicker
                  className={classes.dateBox}
                  inputFormat="MM/DD/YYYY"
                  value={dayjs(addExistingData?.newMaturityDate) || null}
                  onChange={(newValue) => { setAddExistingData({ ...addExistingData, newMaturityDate: newValue }); }}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                  disabled
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box width={'100%'} border={`1px solid #b8c4f6`} borderRadius={"3px"} bgcolor={'#e8f3fd'} >
          <Grid item container spacing={2} p={1}>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box>
                <Box mb={2}>
                  <TextLabel fontWeight={500} fontSize={15} title={"Previous"} color={theme.palette.error.main} />
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <TextLabel fontWeight={500} fontSize={13} title={"Investment Amount : "} color={theme.palette.bgDarkPrimary.main} secondText={`${!data?.currentInvestment ? "NA" : "₹ " + globalAmountConfig(data?.currentInvestment)}`} secondTextColor={theme.palette.error.main} />
                  <TextLabel fontWeight={500} fontSize={13} title={"Investment Day : "} color={theme.palette.bgDarkPrimary.main} secondText={data?.investmentDays} secondTextColor={theme.palette.bgDarkPrimary.main} />
                  <TextLabel fontWeight={500} fontSize={13} title={"Return Of Percentage : "} color={theme.palette.bgDarkPrimary.main} secondText={`${!data?.returnOfPercentage ? " NA" : data?.returnOfPercentage + " %"}`} secondTextColor={theme.palette.bgDarkPrimary.main} />
                  <TextLabel fontWeight={500} fontSize={13} title={"Return Of Investment : "} color={theme.palette.bgDarkPrimary.main} secondText={`₹ ${globalAmountConfig(returnOnInvestmentAmount)}`} secondTextColor={theme.palette.bgDarkPrimary.main} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box mb={2}>
                <TextLabel fontWeight={500} fontSize={15} title={"New Investment"} color={theme.palette.primary.main} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <TextLabel fontWeight={500} fontSize={13} title={"Investment Amount : "} color={theme.palette.bgDarkPrimary.main} secondText={`${!addExistingData?.newCurrentInvestment ? "NA" : "₹ " + globalAmountConfig(addExistingData?.newCurrentInvestment)}`} secondTextColor={theme.palette.primary.main} />
                <TextLabel fontWeight={500} fontSize={13} title={"Investment Day : "} color={theme.palette.bgDarkPrimary.main} secondText={addExistingData?.newInvestmentDays} secondTextColor={theme.palette.bgDarkPrimary.main} />
                <TextLabel fontWeight={500} fontSize={13} title={"Return Of Percentage : "} color={theme.palette.bgDarkPrimary.main} secondText={`${!addExistingData?.newReturnOfPercentage ? " NA" : addExistingData?.newReturnOfPercentage + " %"}`} secondTextColor={theme.palette.bgDarkPrimary.main} />
                <TextLabel fontWeight={500} fontSize={13} title={"Return Of Investment : "} color={theme.palette.bgDarkPrimary.main} secondText={`₹ ${!newReturnOnInvestmentAmount ? "NA" : globalAmountConfig(newReturnOnInvestmentAmount)}`} secondTextColor={theme.palette.bgDarkPrimary.main} />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box mb={2}>
                <TextLabel fontWeight={500} fontSize={15} title={"Sub Total"} color={theme.palette.bgSuccess.main} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <TextLabel fontWeight={500} fontSize={13} title={"Investment Amount : "} color={theme.palette.bgDarkPrimary.main} secondText={`₹ ${globalAmountConfig(subTotalInvestmentAmount)}`} secondTextColor={theme.palette.bgSuccess.main} />
                <TextLabel fontWeight={500} fontSize={13} title={"Investment Day : "} color={theme.palette.bgDarkPrimary.main} secondText={subTotalInvestmentDays} secondTextColor={theme.palette.bgDarkPrimary.main} />
                <TextLabel fontWeight={500} fontSize={13} title={"Return Of Percentage : "} color={theme.palette.bgDarkPrimary.main} secondText={`${subTotalReturnPercentage} %`} secondTextColor={theme.palette.bgDarkPrimary.main} />
                <TextLabel fontWeight={500} fontSize={13} title={"Return Of Investment : "} color={theme.palette.bgDarkPrimary.main} secondText={`₹ ${globalAmountConfig(subTotalReturnOnInvestmentAmount)}`} secondTextColor={theme.palette.bgDarkPrimary.main} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
          <CommonButton
            width={'180px'}
            text={`Add`}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default AddExisitngPlanModel