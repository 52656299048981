import { Button, Typography, Box, useTheme } from "@mui/material";
import { Image_BASE_URL } from "../../APiSetUp/axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Assets from "./ImageContainer";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const useStyles = makeStyles()((theme) => {
    return {
        clearBox: {
            borderRadius: "2px",
            padding: "4px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
        },
    };
});

const DocumentsUpload = ({ handleFileChange, selectedFile, OnDelate, text, acceptFile, multiple = false, title }) => {
    const theme = useTheme()
    const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png"];
    const { classes } = useStyles();
    const fileExtension = selectedFile?.split('.')?.pop()?.toLowerCase();
    const getThumbnail = () => {
        let thumb;
        if (fileExtension === 'pdf') {
            thumb = <PictureAsPdfIcon fontSize="large" color="error" style={{ height: '130px', maxWidth: '100%' }} />
        } else if (fileExtension === 'mp3') {
            thumb = <audio class='audioPlayer' controls style={{ height: '44px' }}>  <source src={`${Image_BASE_URL}${selectedFile}`} type="audio/mpeg" /></audio>
        } else if (fileExtension === 'jpeg' || fileExtension === 'gif' || fileExtension === 'jpg' || fileExtension === 'png') {
            thumb = <img src={`${Image_BASE_URL}${selectedFile}`} alt="Selected" style={{ height: '130px', maxWidth: '100%' }} />
        } else {
            thumb = <NoteAddIcon fontSize="large" color="primary" style={{ height: '130px', maxWidth: '100%' }}/>
        }
        return thumb
    }

    return (
        <>
            <Box component="label" p={1}
                sx={{
                    border: "1px dashed #ccc",
                    "&:hover": { backgroundColor: theme.palette.bgLightExtraPrimary.main },
                    borderRadius: 2,
                    cursor: "pointer"
                }}
                display={'flex'}
                width={'100%'}
                height={'200px'}
                flexDirection={'column'}
                flexWrap={'wrap'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={1}
            >
                {!selectedFile &&
                    <>
                        <CloudUploadIcon color="primary" />
                        <Typography variant="body2">{title}</Typography>
                        <VisuallyHiddenInput type="file" onChange={handleFileChange} onClick={(event) => { event.target.value = null }} accept={acceptFile} multiple={multiple} />
                    </>
                }
                {selectedFile &&
                    <>
                        <Box display={'flex'} flexDirection={'column'} gap={3} width={'100%'}>
                            <Box display={'flex'} justifyContent={'space-between'} gap={5} alignItems={'center'}>
                                <Typography variant="body2" color={'primary'}>{title}</Typography>
                                <Box display={"flex"} justifyContent={"flex-end"} gap={1} onClick={OnDelate}>
                                    <CloseOutlinedIcon sx={{ color: lightTheme.palette.error.main, fontSize: "23px" }} className={classes.clearBox} />
                                </Box>
                            </Box>
                            <Box display={'flex'} justifyContent={'center'}>
                                {getThumbnail()}

                            </Box>
                        </Box>

                    </>
                }
            </Box >
        </>
    )
}

export default DocumentsUpload