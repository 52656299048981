import React, { useEffect, useState } from "react";
import PaperContainer from "../../Components/Common/PaperContainer";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { format } from 'date-fns';
import TextLabel from "../../Components/Common/Fields/TextLabel";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonBarChart from "../../Components/Common/CommonBarChart";
import CommonLineChart from "../../Components/Common/CommonLineChart";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import { useAppContext } from "../../Context/context";
import WidgetLoader from "../Common/widgetLoader";

const TotalRevenue = () => {
    const { OnUpdateError, toggleLoader, user } = useAppContext();
    const [totalRevenue, setTotalRevenue] = useState([]);
    const [loading, setLoading] = useState(false)

    const _getDashboardTotalRevenue = () => {
        let body = {};
        setLoading(true)
        axios.post(`/total_Revenue`, body)
            .then((res) => {
                console.log(res, "resssss")
                if (res?.data?.data) setTotalRevenue(res?.data?.data)
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    useEffect(() => {
        _getDashboardTotalRevenue()
    }, [])

    return (
        <Grid item sm={12} xs={12} md={6} lg={7}>
            <PaperContainer sx={{ height: "400px" }} elevation={0}>
                <TextLabel
                    variant={"h6"}
                    fontWeight={600}
                    title={"Total Revenue"}
                    style={{ padding: "10px 0px 0px 10px" }}
                />
                {loading ? <WidgetLoader /> : <CommonBarChart totalRevenue={totalRevenue} />}
            </PaperContainer>
        </Grid>
    )
}

export default TotalRevenue
