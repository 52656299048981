import React, { useImperativeHandle, useRef, useState } from 'react'
import { Box, Chip, Fab, Grid, InputLabel, Paper, useTheme } from '@mui/material'
import AutoCompleteSearch from '../Common/commonAutoComplete'
import CommonButton from '../Common/Button/CommonButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextLabel from '../Common/Fields/TextLabel'

import DraftEditor from '../Common/Editor/DraftEditor'
import CommonTextField from '../../Components/Common/Fields/TextField';
import 'draft-js/dist/Draft.css'

const EmailTemplateConfiguration = ({ setSelectedTemplateType, setData, clearEmailState, handleChange, selectedTemplateType, templateTypes, setIsTamplateOpen, isTamplateOpen, data, selectedVariables, allVariables,
    _createTemplate, insertVariable, handleHtmlCodeChange, editorState, onEditorChange, error }) => {

    const theme = useTheme()

    return (
        <>
            <Grid container spacing={1} xs={12} smm={12} md={12} lg={12} >
                <Grid container item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <AutoCompleteSearch
                            fullWidth
                            backgroundColor="white"
                            text="Select template type"
                            handleChange={(e, newValue) => {
                                setSelectedTemplateType(newValue);
                                setData((prevState) => ({
                                    ...prevState,
                                    selectedTemplateId: templateTypes?.response?.length > 0 ? templateTypes?.response?.filter((e) => e?.templateName == newValue)[0]?._id : null,
                                }));
                            }}
                            options={templateTypes?.response?.map((e) => e?.templateName) || []}
                            name="label"
                            defaultValue={selectedTemplateType || ""}
                            freeSolo
                            blurOnSelect
                            placeholder={"Select Template Type"}
                        />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedTemplateType ? error?.selectedTemplateType : ""} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box display={'flex'} alignItems={'end'} justifyContent={'end'} marginTop={3}>
                            <Fab color="primary" aria-label="add" onClick={() => {
                                setIsTamplateOpen(false)
                                clearEmailState()
                            }} size='small'>
                                <ArrowBackIcon />
                            </Fab>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Template name'}
                        placeholder={"Enter template name "}
                        type='text'
                        name='templateName'
                        value={data?.templateName}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.templateName ? error?.templateName : ""} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={11}>
                    <Box display={'flex'} gap={1} justifyContent={'space-between'}>
                        <InputLabel sx={{ fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}> Format your email template here..</InputLabel>
                        {allVariables?.length > 0 ? allVariables?.map((c, i) => {
                            return (
                                <Chip
                                    key={i}
                                    onClick={(e) => insertVariable(" " + c?.toUpperCase() + " ")}
                                    variant={selectedVariables.has(c) ? "filled" : "outlined"}
                                    label={`${c?.toUpperCase()}`}
                                    style={{ height: '25px', fontWeight: 'bolder', color: "#6668ad", border: `0.5px solid #6668ad` }}
                                // color='primary'
                                />
                            )
                        }) : ""}
                    </Box>
                    <Box marginTop={1}>
                        <DraftEditor editorState={editorState} onChange={onEditorChange} onHtmlCodeChange={handleHtmlCodeChange} />
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.htmlCode ? error?.htmlCode : ""} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={11}>
                    <Box display={'flex'} alignItems={'end'} justifyContent={'center'} marginTop={2}>
                        <CommonButton
                            width={'160px'}
                            text={`${data?._id ? "Update" : "Create"} Template`}
                            type="submit"
                            onClick={() => _createTemplate()}
                        />
                    </Box>
                </Grid>
            </Grid >

        </>
    )
}

export default EmailTemplateConfiguration