import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Box, Typography, Paper, useTheme, Divider, TextField, InputBase, Fab, } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { useAppContext } from "../../Context/context";
import { useLocation, useNavigate } from "react-router-dom";
import Assets from "../../Components/Common/ImageContainer";
import CommonTextField from "../../Components/Common/Fields/TextField";
import { Regex } from "../../Utils/regex";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import { feedbackEnum } from "../../Utils/enum";
// import CommonTextField from '../Common/Fields/TextField';
import axios from "../../APiSetUp/axios";
import swal from "sweetalert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WidgetLoader from "../../Components/Common/widgetLoader";


const useStyles = makeStyles()((theme) => {
    return {
        centerContainer: {
            border: `1px solid ${theme.palette.bgLightGray.main}`,
            background: "#fdfdfd",
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 10,
            padding: 12,
            maxWidth: '500px'
        },
        rating: {
            height: '260px',
            width: '220px'
        },
        // ratingButton: {
        //     height: '120px',
        //     width: '120px',
        //     cursor: "pointer",
        //     transition: 'transform 0.5s', // Adding transition for the hover effect
        //     '&:hover': {
        //         transform: 'scale(1.1)',// Zoom in effect on hover
        //         border: '1px solid red',
        //         borderRadius:'50%'
        //     }
        // },
        ratingButton: {
            position: 'relative',
            height: '120px',
            width: '120px',
            cursor: 'pointer',
            transition: 'transform 0.5s, border 0.5s, border-radius 0.5s', // Adding transitions for the hover effect
            '&:hover': {
                transform: 'scale(0.8)', // Zoom in effect on hover
                border: '5px dashed grey', // Start with transparent border
                borderRadius: '50%',
                animation: '$borderAnimation 10s infinite', // Apply the border animation on hover
            },
        },
        '@keyframes borderAnimation': {
            '0%': {
                borderColor: 'red',
            },
            '50%': {
                borderColor: 'red',
            },
            '100%': {
                borderColor: 'red',
            },
        },
    };
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        // position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        // width: '80%',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.bgLightBlue2.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.bgLightBlue2.main,
        },
    },
}));

const Feedback = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, user, menuList } = useAppContext();
    const location = useLocation()
    const navigate = useNavigate();
    const theme = useTheme()
    const [formSubmitted, setFormSubmitted] = useState(false); // New state variable

    //States
    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        if (!data?.name) {
            formIsValid = false;
            errors["name"] = "*Please enter name.";
        }

        if (!data?.contactNumber) {
            formIsValid = false;
            errors["contactNumber"] = "*Please enter contact number.";
        } else if (!data?.contactNumber?.match(Regex.mobileNumberRegex)) {
            formIsValid = false;
            errors["invalidMobile"] = "*Please enter valid contact number.";
        }
        setError(errors);
        return formIsValid;
    };

    const _submitFeedbackForm = (type) => {
        if (handleValidation()) {
            setLoading(true)
            let body = {
                "name": data?.name,
                "mobileNo": data?.contactNumber,
                "feedback": type,
                "comments": data?.description
            }
            axios.post("feedback/create", body)
                .then((res) => {
                    if (res?.data?.data) {
                        setFormSubmitted(true)
                        setData({})
                    }
                })
                .catch((err) => {
                    OnUpdateError(err.data.message);
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 2000);
                });
        }
    };
    React.useEffect(() => {
        if (formSubmitted) {
            setTimeout(() => {
                setFormSubmitted(false)
            }, 10000);
        }
    }, [formSubmitted])

    return (
        <>
            <Grid container component={Paper} elevation={0} >
                <Grid item xs={12} sm={12} md={12} lg={8} className={classes.centerContainer}>
                    <Typography variant="h4">Feedback</Typography>
                    <Assets
                        className={classes.rating}
                        src={"/assets/image/rating.svg"}
                        absolutePath={true}
                    />
                    <Box>
                        <Typography variant="body2" color={theme.palette.backgroundDefaultColor.main} align="center"> Hey,</Typography>
                        <Typography variant="body2" color={theme.palette.backgroundDefaultColor.main} align="center"> We appreciate feedback about your experience.</Typography>
                    </Box>
                    <Divider sx={{ width: '70%', mt: 1 }} />
                    {loading ?
                        <Box display={'flex'} justifyContent={'center'} padding={10}>
                            <div class="loaderSuccess"></div>
                        </Box>
                        : !formSubmitted ?
                            <>
                                <Box maxWidth={'400px'} mt={1}>
                                    <Typography variant="body1" color={theme.palette.bgBlack.main} align="center">How happy were you with the overall experience?</Typography>
                                </Box>
                                <Box width={'80%'} display={'flex'} justifyContent={'center'} gap={2}>
                                    <Box width={'40%'}>
                                        <BootstrapInput type="text"
                                            name='name' value={data?.name}
                                            onChange={handleChange}
                                            placeholder='Please enter your name'
                                            fullWidth
                                        />
                                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.name ? error?.name : ""} />
                                    </Box>
                                    <Box width={'40%'}>
                                        <BootstrapInput
                                            type="number"
                                            name='contactNumber'
                                            value={data?.contactNumber}
                                            onChange={handleChange}
                                            placeholder='Please enter contact number'
                                            fullWidth
                                        />
                                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.contactNumber ? error?.contactNumber : ""} />
                                        <TextLabel fontSize={"12px"} color={"red"} title={data?.contactNumber?.match(Regex.mobileNumberRegex) ? "" : error.invalidMobile} />
                                    </Box>
                                </Box>
                                <Box width={'80%'} display={'flex'} justifyContent={'center'}>
                                    <BootstrapInput
                                        type="text"
                                        name='description'
                                        value={data?.description}
                                        onChange={handleChange}
                                        placeholder='Share you experiance in brief'
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Box>
                                <Box width={'100%'} display={'flex'} justifyContent={'center'} gap={2}>
                                    <RatingButton src={"/assets/image/good.svg"} title="Good" color={theme.palette.bgSuccess.main} handleClick={() => _submitFeedbackForm(feedbackEnum?.Good)} />
                                    <RatingButton src={"/assets/image/medium.svg"} title="Medium" color={theme.palette.warning.main} handleClick={() => _submitFeedbackForm(feedbackEnum?.Medium)} />
                                    <RatingButton src={"/assets/image/poor.svg"} title="Poor" color={theme.palette.error.main} handleClick={() => _submitFeedbackForm(feedbackEnum?.Poor)} />
                                </Box>
                            </> : <>
                                <Assets
                                    src={"/assets/icons/success.svg"}
                                    absolutePath={true}
                                    height={150}
                                />
                                <Box width={'60%'}>
                                    <TextLabel fontSize={"25px"} color={theme.palette.bgLightGreen.main} fontWeight={"400"} title={"Your detailed feedback is a valuable guide for us."} textAlign={'center'} />
                                </Box>
                                <Fab color="success" aria-label="add" onClick={() => { setFormSubmitted(false) }} size='small'>
                                    <ArrowBackIcon />
                                </Fab>
                            </>
                    }
                </Grid>
            </Grid>

        </>
    );
};
export const RatingButton = ({ src, title, color, handleClick }) => {
    const { classes } = useStyles();
    const theme = useTheme()

    return (
        <Box>
            <Assets
                className={classes.ratingButton}
                src={src}
                absolutePath={true}
                onClick={handleClick}
            />
            <Typography variant="body1" color={color} align="center" fontWeight={550}>{title}</Typography>
        </Box>
    );
};
export default Feedback;
