import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, TableContainer, Box, Grid, useTheme, Chip } from "@mui/material";
import { useAppContext } from "../../Context/context";
import { useLocation } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import axios from "../../APiSetUp/axios";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetLoader from "../../Components/Common/widgetLoader";
import CustomPagination from "../../Components/Common/CustomPagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        whiteSpace: "nowrap",
        padding: 8
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "8px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: "15px 10px",
        },
    };
});


const FeedbackDetails = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, user, menuList } = useAppContext();
    const location = useLocation()
    const { pathname } = location
    const theme = useTheme()
    //States

    const [feedbackDetails, setFeedbackDetails] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)

    const feedbackStatusConfig = [
        { label: "Good", status: 5, color: theme.palette.bgSuccess.main },
        { label: "Medium", status: 3, color: theme.palette.warning.main },
        { label: "Poor", status: 1, color: theme.palette.error.main },
    ]

    //Validation


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };


    const _getLeads = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
        };
        await axios.post("/feedback", body)
            .then((res) => {
                if (res?.data?.data) { setFeedbackDetails(res?.data?.data); }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    console.log(feedbackDetails, "feedbackDetails")
    useEffect(() => {
        _getLeads();
    }, [page, rowsPerPage, search]);

    return (
        <>
            <PaperContainer elevation={0} square={false}>
                <Grid container>
                    <Grid item xs={12}>
                        <TableHeading
                            title="Feedback List"
                            handleSearch={(value) => { setPage(1); setSearch(value); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            {!loading ? (
                                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Contact No.</StyledTableCell>
                                            <StyledTableCell>Comment</StyledTableCell>
                                            <StyledTableCell align="right">Rating</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {feedbackDetails?.response?.length > 0 ? feedbackDetails?.response?.map((row, index) => {
                                            const getStatusLabel = (feedback) => {
                                                const statusObject = feedbackStatusConfig?.find(item => item.status === feedback);
                                                return statusObject
                                            };
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell style={{ paddingLeft: "13px" }}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                    <StyledTableCell className={classes.paddedRow} component="th" scope="row">{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row?.mobileNo}</StyledTableCell>
                                                    <StyledTableCell><Box width={'200px'}>{row?.comments || "-"}</Box></StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Chip
                                                            variant={"outlined"}
                                                            label={getStatusLabel(row?.feedback)?.label}
                                                            style={{ height: '25px', fontWeight: 'normal', color: "white", backgroundColor: getStatusLabel(row?.feedback)?.color, border: `0.5px solid ${getStatusLabel(row?.feedback)?.color}` }}
                                                        />
                                                    </StyledTableCell>

                                                </StyledTableRow>
                                            );
                                        }) :
                                            <TableRow>
                                                <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <WidgetLoader />
                            )}
                        </TableContainer>
                    </Grid>
                    {feedbackDetails?.count > 0 &&
                        <Grid item xs={12}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(feedbackDetails?.count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />
                        </Grid>
                    }

                </Grid>
            </PaperContainer >
        </>
    );
};

export default FeedbackDetails;
