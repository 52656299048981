import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, useTheme, TableContainer, Typography, Fab, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import dayjs from "dayjs";
import CommonModal from "../../Components/Common/CommonModel";
import swal from "sweetalert";
import { Roles, permissionStatus } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import Swal from "sweetalert2";
import WidgetLoader from "../../Components/Common/widgetLoader";
import AddClientData from "../../Components/Client-Form";
import { Regex } from "../../Utils/regex";
import CustomPagination from "../../Components/Common/CustomPagination";
import DownloadIcon from '@mui/icons-material/Download';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { downloadFileFromBlob } from "../../Utils/helper";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        whiteSpace: "nowrap",
        padding: 8
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "8px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: "15px 10px",
        },
        writeBox: {
            borderRadius: "6px",
            padding: "6px",
            backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
            color: lightTheme.palette.primary.main,
            cursor: "pointer",
            height: '26px'
        },
        viewBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightSuccess.main,
            backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
            cursor: "pointer",
            height: '26px'
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px'
        },
    };
});
const ClientDataForm = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();
    const location = useLocation();
    const { pathname } = location;
    const theme = useTheme()
    //States
    const [data, setData] = useState({ services: [], visitDate: null, visitTime: null });
    const [telecallerClientDetails, setTelecallerClientDetails] = useState([]);
    const [model, setModel] = useState(false);
    const [error, setError] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [permissions, setPermissions] = useState({});
    const [search, setSearch] = useState("");
    const [newFormId, setNewFormId] = useState("");
    const [loading, setLoading] = useState(false)

    console.log(user, "user")
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };


    //Validation
    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};

        if (!data?.name) {
            formIsValid = false;
            errors["name"] = "*Please enter name.";
        }
        if (data?.services?.length == 0) {
            formIsValid = false;
            errors["services"] = "*Please select atleast one service.";
        }

        if (!data?.contactNumber) {
            formIsValid = false;
            errors["contactNumber"] = "*Please enter contact number.";
        } else if (!data?.contactNumber?.match(Regex.mobileNumberRegex)) {
            formIsValid = false;
            errors["invalidMobile"] = "*Please enter valid contact number.";
        }
        setError(errors);
        return formIsValid;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setData((prevState) => {
            if (type === "checkbox") {
                let updatedServices = prevState?.services || [];
                if (checked) {
                    updatedServices = [...updatedServices, value];
                } else {
                    updatedServices = updatedServices.filter((service) => service !== value);
                }
                return {
                    ...prevState,
                    services: updatedServices,
                };
            } else {
                return {
                    ...prevState,
                    [name]: value,
                };
            }
        });
    };

    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        toggleLoader();
        if (file?.size <= 10000000) {
            axios
                .post("/upload/image/attachment", formData)
                .then((res) => {
                    if (res?.data?.data) {
                        setData((prevState) => ({
                            ...prevState,
                            audioFile: res?.data?.data?.image,
                        }));
                    }
                }).catch((err) => {
                    toggleLoader();
                    OnUpdateError(err.data.message);
                });
        } else {
            setError({ ...error, audioSizeValid: "Upload file allowed size is 10MB", });
        }
        toggleLoader();
    };

    const handleDeleteFile = () => {
        let body = {
            url: data?.audioFile,
        };
        axios
            .post(`/upload/delete_file`, body)
            .then((res) => {
                swal("Success", res?.data?.message, "success");
                setData((prevState) => ({
                    ...prevState,
                    audioFile: null,
                }));
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            });
    };

    const handleClear = () => {
        setModel(false);
        setData({ services: [], visitDate: null, visitTime: null });
        setError({});
        setIsEdit(false);
        setNewFormId("")
        _getTelecallerDetails()
    };


    const _getTelecallerDetails = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
        };
        await axios.post(`/telecaller_client_data/get`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setTelecallerClientDetails(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    const _getNewFormById = () => {
        axios
            .get(`telecaller_client_data/${newFormId}`)
            .then((res) => {
                if (res?.data?.data) {
                    setIsEdit(true);
                    console.log(res?.data?.data, "res?.data?.data")
                    setData(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            });
    };


    const _deleteFormById = async (id) => {
        Swal.fire({
            title: "<strong>Warning</strong>",
            icon: "error",
            html: "Are you sure you want to Delete Form",
            showCancelButton: true,
            confirmButtonColor: "#0492c2",
            iconColor: "#ff0000",
            confirmButtonText: "Yes",
            cancelButtonColor: "#1A1B2F",
        }).then(async (result) => {
            if (result.isConfirmed) {
                toggleLoader();
                axios.delete(`telecaller_client_data/delete/${id}`)
                    .then((res) => {
                        if (res?.data?.data) {
                            swal("Success", res?.data?.message, "success");
                            _getTelecallerDetails();
                        }
                    })
                    .catch((err) => {
                        OnUpdateError(err.data.message);
                    }).finally(() => {
                        toggleLoader();
                    });
            }
        });
    }

    const _downloadTelecallerClientdata = async () => {
        await axios.get(`download_telecaller_client_data/${user?._id}`, { responseType: "blob" })
            .then((res) => {
                console.log(res, "resres")
                if (res?.data) {
                    downloadFileFromBlob(res?.data, "download.xlsx")
                }
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    };

    const _addUpdateNewForm = () => {
        if (handleValidation()) {
            toggleLoader();
            let body = {
                "name": data?.name,
                "services": data?.services,
                "contactNumber": data?.contactNumber,
                "clientResponse": data?.clientResponse,
                "city": data?.city,
                "visitDate": data?.visitDate,
                "visitTime": data?.visitTime,
                "audioFile": data?.audioFile,
            }
            if (isEdit && data?._id) {
                body.id = data?._id
            }
            axios.post(`telecaller_client_data/${data?._id ? "update" : "create"}`, body)
                .then((res) => {
                    if (res?.data?.data) {
                        swal("Success", res?.data?.message, "success");
                        handleClear();
                    }
                    toggleLoader();
                })
                .catch((err) => {
                    toggleLoader();
                    OnUpdateError(err.data.message);
                });
        }
    };


    useEffect(() => {
        _getTelecallerDetails();
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        if (newFormId) {
            _getNewFormById();
        }
    }, [newFormId]);

    useEffect(() => {
        const menu = menuList?.find((e) => e?.path === pathname);
        if (menu) {
            const menuPermissions = menu.permissions;
            setPermissions({
                view: menuPermissions.includes(permissionStatus.view) ? true : false,
                create: menuPermissions.includes(permissionStatus.create) ? true : false,
                update: menuPermissions.includes(permissionStatus.update) ? true : false,
                delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
            });
        }
    }, [menuList, location]);

    return (
        <>
            <PaperContainer elevation={0} square={false}>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12}>
                        <TableHeading
                            title={"Telecaller Client Data"}
                            buttonText={permissions?.create ? `Add New Form` : ""}
                            onClick={() => setModel(true)}
                            handleSearch={(value) => { setPage(1); setSearch(value); }}
                            children={
                                <>
                                    <Fab size="small" color="primary" aria-label="add" onClick={_downloadTelecallerClientdata}>
                                        <DownloadIcon fontSize="small" />
                                    </Fab>
                                </>
                            }
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableContainer>
                            {!loading ? (
                                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Contact Number</StyledTableCell>
                                            <StyledTableCell align="start">Telecaller Details</StyledTableCell>
                                            <StyledTableCell>Create date</StyledTableCell>
                                            <StyledTableCell>City</StyledTableCell>
                                            <StyledTableCell>Services</StyledTableCell>
                                            <StyledTableCell>Visit Date/Time</StyledTableCell>
                                            <StyledTableCell >Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {telecallerClientDetails?.response?.length > 0 ?
                                            telecallerClientDetails?.response?.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className={classes.paddedRow}>
                                                            {(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">{row?.name}</StyledTableCell>
                                                        <StyledTableCell>{row?.contactNumber}</StyledTableCell>
                                                        <StyledTableCell align="start">
                                                            <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                                                                <Box>
                                                                    <TextLabel fontSize={"12px"} title={"Name"} textAlign={'start'} line />
                                                                    <TextLabel fontSize={"12px"} title={row?.telecallerDetails?.name} textAlign={'start'} fontWeight={600} />
                                                                </Box>
                                                                <Box>
                                                                    <TextLabel fontSize={"12px"} title={"Branch"} textAlign={'start'} line />
                                                                    <TextLabel fontSize={"12px"} title={row?.telecallerBanchDetails?.map((e) => e?.branchName)?.join(",")} textAlign={'start'} fontWeight={600} />
                                                                </Box>
                                                            </Box>
                                                        </StyledTableCell>
                                                        <StyledTableCell>{row?.createdAt ? dayjs(row?.createdAt).format("DD/MM/YYYY") : "-"}</StyledTableCell>
                                                        <StyledTableCell>{row.city}</StyledTableCell>
                                                        <StyledTableCell>{row?.services && row?.services?.map((e) => e)?.join(",")}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <Box display={'flex'} gap={1}>
                                                                <TextLabel fontSize={"12px"} title={row?.visitDate ? dayjs(row?.visitDate).format("DD/MM/YYYY") : "-"} textAlign={'start'} fontWeight={600} />
                                                                <TextLabel fontSize={"12px"} title={row?.visitTime ? dayjs(row?.visitTime).format("LT") : "-"} textAlign={'start'} fontWeight={600} />
                                                            </Box>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {(permissions?.update || permissions?.delete) && <Box display={"flex"} gap={1}>
                                                                {permissions?.update && (
                                                                    <Assets
                                                                        className={classes.writeBox}
                                                                        src={"/assets/icons/write.svg"}
                                                                        absolutePath={true}
                                                                        onClick={() => {
                                                                            setIsEdit(true);
                                                                            setModel(true);
                                                                            setNewFormId(row?._id)
                                                                        }}
                                                                    />
                                                                )}
                                                                {permissions?.delete && (
                                                                    <Assets
                                                                        className={classes.deleteBox}
                                                                        src={"/assets/icons/delete.svg"}
                                                                        absolutePath={true}
                                                                        onClick={() => {
                                                                            _deleteFormById(row?._id)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Box>}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }) :
                                            <TableRow>
                                                <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            ) : (
                                <WidgetLoader />
                            )}
                        </TableContainer>
                    </Grid>
                </Grid>
                {telecallerClientDetails?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(telecallerClientDetails?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />

                    </Grid>}
            </PaperContainer>
            {model && (
                <CommonModal
                    open={model}
                    onClose={handleClear}
                    title={`${isEdit ? "Update" : "Add"} New Form`}
                    maxWidth={'md'}
                    content={
                        <AddClientData
                            data={data}
                            setData={setData}
                            error={error}
                            handleChange={handleChange}
                            isEdit={isEdit}
                            onSubmit={_addUpdateNewForm}
                            handleDeleteFile={handleDeleteFile}
                            handleUpload={handleUpload}

                        />
                    }
                />
            )}
        </>
    )
}

export default ClientDataForm