import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, useTheme, TableContainer, Typography, Tooltip, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonPagination from "../../Components/Common/Pagination";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import dayjs from "dayjs";
import AddFinancialData from "../../Components/FinancialData";
import CommonModal from "../../Components/Common/CommonModel";
import swal from "sweetalert";
import { Roles, permissionStatus } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import { convertToNumber, globalAmountConfig } from "../../Utils/globalConfig";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import WidgetLoader from "../../Components/Common/widgetLoader";
import Swal from "sweetalert2";
import CommonButton from "../../Components/Common/Button/CommonButton";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddExisitngPlanModel from "../../Components/FinancialData/addExisitngPlanModel";
import CustomPagination from "../../Components/Common/CustomPagination";
import BlockIcon from '@mui/icons-material/Block';
import ForeclosureAccountModal from "../../Components/FinancialData/foreclosureAccountModal";
// import ExcelJS from "exceljs";
// import FileSaver from "file-saver";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `3px solid ${"#FFF"}`,
  '&:not(:last-child)': { borderBottom: 0, },
  '&::before': { display: 'none', },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />}    {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : "#79797914",
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  padding: theme.spacing(0)
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderTop: '0px solid rgba(0, 0, 0, .125)',
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "6px",
      backgroundColor: theme.palette.bgLightExtraPrimary.main,
      color: theme.palette.primary.main,
      cursor: "pointer",
      height: '26px'
    },
    viewBox: {
      borderRadius: "6px",
      padding: "6px",
      color: theme.palette.bgLightSuccess.main,
      backgroundColor: theme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
      height: '26px'
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "6px",
      color: theme.palette.bgLightRed.main,
      backgroundColor: theme.palette.bgLightExtraRed.main,
      cursor: "pointer",
      height: '26px'
    },
  };
});

const FinancialData = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme()
  //States
  const [data, setData] = useState({ userPurchasePlan: [{ _id: null, currentInvestment: "", investmentDays: "", returnOfPercentage: "", planDate: null, remainingAmount: "" }] });
  const [addExistingData, setAddExistingData] = useState({ newPlanDate: null, newMaturityDate: null, isExistingPlan: true });
  const [financialDetails, setFinancialDetails] = useState([]);
  const [addtionalPlanDetails, setAddtionalPlanDetails] = useState([]);
  const [model, setModel] = useState(false);
  const [isAddExistingPlanModel, setIsAddExistingPlanModel] = useState(false);
  const [isForeclosureAccountModal, setIsForeclosureAccountModal] = useState(false);
  const [error, setError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [financialId, setFinancialId] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({});
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [closureConfirmationLetterFile, setClosureConfirmationLetterFile] = useState("");
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = React.useState('panel1');
  const handleAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const closeDate = (date, days) => {
    const initialDate = new Date(date);
    const newDate = new Date(initialDate);
    newDate?.setDate(initialDate?.getDate() + days);
    newDate?.setUTCHours(0, 0, 0, 0);
    const formattedDate = newDate?.toISOString();
    return dayjs(formattedDate).format("DD/MM/YYYY");
  };

  //Validation
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!selectedClient) {
      formIsValid = false;
      errors["selectedClient"] = "* Please select Client.";
    }

    if (data?.userPurchasePlan?.length > 0) {
      data?.userPurchasePlan?.map((e) => {
        if (!e?.currentInvestment) {
          formIsValid = false;
          errors["currentInvestment"] = "* Please enter Investment.";
        }
        if (!e?.investmentDays) {
          formIsValid = false;
          errors["investmentDays"] = "* Please enter Investment Days.";
        }
        if (!e?.returnOfPercentage) {
          formIsValid = false;
          errors["returnOfPercentage"] = "* Please enter Return Of Investment.";
        }
        console.log(e, "eeeeeeeee")
        if (!e?.planDate) {
          formIsValid = false;
          errors["planDate"] = "* Please select planDate.";
        }
      });
    } else {
      formIsValid = false;
      errors["validatePlan"] = "* Please add atleast one plan.";
    }

    setError(errors);
    return formIsValid;
  };

  const handleExisitingPlanValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!addExistingData?.newCurrentInvestment) {
      formIsValid = false;
      errors["newCurrentInvestment"] = "* Enter New Investment Amount.";
    }

    if (!addExistingData?.newReturnOfPercentage) {
      formIsValid = false;
      errors["newReturnOfPercentage"] = "* Enter Return Of Percentage.";
    }

    setError(errors);
    return formIsValid;
  };


  const handleForeclosureValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!data?.remainingAmount) {
      formIsValid = false;
      errors["remainingAmount"] = "* Enter remaining amount.";
    }

    if (!closureConfirmationLetterFile) {
      formIsValid = false;
      errors["closureConfirmationLetter"] = "* Please upload Closure Confirmation Letter.";
    }

    setError(errors);
    return formIsValid;
  };
  console.log(addExistingData, "addExistingData")
  const handleChange = (e, isInvestmentPlan, i) => {
    const { name, value } = e.target;

    if (isInvestmentPlan && name === 'returnOfPercentage') {
      if (value !== "" && (isNaN(value) || value < 0 || value > 100)) {
        return;
      }
    }
    if (isInvestmentPlan && name === 'investmentDays') {
      if (value !== "" && (isNaN(value) || value < 0 || value > 99999)) {
        return;
      }
    }

    if (isInvestmentPlan === true) {
      const modifyData = { ...data };
      if (modifyData.userPurchasePlan && modifyData.userPurchasePlan[i]) {
        modifyData.userPurchasePlan[i][name] = value?.replace(/,/g, '');
      }
      setData(modifyData);
    } else {
      setData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChangeAddExistingPlan = (e, isExistingPlan) => {
    const { name, value, checked } = e.target;

    if (name === 'newReturnOfPercentage') {
      if (value !== "" && (isNaN(value) || value < 0 || value > 100)) {
        return;
      }
    }

    setAddExistingData((prevState) => ({
      ...prevState,
      [name]: isExistingPlan ? checked : value,
    }));

  };

  const setUserPurchasePlanDelete = (i) => {
    setData(prevData => {
      const newData = { ...prevData };
      if (newData.userPurchasePlan && newData.userPurchasePlan.length > i) {
        newData.userPurchasePlan.splice(i, 1);
      }
      return newData;
    });
  };
  const setUserPurchasePlanAdd = () => {
    setData({
      ...data,
      userPurchasePlan: [
        ...data?.userPurchasePlan,
        { _id: null, currentInvestment: "", investmentDays: "", returnOfPercentage: "", planDate: null },
      ],
    });
  };

  const _getUsers = () => {
    axios.post("/userPurchasePlan/userList")
      .then((res) => {
        if (res?.data?.data) {
          setClients(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };
  const handleClear = () => {
    setModel(false);
    setData({ userPurchasePlan: [{ _id: null, currentInvestment: "", investmentDays: "", returnOfPercentage: "", planDate: null, remainingAmount: "" }] });
    setError({});
    setIsEdit(false);
    setFinancialId("");
    setSelectedClient("");
    _getUsers()
    _getFinancialData()
  };

  const handleAddExistingModelClear = () => {
    setIsAddExistingPlanModel(false);
    setIsForeclosureAccountModal(false);
    setData({ userPurchasePlan: [{ _id: null, currentInvestment: "", investmentDays: "", returnOfPercentage: "", planDate: null, remainingAmount: "" }] });
    setAddExistingData({ newPlanDate: null, newMaturityDate: null, isExistingPlan: true });
    setSelectedClient("");
    setFinancialId("");
    setClosureConfirmationLetterFile("")
    setIsEdit(false);
    setError({});
    _getUsers()
    _getFinancialData()
  };

  const handleEditExistingPlan = (row, val) => {
    console.log(row, val, "rowrowrow")
    setSelectedClient(val?.name);
    setData(row);
    setAddExistingData({
      ...addExistingData,
      planId: row?._id,
      newInvestmentDays: row?.investmentDays,
      newPlanDate: dayjs(row?.maturityDate).add(1, 'day').format("YYYY-MM-DD"),
      newMaturityDate: dayjs(row?.maturityDate).add(parseInt(row?.investmentDays), 'day').format("YYYY-MM-DD"),
    });
    setIsEdit(true);
    setIsAddExistingPlanModel(true)
  }
  const handleForeclosure = (row, val) => {
    setSelectedClient(val?.name);
    setData(row);
    // setAddExistingData({
    //   ...addExistingData,
    //   planId: row?._id,
    //   newInvestmentDays: row?.investmentDays,
    //   newPlanDate: dayjs(row?.maturityDate).add(1, 'day').format("YYYY-MM-DD"),
    //   newMaturityDate: dayjs(row?.maturityDate).add(parseInt(row?.investmentDays), 'day').format("YYYY-MM-DD"),
    // });
    setIsForeclosureAccountModal(true)
  }

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      axios
        .post("/upload/image/attachment", formData)
        .then((res) => {
          if (res?.data?.data) {
            setClosureConfirmationLetterFile(res?.data?.data.image);
          }
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };
  const deleteUploadFile = () => {
    let body = { url: closureConfirmationLetterFile, };
    toggleLoader()
    axios
      .post(`/upload/delete_file`, body)
      .then((res) => {
        swal("Success", res?.data?.message, "success");
        setClosureConfirmationLetterFile("");
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => toggleLoader());
  };

  console.log(data, "datadata")
  const _getFinancialData = async () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
    };
    await axios.post(`/userPurchasePlanList`, body)
      .then((res) => {
        if (res?.data?.data) { setFinancialDetails(res?.data?.data); }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getAdditionalInvestmentByPlan = async () => {
    setLoading(true)
    let body = {
      planId: data?._id,
    };
    await axios.post(`userPurchasePlan/getAdditionalInvestmentByPlan`, body)
      .then((res) => {
        console.log(res?.data, "res?.data?.data")
        if (res?.data?.data) { setAddtionalPlanDetails(res?.data?.data); }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getFinancialById = async () => {
    await axios.get(`userPurchasePlan/${financialId}`)
      .then((res) => {
        if (res?.data?.data) {
          setIsEdit(true);
          setData(res?.data?.data);
          setSelectedClient(res?.data?.data?.name);
        }
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _deleteUserPlanById = async (id) => {
    toggleLoader();
    await axios.post(`userPurchasePlan/delete/${id}`)
      .then(async (res) => {
        if (res?.data?.status === 200 && financialId) {
          await _getFinancialById()
          toggleLoader();
          swal("Success", res?.data?.message, "success");
          await _getFinancialData()
        }
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  }
  const _addUpdateFinancialData = async () => {
    if (handleValidation()) {
      toggleLoader();
      const planData = data?.userPurchasePlan.map(plan => {
        return {
          _id: plan?._id,
          investment: plan?.investment,
          currentInvestment: plan?.currentInvestment,
          returnOfPercentage: plan?.returnOfPercentage,
          investmentDays: plan?.investmentDays,
          planDate: dayjs(plan?.planDate).format("YYYY-MM-DD")
        };
      });
      let body = {
        clientId: clients?.filter((e) => e?.name == selectedClient)[0]?._id,
        userPurchasePlan: planData
      };
      // if (data?._id) {
      //   body.id = data?._id;
      // }
      await axios.post(`userPurchasePlan/${isEdit ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClear();
            _getFinancialData();
          }
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const generateExcel = (userData, fileName = "Ledger") => {
    const ExcelJS = require('exceljs');
    const FileSaver = require('file-saver');
    const dayjs = require('dayjs');
    console.log(userData, "userData")
    const workbook = new ExcelJS.Workbook();
    const investmentDataSheet = workbook.addWorksheet('Plan Details');

    const investmentColumnNames = [
      'Particulars',
      'Credit',
      'Debit',
      'Cycle (In days)',
      'ROI (%)',
      // 'Return Of Investment',
      'B/S'
    ];

    const investmentColumnWidths = [15, 15, 15, 15, 20, 20];
    investmentColumnWidths.forEach((width, index) => {
      investmentDataSheet.getColumn(index + 1).width = width;
    });

    // Add user's name in the first row
    userData.forEach(user => {
      const name = `${user?.name?.toUpperCase()} ( ${dayjs(user?.userPurchasePlan?.[0]?.planDate).format("DD/MM/YYYY")} To ${dayjs(user?.userPurchasePlan?.[0]?.maturityDate).format("DD/MM/YYYY")} )`;
      const nameRow = investmentDataSheet?.addRow([name]);
      nameRow.font = { bold: true };
      investmentDataSheet?.mergeCells(`A${nameRow?.number}:G${nameRow?.number}`); // Merge cells for user's name
      nameRow.alignment = { horizontal: 'center' }; // Center align user's name
    });

    const investmentHeaderRow = investmentDataSheet?.addRow(investmentColumnNames);
    investmentHeaderRow.font = { bold: true };

    // Iterate through data and populate Excel
    userData?.forEach(user => {
      user?.userPurchasePlan?.forEach(plan => {
        let runningBalance = 0; // Initialize running balance
        plan?.transactionsData?.forEach((transaction, index) => {
          const rowData = [];
          rowData?.push(transaction?.title || " "); // Particulars
          const transactionsDataLength = plan?.transactionsData?.length;
          if (transaction?.transactionsType === 0) {
            rowData?.push(transaction?.investment); // Debit
            rowData?.push(0); // Credit
            runningBalance += transaction?.investment;
          } else {
            rowData?.push(0); // Debit
            rowData?.push(transaction?.investment); // Credit
            // if (index < transactionsDataLength - 1) { // If not the last transaction
            //   // // runningBalance += transaction?.investment; // Add to running balance for credit
            //   // if (transaction?.isReturnOfInvestment && transaction?.isRefundInitiated) {
            //   //   runningBalance -= transaction?.investment; // Subtract from running balance for refund initiated (debit)
            //   // } else {
            //   //   runningBalance += transaction?.investment; // Add to running balance for credit
            //   // }
            // } else {
            runningBalance -= transaction?.investment; // Subtract from running balance for the last credit transaction
            // }
          }

          rowData?.push(transaction?.investmentDays); // Cycle
          rowData?.push(transaction?.returnOfPercentage); // Return Of Percentage
          // rowData?.push(transaction?.returnOfInvestment); 
          rowData?.push(runningBalance); // Running Balance

          investmentDataSheet?.addRow(rowData);
        });
      });
    });


    workbook.xlsx.writeBuffer().then(buffer => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(data, fileName + '.xlsx');
    });
  };

  const _getLedgerDetailsById = (userId, planId) => {
    toggleLoader();
    let body = { userId: userId, planId: planId }
    axios.post("/ledger", body)
      .then((res) => {
        if (res?.data?.data) {
          generateExcel(res?.data?.data, "Ledger")
        }
      })
      .catch((err) => {
        OnUpdateError(err?.message);
      }).finally(() => {
        toggleLoader();
      });
  };


  const _addUpdateExisitingPlan = async () => {
    if (handleExisitingPlanValidation()) {
      toggleLoader();
      let body = {
        planId: data?._id,
        continueWithCurrentPlan: addExistingData?.isExistingPlan,
        currentInvestment: convertToNumber(addExistingData?.newCurrentInvestment),
        returnOfPercentage: convertToNumber(addExistingData?.newReturnOfPercentage),
        investmentDays: addExistingData?.newInvestmentDays,
      }
      await axios.post(`userPurchasePlan/addMoreInvestment`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleAddExistingModelClear()
          }
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => toggleLoader());
    }
  };

  const _submitFor_forclosurePlan = async () => {
    if (handleForeclosureValidation()) {
      toggleLoader();
      let body = {
        "userId": data?.clientId,
        "planId": data?._id,
        "remainingAmount": convertToNumber(data?.remainingAmount),
        "closureConfirmationLetter": closureConfirmationLetterFile
      }
      await axios.post(`account_foreclosure`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleAddExistingModelClear()
          }
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => toggleLoader());
    }
  };
  const _deleteUpcommingPlans = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to Upcomming Plan?",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          await axios.delete(`userPurchasePlan/deleteUpcomingInvestment/${id}`)
            .then((res) => {
              swal("Success", res?.data?.message, "success");
              _getAdditionalInvestmentByPlan()
            })
            .catch((err) => {
              OnUpdateError(err.data.message);
            }).finally(() => { });
        }
      }
    });
  };

  useEffect(() => {
    _getFinancialData();
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    if (financialId) {
      _getFinancialById();
    }
  }, [financialId]);

  useEffect(() => {
    if (model) {
      _getUsers();
    }
  }, [model]);

  useEffect(() => {
    if (isAddExistingPlanModel && addExistingData?.planId && !addExistingData?.isExistingPlan) {
      _getAdditionalInvestmentByPlan();
    }
  }, [addExistingData?.isExistingPlan]);

  useEffect(() => {
    if (isForeclosureAccountModal) {
      _getAdditionalInvestmentByPlan();
    }
  }, [isForeclosureAccountModal]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);

  useEffect(() => {
    if (addExistingData?.isExistingPlan) {
      setAddExistingData({
        ...addExistingData,
        planId: data?._id,
        newInvestmentDays: data?.investmentDays,
        newPlanDate: dayjs(data?.planDate).format("YYYY-MM-DD"),
        newMaturityDate: dayjs(data?.maturityDate).format("YYYY-MM-DD"),
      });
    } else {
      setAddExistingData({
        ...addExistingData,
        planId: data?._id,
        newInvestmentDays: data?.investmentDays,
        newPlanDate: dayjs(data?.maturityDate).add(1, 'day').format("YYYY-MM-DD"),
        newMaturityDate: dayjs(data?.maturityDate).add(parseInt(data?.investmentDays), 'day').format("YYYY-MM-DD"),
      });
    }
  }, [addExistingData?.isExistingPlan])
  console.log(data?.maturityDate, "data?.maturityDate")
  return (
    <>
      <PaperContainer elevation={0} square={false}>
        <Grid container>
          <Grid item xs={12}>
            <TableHeading
              title={"Financial Data History"}
              buttonText={permissions?.create && `Add Financial Data`}
              onClick={() => setModel(true)}
              handleSearch={(value) => { setPage(1); setSearch(value); }}
            />
          </Grid>
          <Grid item xs={12}>
            {!loading ? financialDetails?.response?.length > 0 ?
              financialDetails?.response?.map((val, index) => {
                const sumOfInvestment = val?.userPurchasePlan?.reduce((total, plan) => { return total + plan?.currentInvestment }, 0);
                return <Accordion expanded={expanded === index + 'panel1'} onChange={handleAccordian(index + 'panel1')} >
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDownwardIcon sx={{ marginLeft: 3, marginRight: 3, fontSize: '1rem', color: theme.palette.primary.main }} />}>
                    <Box display={'flex'} alignItems={'center'} width="100%" justifyContent="space-between" gap={2}>
                      <Box display={'flex'} alignItems={'center'} width={{ xl: '140px', lg: "180px", md: '140px', sm: '100px', xs: '100px' }}>
                        <PersonOutlineIcon color="primary" fontSize="small" />
                        <Typography style={{ color: theme.palette.primary.main, fontSize: '14px' }} variant="body2" noWrap>
                          {val?.name?.toUpperCase()}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={{ xl: 8, lg: 8, md: 4, sm: 3, xs: 2 }}>
                        <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                          <Box>
                            <TextLabel fontSize={"12px"} title={"Total Plan"} textAlign={'start'} line />
                            <TextLabel fontSize={"12px"} title={val?.userPurchasePlan?.length} textAlign={'start'} fontWeight={600} />
                          </Box>
                          <Box>
                            <TextLabel fontSize={"12px"} title={"Total Investment"} textAlign={'start'} line />
                            <TextLabel fontSize={"12px"} title={globalAmountConfig(sumOfInvestment) || 0} textAlign={'start'} fontWeight={600} />
                          </Box>
                          <Box>
                            <TextLabel fontSize={"12px"} title={"Status"} textAlign={'start'} line />
                            <TextLabel fontSize={"12px"} color={val?.isGenerateId ? theme.palette.bgLightSuccess.main : theme.palette.bgLightBlue2.main} title={val?.isGenerateId ? "Generated" : "Pending"} textAlign={'center'} />
                          </Box>
                        </Box>
                        <Box display={"flex"} gap={1}>
                          {permissions?.update && (
                            <Assets className={classes.writeBox} src={"/assets/icons/write.svg"}
                              absolutePath={true}
                              onClick={(e) => {
                                e?.stopPropagation()
                                setData(val); setIsEdit(true); setModel(true); setFinancialId(val?._id);
                              }}
                            />
                          )}
                        </Box>
                      </Box>

                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      {val?.userPurchasePlan != undefined ? (
                        <Table sx={{ minWidth: 600 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>No.</StyledTableCell>
                              <StyledTableCell> Investment Date </StyledTableCell>
                              <StyledTableCell> Maturity Date </StyledTableCell>
                              <StyledTableCell align="center"> Investment Days </StyledTableCell>
                              <StyledTableCell align="center"> Return Amount Of Interest </StyledTableCell>
                              <StyledTableCell align="center"> Investment Amount </StyledTableCell>
                              {(user?.userType == Roles.Accountant || user?.userType != Roles.User) && <StyledTableCell align='right'> Action </StyledTableCell>}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {val?.userPurchasePlan?.length > 0 ?
                              val?.userPurchasePlan?.map((row, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell style={{ paddingLeft: "15px" }}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                    <StyledTableCell>{dayjs(row?.planDate).format("DD/MM/YYYY")}</StyledTableCell>
                                    <StyledTableCell>{dayjs(row?.maturityDate).format("DD/MM/YYYY")}</StyledTableCell>
                                    <StyledTableCell align="center">{row?.investmentDays}</StyledTableCell>
                                    <StyledTableCell align="center">{`${row?.returnOfInvestment}(${row?.returnOfPercentage}%)`}</StyledTableCell>
                                    <StyledTableCell align="center">{row?.currentInvestment ? globalAmountConfig(row?.currentInvestment || "0") : 0}</StyledTableCell>
                                    {(user?.userType != Roles.User || user?.userType == Roles.Accountant) && <StyledTableCell align='right'>
                                      <Box display={"flex"} alignItems={'center'} justifyContent={"end"} gap={1}>
                                        {user?.userType != Roles.User && <CommonButton
                                          text={`Add`}
                                          padding={"2px 2px"}
                                          fontSize='11px'
                                          startIcon={<AddOutlinedIcon />}
                                          onClick={(e) => { handleEditExistingPlan(row, val) }}
                                        />}
                                        {user?.userType == Roles.Accountant &&
                                          <CommonButton
                                            text={`Foreclosure`}
                                            padding={"2px 7px"}
                                            fontSize='12px'
                                            startIcon={<BlockIcon />}
                                            color={'error'}
                                            onClick={(e) => { handleForeclosure(row, val) }}
                                          />
                                        }
                                        {user?.userType == Roles.Accountant &&
                                          <Tooltip title={'Download Ledger'} placement="top">
                                            <Box>
                                              <Assets
                                                src={"/assets/icons/downloadIcon.svg"}
                                                absolutePath={true}
                                                onClick={() => _getLedgerDetailsById(row?.clientId, row?._id)}
                                                style={{ cursor: "pointer", marginTop: 4 }}
                                              />
                                            </Box>
                                          </Tooltip>
                                        }

                                      </Box>
                                    </StyledTableCell>}

                                  </StyledTableRow>
                                );
                              }) : <TableRow>
                                <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} title={"No Plan Found!"} />
                                </TableCell>
                              </TableRow>}
                          </TableBody>
                        </Table>
                      ) : <WidgetLoader />}
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              })
              :
              <Grid item xs={12}>
                <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} title={"No Financial Record Found!"} />
              </Grid>
              : <WidgetLoader />}
          </Grid>
          {financialDetails?.count > 0 && <Grid item xs={12}>
            <CustomPagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={Math.ceil(financialDetails?.count / rowsPerPage)}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage} />

          </Grid>}
        </Grid>

      </PaperContainer >

      {model && (
        <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add"} "Financial Data"`}
          maxWidth={'md'}
          content={
            <AddFinancialData
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              isEdit={isEdit}
              onSubmit={_addUpdateFinancialData}
              setSelectedClient={setSelectedClient}
              selectedClient={selectedClient}
              clients={clients}
              user={user}
              setUserPurchasePlanDelete={setUserPurchasePlanDelete}
              setUserPurchasePlanAdd={setUserPurchasePlanAdd}
              deleteUserPlan={_deleteUserPlanById}
            />
          }
        />
      )
      }
      {
        isAddExistingPlanModel && (
          <CommonModal
            open={isAddExistingPlanModel}
            onClose={handleAddExistingModelClear}
            title={`Add In Exisitng Plan`}
            maxWidth={'md'}
            content={
              <AddExisitngPlanModel
                data={data}
                setData={setData}
                addExistingData={addExistingData}
                setAddExistingData={setAddExistingData}
                error={error}
                handleChange={handleChangeAddExistingPlan}
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                clients={clients}
                onSubmit={_addUpdateExisitingPlan}
                addtionalPlanDetails={addtionalPlanDetails}
                deleteUpcommingPlans={_deleteUpcommingPlans}
              />
            }
          />
        )
      }
      {
        isForeclosureAccountModal && (
          <CommonModal
            open={isForeclosureAccountModal}
            onClose={handleAddExistingModelClear}
            title={`Submit For Foreclosure`}
            maxWidth={'md'}
            content={
              <ForeclosureAccountModal
                data={data}
                setData={setData}
                error={error}
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                clients={clients}
                onSubmit={_submitFor_forclosurePlan}
                handleUpload={handleUpload}
                setClosureConfirmationLetterFile={setClosureConfirmationLetterFile}
                closureConfirmationLetterFile={closureConfirmationLetterFile}
                deleteUploadFile={deleteUploadFile}
                addtionalPlanDetails={addtionalPlanDetails}
                deleteUpcommingPlans={_deleteUpcommingPlans}
              />
            }
          />
        )
      }
    </>
  );
};

export default FinancialData;
