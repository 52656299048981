import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../Context/context";
import Loader from "../Components/Loader";
import Layout from "../Components/Layout";

export const ProtectedRoutes = () => {
  const { user, menuList } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate()
  const isLogin = Boolean(user);

  const isAuth = () => {
    const menuPaths = menuList.map(menu => menu?.path);
    const isPathAvailable = menuPaths.includes(location.pathname);

    if (!isLogin) {
      return <Navigate to="/login" replace state={{ from: location.pathname }} />;
    } else if (isLogin && !menuList || menuList.length === 0) {
      return <Loader />;
    } else if (!isPathAvailable) {
      return <Navigate to="/not-found" replace />;
    } else {
      return (
        <Layout>
          <Outlet />
        </Layout>
      )
    }
  }
  return (
    isAuth()
  );
};
export const LoginRoutes = () => {
  const { user } = useAppContext();
  const location = useLocation();
  const isAuth = user ? true : false;

  return !isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: location?.pathname }} />
  );
};
