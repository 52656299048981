import React from 'react'
import { Box, Grid, Divider, FormControlLabel, FormGroup, Checkbox, TextField, InputLabel } from "@mui/material";
import { useTheme } from '@mui/styles';
import { makeStyles } from "tss-react/mui";
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import CommonButton from '../../Components/Common/Button/CommonButton';
import { Regex } from '../../Utils/regex';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import FileUpload from '../Common/uploadButton';
const useStyles = makeStyles()((theme) => {
    return {
        dateBox: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '8px',
            // minWidth: "50px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "12.5px 14px",
                fontSize: "14px !important",
                height: "18px",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: "8px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
            }
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
    };
});
const AddClientData = ({ data, error, handleChange, isEdit, onSubmit, setData, handleUpload, handleDeleteFile }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const services = ["INEVSTMENT", 'LEARNING CLASS', "PMS", "DEMAT ACCOUNT", "OTHER"]
    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Name'}
                        placeholder={"Please enter name"}
                        type='text'
                        name='name'
                        value={data?.name}
                        onChange={handleChange}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.name ? error?.name : ""} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Contact Number'}
                        placeholder={"Please enter number."}
                        type='number'
                        name='contactNumber'
                        value={data?.contactNumber}
                        onChange={(e) => handleChange(e, false)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.contactNumber ? error?.contactNumber : ""} />
                    <TextLabel fontSize={"12px"} color={"red"} title={data?.contactNumber?.match(Regex.mobileNumberRegex) ? "" : error.invalidMobile} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <CommonTextField
                        fontWeight={400}
                        text={'City'}
                        placeholder={"Please enter city"}
                        type='text'
                        name='city'
                        value={data?.city}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                        <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Services</InputLabel>
                    </Box>
                    <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                        {services?.map((e, i) => {
                            return (
                                <FormControlLabel key={i} control={<Checkbox name='services' value={e} onChange={handleChange} checked={data?.services?.includes(e)} />} label={<TextLabel fontWeight={500} fontSize={13} title={e} />} />
                            )
                        })}
                    </FormGroup>
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={data?.services?.length == 0 ? error?.services : ""} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Visit Date</InputLabel>
                        </Box>
                        <DesktopDatePicker
                            sx={{ width: '280px' }}
                            className={classes.dateBox}
                            inputFormat="MM/DD/YYYY"
                            value={data?.visitDate ? dayjs(data?.visitDate) : null}
                            onChange={(newValue) => {
                                setData({ ...data, visitDate: newValue });
                            }}
                            name='visitDate'
                            renderInput={(params) => <TextField {...params} />}
                            disablePast
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box mt={0.5} display="flex" flexDirection={"row"}                                            >
                            <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Visit Time</InputLabel>
                        </Box>
                        <MobileTimePicker
                            sx={{ width: '280px' }}
                            className={classes.dateBox}
                            value={data?.visitTime ? dayjs(data?.visitTime) : null}
                            onChange={(newValue) => {
                                setData({ ...data, visitTime: newValue });
                            }}
                            name='visitTime'
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <CommonTextField
                        fontWeight={400}
                        text={'Client Response'}
                        placeholder={"Please enter response"}
                        type='text'
                        name='clientResponse'
                        value={data?.clientResponse}
                        onChange={handleChange}
                        multiline={true}
                        rows={2}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.description ? error?.description : ""} marginBottom={10} />
                        <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Upload Audio File"} marginBottom={5} secondText={' (Optional)'} secondTextFontSize="10px" secondTextColor={theme.palette.backgroundDefaultColor.main} />
                    </Grid>
                    <FileUpload
                        handleFileChange={(e) => {
                            handleUpload(e.target.files[0]);
                        }}
                        selectedFile={data?.audioFile}
                        OnDelate={() => handleDeleteFile()}
                        acceptFile="audio/mpeg"
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.audioSizeValid} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'160px'}
                            text={`${isEdit ? "Update" : "Submit"} Form`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AddClientData