import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, TableContainer, Grid, Box, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonPagination from "../../Components/Common/Pagination";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetLoader from "../../Components/Common/widgetLoader";
import Assets from "../../Components/Common/ImageContainer";
import { permissionStatus } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import AddExpenses from "../../Components/Expenses";
import CommonModal from "../../Components/Common/CommonModel";
import dayjs from "dayjs";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { convertToNumber } from "../../Utils/globalConfig";
import CustomPagination from "../../Components/Common/CustomPagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightSuccess.main,
      backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
  };
});

const Expenses = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader, user, menuList } = useAppContext();
  const location = useLocation()
  const { pathname } = location

  //States
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [expenseDetails, setExpenseDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState({});
  const [model, setModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  console.log(data.cashExpense, "datadatadatadata")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleEdit = (row) => {
    setData(row);
    setModel(true)
    setIsEdit(true);
  };
  const handleClear = () => {
    setModel(false);
    setData({});
    setError({});
    setIsEdit(false);
  };
  console.log(error, "errro")
  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!data?.month) {
      formIsValid = false;
      errors["month"] = "* Please select Month.";
    }
    if (!data?.cashExpense) {
      formIsValid = false;
      errors["cashExpense"] = "* Please select cashExpense.";
    }
    if (!data?.bankExpense) {
      formIsValid = false;
      errors["bankExpense"] = "* Please select bankExpense.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(data?.month, "data?.month")
  const _getExpenses = () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
    };
    axios.post(`/account_cash_expense_bank_expense`, body)
      .then((res) => {
        if (res?.data?.data) {
          setExpenseDetails(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _addUpdateExpenses = async () => {
    if (handleValidation()) {
      toggleLoader();
      let body = {
        "month": dayjs(data?.month).format("MM/DD/YYYY"),
        "cashExpense": convertToNumber(data?.cashExpense),
        "bankExpense": convertToNumber(data?.bankExpense)
      }
      if (data?._id) {
        body.id = data?._id;
      }
      await axios.post(`account_cash_expense_bank_expense/${data?._id ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClear();
            _getExpenses();
          }
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => toggleLoader());
    }
  };

  const _deleteExpense = (expenseId) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to delete expense?",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .delete(`account_cash_expense_bank_expense/delete/${expenseId}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getExpenses();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          });
      }
    });
  };

  useEffect(() => {
    _getExpenses();
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);
  return (
    <>
      <PaperContainer elevation={0} square={false}>
        <Grid container>
          <Grid item xs={12}>
            <TableHeading title="Expense List" buttonText={permissions?.create ? `Add Expense` : ""} onClick={() => setModel(true)} handleSearch={(value) => { setPage(1); setSearch(value); }} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              {!loading ? (
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className={classes.paddedRow}>No</StyledTableCell>
                      <StyledTableCell>Month</StyledTableCell>
                      <StyledTableCell>Cash Expense</StyledTableCell>
                      <StyledTableCell>Bank Expense</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expenseDetails?.response?.length > 0 ?
                      expenseDetails?.response?.map((row, index) => {
                        console.log(row, "rowrow")
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                            <StyledTableCell className={classes.paddedRow}>{dayjs(row?.month).format("MMMM YYYY")}</StyledTableCell>
                            <StyledTableCell>{row.cashExpense}</StyledTableCell>
                            <StyledTableCell>{row.bankExpense}</StyledTableCell>
                            <StyledTableCell>
                              <Box display={"flex"} justifyContent={"center"} gap={1}>
                                {permissions?.update && <Assets
                                  className={classes.writeBox}
                                  src={"/assets/icons/write.svg"}
                                  absolutePath={true}
                                  onClick={() => { handleEdit(row); }}
                                />}
                                {permissions?.delete && (
                                  <Assets
                                    className={classes.deleteBox}
                                    src={"/assets/icons/delete.svg"}
                                    absolutePath={true}
                                    onClick={() => _deleteExpense(row?._id)}
                                  />
                                )}
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }) :
                      <TableRow>
                        <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} title={"No Expense Found!"}/>
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              ) : (
                <WidgetLoader />
              )}
            </TableContainer>
          </Grid>
          {expenseDetails?.count > 0 && <Grid item xs={12}>
            <CustomPagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={Math.ceil(expenseDetails?.count / rowsPerPage)}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage} />

          </Grid>}
        </Grid>

      </PaperContainer>
      {model && (
        <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add"} Expenses`}
          maxWidth={'md'}
          content={
            <AddExpenses
              handleChange={handleChange}
              error={error}
              data={data}
              setData={setData}
              isEdit={isEdit}
              onSubmit={_addUpdateExpenses}
            />
          }
        />
      )}
    </>
  );
};

export default Expenses;
