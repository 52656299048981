import React, { useEffect, useState } from "react";
import PaperContainer from "../../Components/Common/PaperContainer";
import { Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, } from "@mui/material";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableHeading from "../../Components/Common/CommonTableHeading";
import axios from "../../APiSetUp/axios";
import { useAppContext } from "../../Context/context";
import { globalAmountConfig } from "../../Utils/globalConfig";
import DataNotFound from "../Common/DataNotFound";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WidgetLoader from "../Common/widgetLoader";
import { ReportGmailerrorred } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    padding: 5,
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
    border: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    border: 0
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {

  };
});

const LeadDetails = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader } = useAppContext();

  const [topLeads, setTopLeads] = useState([])
  const [loading, setLoading] = useState(false)

  console.log(topLeads, "topLeads")
  const _getTopLeads = () => {
    let body = {};
    setLoading(true)
    axios
      .post(`/top_leads`, body)
      .then((res) => {
        if (res?.data?.data) {
          setTopLeads(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  useEffect(() => {
    _getTopLeads();
  }, []);


  return (
    <Grid item sm={12} xs={12} md={12} lg={12}>
      <PaperContainer sx={{ height: "auto", overflow: "auto" }} elevation={0}>
        <Grid item sm={12} xs={12} md={12} lg={12}>
          <TableHeading
            variant={"h6"}
            title="Top Leads"
            removeSearchField
            style={{ padding: "10px 0px 0px 10px" }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={12} lg={12}>
          <TableContainer>
            {!loading ? (
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Contact No.</StyledTableCell>
                    <StyledTableCell>Email Id</StyledTableCell>
                    <StyledTableCell>Postal Code</StyledTableCell>
                    <StyledTableCell>Branch</StyledTableCell>
                    <StyledTableCell align="center">Assign To</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topLeads?.response?.length > 0 ? topLeads?.response?.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{ paddingLeft: "13px" }}>{index + 1}</StyledTableCell>
                        <StyledTableCell className={classes.paddedRow} component="th" scope="row">{row.name || "-"}</StyledTableCell>
                        <StyledTableCell>{row?.address || "-"}</StyledTableCell>
                        <StyledTableCell>{row?.mobileNo || "-"}</StyledTableCell>
                        <StyledTableCell>{row?.email || "-"}</StyledTableCell>
                        <StyledTableCell>{row?.postalCode || "-"}</StyledTableCell>
                        <StyledTableCell>{row?.branchDetails?.map((e) => e?.branchName)?.join(",")}</StyledTableCell>
                        <StyledTableCell align="center">{row?.teleCallerDetails?.name || "-"}</StyledTableCell>
                      </StyledTableRow>
                    );
                  }) :
                    <TableRow>
                      <StyledTableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                      </StyledTableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            ) : (
              <WidgetLoader />
            )}
          </TableContainer>
        </Grid>
      </PaperContainer>
    </Grid>
  )
}

export default LeadDetails
