import { Box, Grid, } from "@mui/material";
import CommonButton from "../../Components/Common/Button/CommonButton";
import CommonTextField from "../../Components/Common/Fields/TextField";
import AutoCompleteSearch from "../../Components/Common/commonAutoComplete";
import TextLabel from "../../Components/Common/Fields/TextLabel";

const ConvertToVisitorModel = ({ data, branchList, selectedBranch, setSelectedBranch, onSubmit, error }) => {
    console.log("branchList", branchList)
    return (
        <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AutoCompleteSearch
                    fullWidth
                    backgroundColor="white"
                    text="Select Branch"
                    placeholder={"Select Branch"}
                    handleChange={(e, newValue) => setSelectedBranch(newValue)}
                    options={branchList?.map((e) => e?.branchName) || []}
                    name="branches"
                    defaultValue={selectedBranch || ""}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedBranch?.branchName ? error?.branch : ""} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <CommonButton
                        width={'200px'}
                        text={`Convert To Visitor`}
                        type="submit"
                        onClick={onSubmit}
                    />
                </Box>
            </Grid>

        </Grid>
    )
}

export default ConvertToVisitorModel