import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, TableContainer, Box, Grid, useTheme, Typography, Tooltip, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import PaperContainer from '../../Components/Common/PaperContainer';
import TableHeading from '../../Components/Common/CommonTableHeading';

import { useAppContext } from '../../Context/context';
import axios from "../../APiSetUp/axios";
import DataNotFound from '../../Components/Common/DataNotFound';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WidgetLoader from '../../Components/Common/widgetLoader';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Roles, roles } from '../../Utils/enum';
import TextLabel from '../../Components/Common/Fields/TextLabel';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CustomPagination from '../../Components/Common/CustomPagination';
import transitions from '@material-ui/core/styles/transitions';
import Assets from '../../Components/Common/ImageContainer';
import UpdateVistorHistoryModel from '../../Components/Visitor/updateVistorHistoryModel';
import CommonModal from '../../Components/Common/CommonModel';
import dayjs from "dayjs";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        padding: 5
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: '8px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `3px solid ${"#FFF"}`,
    '&:not(:last-child)': { borderBottom: 0, },
    '&::before': { display: 'none', },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />}    {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : "#79797914",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0),
    },
    padding: theme.spacing(0)
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0.5),
    borderTop: '0px solid rgba(0, 0, 0, .125)',
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: '15px 10px',
        },
        writeBox: {
            borderRadius: "6px",
            padding: "5px",
            backgroundColor: theme.palette.bgLightExtraPrimary.main,
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "5px",
            color: theme.palette.bgLightRed.main,
            backgroundColor: theme.palette.bgLightExtraRed.main,
            cursor: "pointer",
        },
    };
});

const Visitor = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, user } = useAppContext();
    const theme = useTheme()
    //States
    const [visitorDetails, setVisitorDetails] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [expanded, setExpanded] = React.useState('panel1');
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState({});
    const [model, setModel] = useState(false);
    const [visitorHistoryId, setVisitorHistoryId] = useState("")
    console.log(data, "datadata")
    //Handler

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleClear = () => {
        setData({});
        setModel(false);
        setVisitorHistoryId("")
        _getVisitor()
    };

    const _getVisitor = () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
            userType: [Roles.Visitor, Roles.User]
        }
        axios.post('/users', body).then((res) => {
            if (res?.data?.data) {
                setVisitorDetails(res?.data?.data)
            }
        }).catch((err) => {
            OnUpdateError(err.data.message);
        }).finally(() => setLoading(false));
    }

    const _getVisitorHistoryyId = () => {
        axios
            .get(`visitorHistory/by_id/${visitorHistoryId}`)
            .then((res) => {
                if (res?.data?.data) {
                    setIsEdit(true);
                    setModel(true);
                    setData(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            });
    };

    const _updateVisitorHistory = () => {
        toggleLoader();
        let body = {
            "id": data?._id,
            "reason": data?.reason,
            "reference": data?.reference
        }
        axios.post(`visitorHistory/update`, body)
            .then((res) => {
                if (res?.data?.data) {
                    swal("Success", res?.data?.message, "success");
                    handleClear()
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => { toggleLoader(); });
    };

    const _deleteVistorHistory = async (id) => {
        Swal.fire({
            title: "<strong>Warning</strong>",
            icon: "error",
            html: "Are you sure you want to delete visitor history !",
            showCancelButton: true,
            confirmButtonColor: "#0492c2",
            iconColor: "#ff0000",
            confirmButtonText: "Yes",
            cancelButtonColor: "#1A1B2F",
        }).then(async (result) => {
            if (result.isConfirmed) {
                toggleLoader();
                axios.delete(`visitorHistory/delete/${id}`)
                    .then((res) => {
                        if (res?.data?.data) {
                            swal("Success", res?.data?.message, "success");
                            _getVisitor();
                        }
                    })
                    .catch((err) => {
                        OnUpdateError(err.data.message);
                    }).finally(() => {
                        toggleLoader();
                    });
            }
        });
    }

    useEffect(() => {
        _getVisitor()
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        if (visitorHistoryId) {
            _getVisitorHistoryyId();
        }
    }, [visitorHistoryId]);

    const getRoleName = (type) => { return roles.filter((e) => e?.id == type)?.[0]?.label; };

    return (
        <>
            <PaperContainer elevation={0} square={false}>
                <Grid container >
                    <Grid item xs={12}>
                        <TableHeading title="Visitor List" handleSearch={(value) => { setPage(1); setSearch(value) }} />
                    </Grid>
                    <Grid item xs={12}>
                        {!loading ? visitorDetails?.response?.length > 0 ?
                            visitorDetails?.response?.map((val, index) => {
                                return <Accordion expanded={expanded === index + 'panel1'} onChange={handleAccordian(index + 'panel1')} >
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDownwardIcon sx={{ marginLeft: 3, marginRight: 3, fontSize: '1rem', color: theme.palette.primary.main }} />}>
                                        <Box display={'flex'} alignItems={'center'} width="100%" justifyContent={'space-between'} gap={2} flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' }}>
                                            <Box display={'flex'} gap={1} alignItems={'start'} marginLeft={1}>
                                                <HowToRegIcon color="primary" fontSize="small" />
                                                <Box>
                                                    <Typography style={{ color: theme.palette.primary.main, fontSize: '14px' }} variant="body2" noWrap>
                                                        {val?.name?.toUpperCase()}
                                                    </Typography>
                                                    <Box display={'flex'} alignItems={'start'} gap={1}>
                                                        <TextLabel fontSize={"11px"} title={"Created Date :"} color={theme.palette.bgGray.main} line fontWeight={500} />
                                                        <TextLabel fontSize={"11px"} title={dayjs(val?.createdAt).format("LL")} color={theme.palette.bgGray.main}  line fontWeight={500} />
                                                    </Box>
                                                </Box>


                                            </Box>
                                            <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'end'} gap={{ xl: 3, lg: 3, md: 3, sm: 6, xs: 2 }} >
                                                <Box display={'flex'} flexDirection={'column'} >
                                                    <TextLabel fontSize={"12px"} title={"Email"} textAlign={'end'} line fontWeight={600} />
                                                    <TextLabel fontSize={"12px"} title={val?.email || "-"} textAlign={'end'} noWrap />
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'} width={'80px'}>
                                                    <TextLabel fontSize={"12px"} title={"Contact No."} textAlign={'end'} line fontWeight={600} />
                                                    <TextLabel fontSize={"12px"} title={val?.mobileNo || "-"} textAlign={'end'} noWrap />
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'} width={'80px'}>
                                                    <TextLabel fontSize={"12px"} title={"Branch"} textAlign={'end'} line fontWeight={600} />
                                                    <TextLabel fontSize={"12px"} title={val?.branchDetails?.map((e) => e?.branchName)?.join(",") || "-"} textAlign={'end'} noWrap />
                                                </Box>
                                                {/* <Box display={'flex'} flexDirection={'column'} >
                                                    <TextLabel fontSize={"12px"} title={"Role"} textAlign={'end'} line fontWeight={600} />
                                                    <TextLabel fontSize={"12px"} title={getRoleName(val.userType)} textAlign={'end'} />
                                                </Box> */}
                                                <Box display={'flex'} flexDirection={'column'} >
                                                    <TextLabel fontSize={"12px"} title={"Address"} textAlign={'end'} line fontWeight={600} />
                                                    {/* <Tooltip title={val?.address} placement="top"> */}
                                                    <Box width={{ xl: '240px', lg: '240px', md: '220px', sm: '220px', xs: '120px' }} sx={{ lineBreak: 'anywhere', wordWrap: 'wrap' }} fontSize={"12px"} textAlign={'end'}>
                                                        {val?.address}
                                                    </Box>
                                                    {/* </Tooltip> */}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            {val?.history != undefined ?
                                                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                                    <TableHead >
                                                        <TableRow>
                                                            <StyledTableCell>No</StyledTableCell>
                                                            <StyledTableCell>Visit Date</StyledTableCell>
                                                            <StyledTableCell>Visit Time</StyledTableCell>
                                                            <StyledTableCell>Reason</StyledTableCell>
                                                            <StyledTableCell align='center'>Reference</StyledTableCell>
                                                            <StyledTableCell align='right'>Action</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {val?.history?.length > 0 ? val?.history?.map((row, index) => (
                                                            <StyledTableRow key={index} >
                                                                <StyledTableCell style={{ paddingLeft: "15px" }}>{index + 1}</StyledTableCell>
                                                                <StyledTableCell><Box >{dayjs(row?.createdAt).format("L")}</Box></StyledTableCell>
                                                                <StyledTableCell><Box >{dayjs(row?.createdAt).format("LTS")}</Box></StyledTableCell>
                                                                <StyledTableCell>{row?.reason}</StyledTableCell>
                                                                <StyledTableCell align='center'>
                                                                    <Typography display={'flex'} fontSize={'14px'} sx={{ lineBreak: 'anywhere' }} justifyContent={'center'}>{row?.reference}</Typography>
                                                                </StyledTableCell>

                                                                <StyledTableCell align='right'>
                                                                    <Box display={"flex"} justifyContent={"end"} gap={1}>
                                                                        <Assets
                                                                            className={classes.writeBox}
                                                                            src={"/assets/icons/write.svg"}
                                                                            absolutePath={true}
                                                                            height={'25px'}
                                                                            width={'25px'}
                                                                            onClick={() => {

                                                                                setVisitorHistoryId(row?._id)
                                                                            }}
                                                                        />
                                                                        <Assets
                                                                            className={classes.deleteBox}
                                                                            src={"/assets/icons/delete.svg"}
                                                                            absolutePath={true}
                                                                            height={'25px'}
                                                                            width={'25px'}
                                                                            onClick={() => _deleteVistorHistory(row?._id)}
                                                                        />
                                                                    </Box>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )) : <TableRow>
                                                            <TableCell colSpan={12}>
                                                                <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                                            </TableCell>
                                                        </TableRow>}
                                                    </TableBody>
                                                </Table> :
                                                <WidgetLoader />}
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            })
                            :
                            <Grid item xs={12}>
                                <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                            </Grid>
                            : <WidgetLoader />}
                    </Grid>

                    {visitorDetails?.count > 0 &&
                        <Grid item xs={12}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(visitorDetails?.count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />
                        </Grid>
                    }
                </Grid>
            </PaperContainer>
            {model && (
                <CommonModal
                    open={model}
                    onClose={handleClear}
                    title={`Update History Of ${data?.userDetail?.name}`}
                    maxWidth={'md'}
                    content={
                        <UpdateVistorHistoryModel
                            data={data}
                            setData={setData}
                            error={error}
                            handleChange={handleChange}
                            isEdit={isEdit}
                            onSubmit={_updateVisitorHistory}
                        />
                    }
                />
            )}
        </>
    )
}

export default Visitor
