import React from "react";
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import FormatStrikethroughOutlinedIcon from '@mui/icons-material/FormatStrikethroughOutlined';
import SuperscriptOutlinedIcon from '@mui/icons-material/SuperscriptOutlined';
import SubscriptOutlinedIcon from '@mui/icons-material/SubscriptOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import AlignHorizontalRightOutlinedIcon from '@mui/icons-material/AlignHorizontalRightOutlined';
import AlignHorizontalLeftOutlinedIcon from '@mui/icons-material/AlignHorizontalLeftOutlined';
import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { RichUtils } from "draft-js";
import Assets from "../../ImageContainer";

const Toolbar = ({ editorState, setEditorState }) => {
  const tools = [
    {
      label: "bold",
      style: "BOLD",
      icon: <FormatBoldOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "italic",
      style: "ITALIC",
      icon: <FormatItalicOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "underline",
      style: "UNDERLINE",
      icon: <FormatUnderlinedOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "highlight",
      style: "HIGHLIGHT",
      icon: <HighlightAltOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "strike-through",
      style: "STRIKETHROUGH",
      icon: <FormatStrikethroughOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "Superscript",
      style: "SUPERSCRIPT",
      icon: <SuperscriptOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "Subscript",
      style: "SUBSCRIPT",
      icon: <SubscriptOutlinedIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "Monospace",
      style: "CODE",
      icon: <CodeOutlinedIcon transform="grow-3" fontSize="small" />,
      method: "inline",
    },
    {
      label: "Blockquote",
      style: "blockQuote",
      icon: <FormatQuoteOutlinedIcon transform="grow-2" fontSize="small" />,
      method: "block",
    },
    {
      label: "Bulleted-List",
      style: "unordered-list-item",
      method: "block",
      icon: <FormatListBulletedIcon fontSize="small" />,
    },
    {
      label: "Number-List",
      style: "ordered-list-item",
      method: "block",
      icon: <FormatListNumberedIcon fontSize="small" />,
    },
    {
      label: "Code Block",
      style: "CODEBLOCK",
      icon: <IntegrationInstructionsIcon fontSize="small" />,
      method: "inline",
    },
    {
      label: "Uppercase",
      style: "UPPERCASE",
      icon:<TextFieldsIcon fontSize="small" />,
      method: "inline",
    },
    // {
    //   label: "lowercase",
    //   style: "LOWERCASE",
    //   icon: <AlignHorizontalRightOutlinedIcon fontSize="small" />,
    //   method: "inline",
    // },
    {
      label: "Left",
      style: "leftAlign",
      icon: <AlignHorizontalLeftOutlinedIcon fontSize="small" />,
      method: "block",
    },
    {
      label: "Center",
      style: "centerAlign",
      icon: <FormatAlignCenterOutlinedIcon fontSize="small" />,
      method: "block",
    },
    {
      label: "Right",
      style: "rightAlign",
      icon: <AlignHorizontalRightOutlinedIcon fontSize="small" />,
      method: "block",
    },
    { label: "H1", style: "header-one", method: "block" },
    { label: "H2", style: "header-two", method: "block" },
    { label: "H3", style: "header-three", method: "block" },
    { label: "H4", style: "header-four", method: "block" },
    { label: "H5", style: "header-five", method: "block" },
    { label: "H6", style: "header-six", method: "block" },
  ];

  const applyStyle = (e, style, method) => {
    e.preventDefault();
    method === "block"
      ? setEditorState(RichUtils.toggleBlockType(editorState, style))
      : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const isActive = (style, method) => {
    if (method === "block") {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  return (
    <div className="toolbar-grid">
      {tools.map((item, idx) => (
        <button
          style={{
            color: isActive(item.style, item.method)
              ? "rgb(129, 156, 253)"
              : 'gray',
          }}
          key={`${item.label}-${idx}`}
          title={item.label}
          onClick={(e) => applyStyle(e, item.style, item.method)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {item.icon || item.label}
        </button>
      ))}
    </div>
  );
};

export default Toolbar;
