import React from 'react'
import { Box, Grid, InputLabel, useTheme } from "@mui/material";
import TextLabel from '../Common/Fields/TextLabel';
import CommonTextField from '../Common/Fields/TextField';
import CommonButton from '../Common/Button/CommonButton';
import dayjs from "dayjs";

const UpdateVistorHistoryModel = ({ data, error, handleChange, isEdit, onSubmit }) => {
    const theme = useTheme()
    return (
        <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box display={'flex'} flexDirection={'column'} >
                    <InputLabel sx={{ fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>Name</InputLabel>
                    <InputLabel sx={{ fontWeight: 600, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>{data?.userDetail?.name || "-"}</InputLabel>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box display={'flex'} flexDirection={'column'} >
                    <InputLabel sx={{ fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>Contact No</InputLabel>
                    <InputLabel sx={{ fontWeight: 600, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>{data?.userDetail?.mobileNo || "-"}</InputLabel>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box display={'flex'} flexDirection={'column'} >
                    <InputLabel sx={{ fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>Visit Date</InputLabel>
                    <InputLabel sx={{ fontWeight: 600, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>{data?.createdAt ? dayjs(data?.createdAt)?.format("L") : "-"}</InputLabel>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box display={'flex'} flexDirection={'column'} >
                    <InputLabel sx={{ fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>Visit Time</InputLabel>
                    <InputLabel sx={{ fontWeight: 600, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px' }}>{data?.createdAt ? dayjs(data?.createdAt)?.format("LTS") : "-"}</InputLabel>

                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <CommonTextField
                    fontWeight={400}
                    text={'Reason'}
                    placeholder={"Please enter reason"}
                    type='text'
                    name='reason'
                    value={data?.reason}
                    onChange={(e) => handleChange(e, false)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.reason ? error?.reason : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <CommonTextField
                    fontWeight={400}
                    text={'Reference'}
                    placeholder={"Please enter reference name"}
                    type='text'
                    name='reference'
                    value={data?.reference}
                    onChange={(e) => handleChange(e, false)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.reference ? error?.reference : ""} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                    <CommonButton
                        width={'140px'}
                        text={`Update`}
                        type="submit"
                        onClick={onSubmit}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default UpdateVistorHistoryModel