import React from 'react'
import { Box, Grid, Divider } from "@mui/material";
import { useTheme } from '@mui/styles';
import { makeStyles } from "tss-react/mui";
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import CommonButton from '../../Components/Common/Button/CommonButton';
import AutoCompleteSearch from '../Common/commonAutoComplete';
import { globalAmountConfig } from '../../Utils/globalConfig';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import DataNotFound from '../Common/DataNotFound';
import DocumentsUpload from '../Common/documentsUploadComponent';
import { Regex } from '../../Utils/regex';
const useStyles = makeStyles()((theme) => {
    return {
        dateBox: {
            "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
            },
            "& .MuiOutlinedInput-input": {
                padding: "16.5px 14px",
                fontSize: "14px !important",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#EDF2F6",
                borderRadius: "12px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
    };
});
const requiredDocs = ["aadharcard", "pancard", "nomineeAadharCard"];

const AddNewFile = ({ data, error, handleChange, isEdit, onSubmit, setSelectedClient, selectedClient, clients, accountants, setSelectedAccountant,
    selectedAccountant, user, setUserPurchasePlanAdd, setUserPurchasePlanDelete, deleteUserPlan, documents, handleUpload, handleDeleteFile }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Box>
            <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AutoCompleteSearch
                        fullWidth
                        backgroundColor="white"
                        text="Client"
                        placeholder={"Select Client"}
                        handleChange={(e, newValue) => setSelectedClient(newValue)}
                        options={clients?.map((e) => e?.name) || []}
                        name="selectedClient"
                        defaultValue={selectedClient || ""}
                        freeSolo
                        blurOnSelect
                        disabled={isEdit ? true : false}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} title={!selectedClient ? error?.selectedClient : ""} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AutoCompleteSearch
                        fullWidth
                        backgroundColor="white"
                        text="Accountant"
                        placeholder={"Select Accountant"}
                        handleChange={(e, newValue) => setSelectedAccountant(newValue)}
                        options={accountants?.map((e) => e?.name) || []}
                        name="selectedAccountant"
                        defaultValue={selectedAccountant || ""}
                        freeSolo
                        blurOnSelect
                        disabled={isEdit ? true : false}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} title={!selectedAccountant ? error?.selectedAccountant : ""} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Nominee Name'}
                        placeholder={"Enter nominee name"}
                        type='text'
                        name='nomineeName'
                        value={data?.nomineeName}
                        onChange={(e) => handleChange(e)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.nomineeName ? error?.nomineeName : ""} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonTextField
                        fontWeight={400}
                        text={'Nominee Mobile No.'}
                        placeholder={"Enter mobile number"}
                        type='text'
                        name='nomineeMobileNo'
                        value={data?.nomineeMobileNo}
                        onChange={(e) => handleChange(e)}
                    />
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.nomineeMobileNo ? error?.nomineeMobileNo : ""} />
                    <TextLabel fontSize={"12px"} color={"red"} title={data?.nomineeMobileNo?.match(Regex.mobileNumberRegex) ? "" : error.invalidMobile} />
                </Grid>
                <Divider />
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                        <TextLabel fontSize={"16px"} fontWeight={"400"} title={'Enter Investment Details'} />
                        <Box display={'flex'} gap={2} alignItems={'center'} >
                            <CommonButton
                                width={'120px'}
                                text={'Add Plan'}
                                onClick={() => setUserPurchasePlanAdd()}
                                startIcon={<AddIcon />}
                            />
                        </Box>
                    </Box>
                </Grid>
                {data?.assignfilesDetails?.length > 0 ? data?.assignfilesDetails?.map((e, i) => {
                    return (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box width={'100%'} border={`1px solid #e2e2e2`} borderRadius={"10px"} bgcolor={'#f9f9f9'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.customGridItem}>
                                    {isEdit && e?._id !== null ?
                                        <Box display={"flex"} style={{ cursor: "pointer" }} margin={'5px 7px 0px'} justifyContent={'end'} onClick={() => Swal.fire({
                                            title: "<strong>Warning</strong>",
                                            icon: "warning",
                                            html: "Are you sure you want to delete plan?",
                                            showCancelButton: true,
                                            confirmButtonColor: "#0492c2",
                                            iconColor: "#0492c2",
                                            confirmButtonText: "Yes",
                                            cancelButtonColor: "#1A1B2F",
                                        }).then(async (result) => {
                                            if (result.isConfirmed) {
                                                deleteUserPlan(e?._id)
                                            }
                                        })}>
                                            <DeleteForeverIcon sx={{ color: "#F14336", borderRadius: 1, fontSize: "22px", marginRight: "1px", backgroundColor: "" }} />
                                        </Box> :
                                        <Box display={"flex"} style={{ cursor: "pointer" }} margin={'5px 7px 0px'} justifyContent={'end'} onClick={() => setUserPurchasePlanDelete(i)}>
                                            <CloseIcon sx={{ color: theme.palette.bgWhite.main, bgcolor: '#F14336', borderRadius: 1, fontSize: "16px", marginRight: "1px", cursor: "pointer" }} />
                                        </Box>
                                    }
                                </Grid>
                                <Grid container padding={'1px 5px 10px 5px'} spacing={1}>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <CommonTextField
                                            fontWeight={400}
                                            text={'Investment Amount'}
                                            placeholder={"Enter Investment"}
                                            type='text'
                                            name='investment'
                                            value={globalAmountConfig(e?.investment)}
                                            onChange={(e) => handleChange(e, true, i)}
                                        />
                                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!e?.investment ? error?.investment : ""} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <CommonTextField
                                            fontWeight={400}
                                            text={'Investment Days'}
                                            placeholder={"Enter Investment Days"}
                                            type='number'
                                            name='investmentDays'
                                            value={e?.investmentDays}
                                            onChange={(e) => handleChange(e, true, i)}
                                        />
                                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!e?.investmentDays ? error?.investmentDays : ""} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <CommonTextField
                                            fontWeight={400}
                                            text={'Return Of Percentage (%)'}
                                            placeholder={"Enter Return Of Percentage"}
                                            type='number'
                                            name='returnOfPercentage'
                                            value={e?.returnOfPercentage}
                                            onChange={(e) => handleChange(e, true, i)}
                                        />
                                        <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!e?.returnOfPercentage ? error?.returnOfPercentage : ""} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    );
                }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataNotFound icon={<ErrorOutlineIcon color="error" style={{ fontSize: "3rem" }} />} elevation={0} title={'No Plan Added!'} />
                    <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={data?.assignfilesDetails?.length == 0 ? error?.validatePlan : ""} />
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2 }} >
                        <TextLabel fontSize={"16px"} fontWeight={"400"} title={'Upload Documents'} />
                    </Box>
                </Grid>
                {documents?.length > 0 && documents?.map((val) => {
                    return (
                        <>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                <DocumentsUpload
                                    handleFileChange={(e) => {
                                        handleUpload(e.target.files[0], val?.key);
                                    }}
                                    selectedFile={val?.image}
                                    OnDelate={() => handleDeleteFile(val?.image, val?.key)}
                                    acceptFile="image/*,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/plain,.xlsx, .xls,"
                                    title={val?.title}
                                />
                                <TextLabel fontSize={"12px"} color={"red"} title={requiredDocs.includes(val.key) && !val.image ? error[val.key] : ""} />
                            </Grid>

                        </>
                    )
                })}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <CommonButton
                            width={'160px'}
                            text={`${isEdit ? "Update" : "Add"} New File`}
                            type="submit"
                            onClick={onSubmit}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AddNewFile