import React, { useEffect, useState } from "react";
import PaperContainer from "../../Components/Common/PaperContainer";
import { Avatar, AvatarGroup, Box, Grid, Tooltip } from "@mui/material";
import { lightTheme } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextLabel from "../Common/Fields/TextLabel";
import { useAppContext } from "../../Context/context";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import { makeStyles } from "tss-react/mui";
import { format } from 'date-fns';
import { meetingStatus, userType } from "../../Utils/enum";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DataNotFound from "../Common/DataNotFound";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WidgetLoader from "../Common/widgetLoader";

const useStyles = makeStyles()((theme) => {
    return {
        customButtom: {
            background: lightTheme.palette.bgWhite.main,
            borderRadius: "8px",
            color: lightTheme.palette.bgDarkPrimary.main,
            border: "2px solid #EDF2F6",
            "&:hover": {
                backgroundColor: "#fff",
            },
        },
        customTab: {
            color: lightTheme.palette.bgLightExtraLightGray.main,
            minHeight: "22px",
            backgroundColor: "#EDF2F6",
            borderRadius: "6px",
            fontSize: "10px",
            fontWeight: "500",
            padding: "8px 10px",
            minWidth: "0",
            marginRight: "8px",
            "&.Mui-selected": {
                color: theme.palette.bgWhite.main,
                backgroundColor: theme.palette.primary.main,
            },
        },
        customIndicators: {
            minHeight: "40px",
            "& .MuiTabs-indicator": {
                display: "none",
            },
        },
        customTabPanel: {
            padding: "0px !important",
        },
        card: {
            padding: "20px",
            borderRadius: "16px",
            border: "1px solid #F8F9FA",
            boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.50)",
            background: "#FFF",
        },
        cardImage: {
            height: "150px",
            border: "1px solid #EDF2F6",
            borderRadius: "16px",
            backgroundPosition: "top center",
        },
        cardContent: {
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 5px",
            gap: "5px",
        },
        cardDescription: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 4,
            minHeight: "70px",
        },
    };
});

const ScheduledMeeting = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader } = useAppContext();

    const [value, setValue] = useState("Upcoming");
    const [meetingDetails, setMeetingDetails] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)



    const convertToAmPm = (timeInMinutes) => {
        const hours = Math.floor(timeInMinutes / 60);
        const minutes = timeInMinutes % 60;
        const period = hours < 12 ? "AM" : "PM";
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
    };

    const colorCombinations = [
        { backgroundColor: "rgba(205, 249, 255, 1)", borderColor: "rgba(0, 196, 223, 1)" },
        { backgroundColor: "rgba(255, 229, 205, 1)", borderColor: "rgba(231, 142, 60, 1)" },
        { backgroundColor: "rgba(215, 205, 255, 1)", borderColor: "rgba(119, 97, 205, 1)" }
    ];


    const upcomingData = meetingDetails?.length > 0 ? meetingDetails?.map((item, index) => {
        const colorIndex = index % colorCombinations.length;
        const colorCombination = colorCombinations[colorIndex];
        const getSlotStartTime = item?.slot_time?.filter((e) => e?.isBooked)
        const meetingDate = new Date(item?.meetingDate);
        const formattedDate = format(meetingDate, "do MMM");
        return {
            name: item?.creatorDetails?.name,
            subline: '',
            time: `${formattedDate}, ${convertToAmPm(getSlotStartTime[0]?.startTime)}`,
            backgroundColor: colorCombination.backgroundColor,
            borderColor: colorCombination.borderColor,
            buttonName: item?.meetingWithDetails?.name,
            meetingWithDetails: item?.meetingWithDetails,
            clientDetails: item?.clientDetails,
            creatorDetails: item?.creatorDetails,
        };
    }) : [];
    console.log("upcomingData", upcomingData)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function getLabelByUserTypeId(id, array) {
        const item = array.find(item => item.id === id);
        return item ? item.label : undefined;
    }

    const _getMeetingList = () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
            status: value === "Upcoming" ? [meetingStatus?.approvalPending, meetingStatus?.approved] : value === "Past" ? [meetingStatus?.completed] : [meetingStatus?.onGoing]
        };

        axios
            .post(`meetingList`, body)
            .then((res) => {
                if (res?.data?.data?.response) {
                    setMeetingDetails(res?.data?.data?.response);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };


    useEffect(() => {
        _getMeetingList();
    }, [value])

    return (
        // <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
            <PaperContainer sx={{ height: "400px" }} elevation={0}>
                <TextLabel
                    variant={"h6"}
                    fontWeight={600}
                    title={"Scheduled Meetings"}
                    style={{ padding: "10px 0px 0px 10px" }}
                />
                <Box padding={1} display={"flex"} flexDirection={"column"}>
                    <TabContext value={value} padding={"0px"}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            className={classes.customIndicators}
                        >
                            <Tab className={classes.customTab} label="Upcoming" value={"Upcoming"} />
                            <Tab className={classes.customTab} label="Past" value={"Past"} />
                            <Tab className={classes.customTab} label="Ongoing" value={"Ongoing"} />
                        </TabList>
                        <TabPanel value="Upcoming" className={classes.customTabPanel}>
                            <Box
                                sx={{
                                    margin: "0px",
                                    maxHeight: "300px",
                                    overflowY: upcomingData?.length > 0 ? "scroll" : "hidden",
                                    "::-webkit-scrollbar": {
                                        width: "0.5px",
                                    },
                                    "::-webkit-scrollbar-thumb": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {loading ? <WidgetLoader /> : upcomingData?.length > 0 ? upcomingData.map((item) => {
                                    return (
                                        <Box
                                            backgroundColor={item.backgroundColor}
                                            padding={2}
                                            marginBottom={1}
                                            sx={{ borderLeft: `5px solid ${item.borderColor}`, minWidth: "300px", }}
                                            display={'flex'}
                                            flexDirection={'column'}
                                        >
                                            <>
                                                <Box display={"flex"} gap={2} alignItems={'center'}>
                                                    <Box display={"flex"} gap={0.5} flexDirection={'column'}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Avatar src={Image_BASE_URL + item?.clientDetails?.avtar} />
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                    <TextLabel variant={"body2"} fontWeight={600} color={"theme.palette.primary.main"} title={item?.clientDetails?.[0]?.name?.toUpperCase()} />
                                                                    <TextLabel variant={"body2"} fontWeight={500} color={lightTheme.palette.bgLightExtraLightGray.main} title={getLabelByUserTypeId(item?.clientDetails?.[0]?.userType, userType)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {item?.clientDetails?.length > 1 && <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <AvatarGroup max={3}>
                                                                    {item?.clientDetails?.splice(1)?.map((e) => {
                                                                        return (
                                                                            <Tooltip title={e?.name} placement="right-start">
                                                                                <Avatar alt={e?.name} src={e?.avtar} />
                                                                            </Tooltip>
                                                                        )
                                                                    })}
                                                                </AvatarGroup>
                                                            </Box>
                                                        </Box>}
                                                    </Box>
                                                    <CompareArrowsIcon color="primary" />
                                                    <Box display={"flex"} gap={0.5} flexDirection={'column'}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Avatar src={Image_BASE_URL + item?.meetingWithDetails?.avtar} />
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                    <TextLabel variant={"body2"} fontWeight={600} color={"theme.palette.primary.main"} title={item?.meetingWithDetails?.[0]?.name?.toUpperCase()} />
                                                                    <TextLabel variant={"body2"} fontWeight={500} color={lightTheme.palette.bgLightExtraLightGray.main} title={getLabelByUserTypeId(item?.meetingWithDetails?.[0]?.userType, userType)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {item?.meetingWithDetails?.length > 1 && <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <AvatarGroup max={3}>
                                                                    {item?.meetingWithDetails?.splice(1)?.map((e) => {
                                                                        return (
                                                                            <Tooltip title={e?.name} placement="right-start">
                                                                                <Avatar alt={e?.name} src={e?.avtar} />
                                                                            </Tooltip>
                                                                        )
                                                                    })}
                                                                </AvatarGroup>
                                                            </Box>
                                                        </Box>}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} alignItems={'center'} justifyContent={'flex-end'}>
                                                    <TextLabel
                                                        variant={"body2"}
                                                        fontWeight={500}
                                                        color={lightTheme.palette.bgLightExtraLightGray.main}
                                                        title={item?.time}
                                                    />
                                                </Box>

                                            </>
                                        </Box>

                                    );
                                }) : <DataNotFound title="No Upcomming Meeting Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}
                            </Box>
                        </TabPanel>
                        <TabPanel value="Past" className={classes.customTabPanel}>
                            <Box
                                sx={{
                                    margin: "0px",
                                    maxHeight: "300px",
                                    overflowY: upcomingData?.length > 0 ? "scroll" : "hidden",
                                    "::-webkit-scrollbar": {
                                        width: "0.5px",
                                    },
                                    "::-webkit-scrollbar-thumb": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {upcomingData?.length > 0 ? upcomingData.map((item) => {
                                    return (
                                        <Box
                                            backgroundColor={item.backgroundColor}
                                            padding={2}
                                            marginBottom={1}
                                            sx={{ borderLeft: `5px solid ${item.borderColor}`, minWidth: "300px", }}
                                            display={'flex'}
                                            flexDirection={'column'}
                                        >
                                            <>
                                                <Box display={"flex"} gap={2} alignItems={'center'}>
                                                    <Box display={"flex"} gap={0.5} flexDirection={'column'}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Avatar src={Image_BASE_URL + item?.clientDetails?.avtar} />
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                    <TextLabel variant={"body2"} fontWeight={600} color={"theme.palette.primary.main"} title={item?.clientDetails?.[0]?.name?.toUpperCase()} />
                                                                    <TextLabel variant={"body2"} fontWeight={500} color={lightTheme.palette.bgLightExtraLightGray.main} title={getLabelByUserTypeId(item?.clientDetails?.[0]?.userType, userType)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {item?.clientDetails?.length > 1 && <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <AvatarGroup max={3}>
                                                                    {item?.clientDetails?.splice(1)?.map((e) => {
                                                                        return (
                                                                            <Tooltip title={e?.name} placement="right-start">
                                                                                <Avatar alt={e?.name} src={e?.avtar} />
                                                                            </Tooltip>
                                                                        )
                                                                    })}
                                                                </AvatarGroup>
                                                            </Box>
                                                        </Box>}
                                                    </Box>
                                                    <CompareArrowsIcon color="primary" />
                                                    <Box display={"flex"} gap={0.5} flexDirection={'column'}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Avatar src={Image_BASE_URL + item?.meetingWithDetails?.avtar} />
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                    <TextLabel variant={"body2"} fontWeight={600} color={"theme.palette.primary.main"} title={item?.meetingWithDetails?.[0]?.name?.toUpperCase()} />
                                                                    <TextLabel variant={"body2"} fontWeight={500} color={lightTheme.palette.bgLightExtraLightGray.main} title={getLabelByUserTypeId(item?.meetingWithDetails?.[0]?.userType, userType)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {item?.meetingWithDetails?.length > 1 && <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <AvatarGroup max={3}>
                                                                    {item?.meetingWithDetails?.splice(1)?.map((e) => {
                                                                        return (
                                                                            <Tooltip title={e?.name} placement="right-start">
                                                                                <Avatar alt={e?.name} src={e?.avtar} />
                                                                            </Tooltip>
                                                                        )
                                                                    })}
                                                                </AvatarGroup>
                                                            </Box>
                                                        </Box>}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} alignItems={'center'} justifyContent={'flex-end'}>
                                                    <TextLabel
                                                        variant={"body2"}
                                                        fontWeight={500}
                                                        color={lightTheme.palette.bgLightExtraLightGray.main}
                                                        title={item?.time}
                                                    />
                                                </Box>
                                            </>
                                        </Box>

                                    );
                                }) : <DataNotFound title="No Past Meeting Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}
                            </Box>
                        </TabPanel>
                        <TabPanel value="Ongoing" className={classes.customTabPanel}>
                            <Box
                                sx={{
                                    margin: "0px",
                                    maxHeight: "300px",
                                    overflowY: upcomingData?.length > 0 ? "scroll" : "hidden",
                                    "::-webkit-scrollbar": {
                                        width: "0.5px",
                                    },
                                    "::-webkit-scrollbar-thumb": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {upcomingData?.length > 0 ? upcomingData.map((item) => {
                                    return (
                                        <Box
                                            backgroundColor={item.backgroundColor}
                                            padding={2}
                                            marginBottom={1}
                                            sx={{ borderLeft: `5px solid ${item.borderColor}`, minWidth: "300px", }}
                                            display={'flex'}
                                            flexDirection={'column'}
                                        >
                                            <>
                                                <Box display={"flex"} gap={2} alignItems={'center'}>
                                                    <Box display={"flex"} gap={0.5} flexDirection={'column'}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Avatar src={Image_BASE_URL + item?.clientDetails?.avtar} />
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                    <TextLabel variant={"body2"} fontWeight={600} color={"theme.palette.primary.main"} title={item?.clientDetails?.[0]?.name?.toUpperCase()} />
                                                                    <TextLabel variant={"body2"} fontWeight={500} color={lightTheme.palette.bgLightExtraLightGray.main} title={getLabelByUserTypeId(item?.clientDetails?.[0]?.userType, userType)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {item?.clientDetails?.length > 1 && <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <AvatarGroup max={3}>
                                                                    {item?.clientDetails?.splice(1)?.map((e) => {
                                                                        return (
                                                                            <Tooltip title={e?.name} placement="right-start">
                                                                                <Avatar alt={e?.name} src={e?.avtar} />
                                                                            </Tooltip>
                                                                        )
                                                                    })}
                                                                </AvatarGroup>
                                                            </Box>
                                                        </Box>}
                                                    </Box>
                                                    <CompareArrowsIcon color="primary" />
                                                    <Box display={"flex"} gap={0.5} flexDirection={'column'}>
                                                        <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Avatar src={Image_BASE_URL + item?.meetingWithDetails?.avtar} />
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                    <TextLabel variant={"body2"} fontWeight={600} color={"theme.palette.primary.main"} title={item?.meetingWithDetails?.[0]?.name?.toUpperCase()} />
                                                                    <TextLabel variant={"body2"} fontWeight={500} color={lightTheme.palette.bgLightExtraLightGray.main} title={getLabelByUserTypeId(item?.meetingWithDetails?.[0]?.userType, userType)} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {item?.meetingWithDetails?.length > 1 && <Box display={'flex'} alignItems={'center'} gap={1}>
                                                            <Box display={'flex'} alignItems={'self-start'} flexDirection={'column'} >
                                                                <AvatarGroup max={3}>
                                                                    {item?.meetingWithDetails?.splice(1)?.map((e) => {
                                                                        return (
                                                                            <Tooltip title={e?.name} placement="right-start">
                                                                                <Avatar alt={e?.name} src={e?.avtar} />
                                                                            </Tooltip>
                                                                        )
                                                                    })}
                                                                </AvatarGroup>
                                                            </Box>
                                                        </Box>}
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} alignItems={'center'} justifyContent={'flex-end'}>
                                                    <TextLabel
                                                        variant={"body2"}
                                                        fontWeight={500}
                                                        color={lightTheme.palette.bgLightExtraLightGray.main}
                                                        title={item?.time}
                                                    />
                                                </Box>
                                            </>
                                        </Box>

                                    );
                                }) : <DataNotFound title="No Ongoing Meeting Found !" icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </PaperContainer>
        </Grid>
        // </Grid >
    )
}

export default ScheduledMeeting