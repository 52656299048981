import React, { useState } from 'react'
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, TableContainer, Box, Grid, useTheme, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import PaperContainer from '../../Components/Common/PaperContainer';
import TableHeading from '../../Components/Common/CommonTableHeading';

import CommonButton from '../../Components/Common/Button/CommonButton';
import { useAppContext } from '../../Context/context';
import axios from "../../APiSetUp/axios";
import dayjs from "dayjs";
import { closeDate } from '../../Utils/helper';
import { globalAmountConfig } from '../../Utils/globalConfig';
import WidgetLoader from '../../Components/Common/widgetLoader';
import DataNotFound from '../../Components/Common/DataNotFound';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import CustomPagination from '../../Components/Common/CustomPagination';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        padding: 5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: '8px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: '15px 10px',
        },
    };
});

const InitiateRefund = () => {
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader } = useAppContext();
    const theme = useTheme()

    //States
    const [initiateRefundList, setInitiateRefundList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)
    //Handler
    console.log(initiateRefundList, "initiateRefundList")
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const _getInitiateRefundList = () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
        };
        axios.post("/initiate_refund_List", body)
            .then((res) => {
                if (res?.data?.data) { setInitiateRefundList(res?.data?.data); }
            })
            .catch((err) => {
                OnUpdateError(err?.data?.message);
            }).finally(() => setLoading(false));
    };

    const _initiateRefundById = async (id) => {
        toggleLoader();
        let body = {
            transactionsId: id,
        };
        await axios.post(`refund_initiate`, body)
            .then(async (res) => {
                if (res?.data?.status === 200) {
                    swal("Success", res?.data?.message, "success");
                    await _getInitiateRefundList()
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => toggleLoader());
    }

    React.useEffect(() => {
        _getInitiateRefundList()
    }, [page, rowsPerPage, search]);

    return (
        <>
            <PaperContainer elevation={0} square={false}>
                <Grid container xs={12} lg={12} md={12} sm={12}>
                    <Grid itemxs={12} lg={12} md={12} sm={12}>
                        <TableHeading title="Initiate Refund" handleSearch={(value) => { setPage(1); setSearch(value); }} />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12}>
                        <TableContainer>
                            {!loading ? <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                <TableHead >
                                    <TableRow>
                                        <StyledTableCell className={classes.paddedRow}>No</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        {/* <StyledTableCell>Plan Date</StyledTableCell>
                                        <StyledTableCell>Maturity Date</StyledTableCell>
                                        <StyledTableCell>Investment Amount</StyledTableCell>
                                        <StyledTableCell>Investment Days</StyledTableCell> */}
                                        <StyledTableCell>Return Of Amount</StyledTableCell>
                                        <StyledTableCell align='center'>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {initiateRefundList?.response?.length > 0 ? initiateRefundList?.response?.map((row, index) => (
                                        <StyledTableRow key={index} >
                                            <StyledTableCell>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                            <StyledTableCell className={classes.paddedRow}>{row?.userDetail?.name}</StyledTableCell>
                                            {/* <StyledTableCell>{dayjs(row?.userparchesplansDetail?.planDate).format("DD/MM/YYYY")}</StyledTableCell>
                                            <StyledTableCell>{dayjs(row?.userparchesplansDetail?.maturityDate).format("DD/MM/YYYY")}</StyledTableCell>
                                            <StyledTableCell>{globalAmountConfig(row?.investment)}</StyledTableCell>
                                            <StyledTableCell >{row?.investmentDays}</StyledTableCell> */}
                                            {/* <StyledTableCell align="center">{`${row?.returnOfInvestment}(${row?.returnOfPercentage}%)`}</StyledTableCell> */}
                                            <StyledTableCell>{globalAmountConfig(row?.investment)}</StyledTableCell>
                                            <StyledTableCell align='center'>
                                                <CommonButton
                                                    width={'90px'}
                                                    text={`Initiate`}
                                                    padding={"2px 2px"}
                                                    fontSize='11px'
                                                    onClick={() => Swal.fire({
                                                        title: "<strong>Warning</strong>",
                                                        icon: "warning",
                                                        html: "Are you sure you want to initiate refund?",
                                                        showCancelButton: true,
                                                        confirmButtonColor: "#0492c2",
                                                        iconColor: "#0492c2",
                                                        confirmButtonText: "Yes",
                                                        cancelButtonColor: "#1A1B2F",
                                                    }).then(async (result) => {
                                                        if (result.isConfirmed) {
                                                            _initiateRefundById(row?._id)
                                                        }
                                                    })}
                                                    background={theme.palette.bgDarkPrimary.main}
                                                />
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    )) : <TableRow>
                                        <TableCell colSpan={12}>
                                            <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} title={'No Refund Found!'}/>
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table> : <WidgetLoader />}
                        </TableContainer>
                    </Grid>
                    {initiateRefundList?.count > 0 && <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(initiateRefundList?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    </Grid>}
                </Grid>
            </PaperContainer >
        </>
    )
}

export default InitiateRefund