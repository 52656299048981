import { useTheme } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CommonBarChart({ totalRevenue }) {
  const theme = useTheme()
  if (!Array.isArray(totalRevenue)) {
    console.error("totalRevenue is not an array");
    return null;
  }
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        width={500}
        height={300}
        data={totalRevenue}
        margin={{
          top: 15,
          right: 15,
          left: 5,
          bottom: 5
        }}
      >
        <XAxis dataKey="day" fontSize={'12px'} tick={{ fill: theme.palette.bgGray.main }} stroke={theme.palette.info.main} />
        <YAxis fontSize={'12px'} tick={{ fill: theme.palette.bgGray.main }} stroke={theme.palette.info.main} />
        {/* <Tooltip cursor={{ fill: theme.palette.bgTrendOrange.main }} /> */}
        <Tooltip cursor={{ fill: theme.palette.bgTrendOrange.main }} contentStyle={{ fontSize: '12px', width: 'auto', border: `1px solid ${theme.palette.info.main}` }} />
        <Legend iconType="triangle" />
        <Bar dataKey="totalRevenue" label='Total Revenue' name="Total Revenue" fill={theme.palette.bgLightBlue2.main} />
        <Bar dataKey="totalPayout" name="Total Payout" fill={theme.palette.warning.main} />
      </BarChart>
    </ResponsiveContainer>
  );
}
