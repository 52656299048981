import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CommonButton from "../../Components/Common/Button/CommonButton";
import CommonTextField from "../../Components/Common/Fields/TextField";
import AutoCompleteSearch from "../../Components/Common/commonAutoComplete";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import FileUpload from "../../Components/Common/uploadButton";

const AssignToReceptionalistModel = ({ data, selectedReceptionalist, setSelectedReceptionalist, onSubmit, handleUpload, handleDeleteFile, error, receptionalistForLeads, handleChange }) => {
    return (
        <Grid container spacing={1} xs={12} md={12} lg={12} sm={12} p={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <CommonTextField
                    fontWeight={400}
                    text={'Lead'}
                    type='text'
                    name='name'
                    value={data?.name}
                    disabled
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.name ? error?.name : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutoCompleteSearch
                    fullWidth
                    backgroundColor="white"
                    // width={"300px"}
                    text="Receptionalist"
                    placeholder={"Select Receptionalist"}
                    handleChange={(e, newValue) => setSelectedReceptionalist(newValue)}
                    options={receptionalistForLeads?.response?.map((e) => e?.name) || []}
                    name="receptionalist"
                    defaultValue={selectedReceptionalist || ""}
                    freeSolo
                    blurOnSelect
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedReceptionalist?.name ? error?.receptionalist : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <CommonTextField
                    fontWeight={400}
                    text={'Description'}
                    placeholder={"Enter Description"}
                    type='text'
                    name='description'
                    value={data?.description}
                    onChange={(e) => handleChange(e, false)}
                    multiline
                    rows={3}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '60px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.description ? error?.description : ""} marginBottom={10} />
                    <TextLabel fontSize={"14px"} color={"#151D48"} fontWeight={"400"} title={"Upload Audio File"} marginBottom={5} secondText={' (Optional)'} secondTextFontSize="10px" />
                </Grid>
                <FileUpload
                    handleFileChange={(e) => {
                        handleUpload(e.target.files[0]);
                    }}
                    selectedFile={data?.audioFile}
                    OnDelate={() => handleDeleteFile()}
                    acceptFile="audio/mpeg"
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.audioSizeValid} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <CommonButton
                        width={'200px'}
                        text={`Assign`}
                        type="submit"
                        onClick={onSubmit}
                    />
                </Box>
            </Grid>

        </Grid>
    )
}

export default AssignToReceptionalistModel