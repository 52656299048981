import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from '@mui/material/styles';
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { useAppContext } from "../../Context/context";
import { useLocation } from "react-router-dom";
import { Box, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import EmailTemplateConfiguration from "../../Components/Setting/emailTemplateConfiguration";
import axios from "../../APiSetUp/axios";
import swal from "sweetalert";
import { ContentState, EditorState, Modifier, convertFromHTML } from "draft-js";
import { permissionStatus } from "../../Utils/enum";
import CommonPagination from "../../Components/Common/Pagination";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetLoader from "../../Components/Common/widgetLoader";
import { styled } from "@mui/material/styles";
import Assets from "../../Components/Common/ImageContainer";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import Swal from "sweetalert2";
import CustomPagination from "../../Components/Common/CustomPagination";

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },

    writeBox: {
      borderRadius: "6px",
      padding: "6px",
      backgroundColor: theme.palette.bgLightExtraPrimary.main,
      color: theme.palette.primary.main,
      cursor: "pointer",
      height: '26px'
    },
    viewBox: {
      borderRadius: "6px",
      padding: "6px",
      color: theme.palette.bgLightSuccess.main,
      backgroundColor: theme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
      height: '26px'
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "6px",
      color: theme.palette.bgLightRed.main,
      backgroundColor: theme.palette.bgLightExtraRed.main,
      cursor: "pointer",
      height: '26px'
    },
  };
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    padding: 5
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EmailConfiguration = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader, user, menuList } = useAppContext();
  const location = useLocation()
  const { pathname } = location
  const theme = useTheme()
  //States
  const [data, setData] = useState();
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [error, setError] = useState({});
  const [value, setValue] = React.useState(0);
  const [isTamplateOpen, setIsTamplateOpen] = React.useState(false);
  const [allVariables, setAllVariables] = React.useState();
  const [selectedVariables, setSelectedVariables] = React.useState(new Set());
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [allEmailTemplates, setAllEmailTemplates] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [htmlCode, setHtmlCode] = useState('');
  const [permissions, setPermissions] = useState({});

  console.log(rowsPerPage, "rowsPerPage")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value, "event.target.value")
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleHtmlCodeChange = (code) => {
    setHtmlCode(code);
  };

  const onEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const htmlToEditorState = (html) => {
    const contentBlocks = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    return EditorState.createWithContent(contentState);
  };
  console.log(allEmailTemplates?.count, "allEmailTemplates?.coun")
  const insertVariable = (variable) => {
    const currentEditorState = editorState;
    const currentContentState = currentEditorState.getCurrentContent();
    const currentSelectionState = currentEditorState.getSelection();

    // Check if selection is collapsed
    if (currentSelectionState.isCollapsed()) {
      // Insert text at current cursor position
      const contentWithVariable = Modifier.insertText(currentContentState, currentSelectionState, variable, currentEditorState.getCurrentInlineStyle().add('BOLD'));

      // Update editor state with new content
      const newEditorState = EditorState.push(currentEditorState, contentWithVariable, 'insert-characters');

      // Set selection after insertion
      const selectionAfterInsert = currentSelectionState.merge({
        anchorOffset: currentSelectionState.getEndOffset() + variable.length,
        focusOffset: currentSelectionState.getEndOffset() + variable.length,
      });

      const editorStateAfterInsert = EditorState.forceSelection(newEditorState, selectionAfterInsert);
      setEditorState(editorStateAfterInsert);
    } else {
      // Replace selected text with variable
      const contentWithVariable = Modifier.replaceText(currentContentState, currentSelectionState, variable, currentEditorState.getCurrentInlineStyle().add('BOLD'));
      const newEditorState = EditorState.push(currentEditorState, contentWithVariable, 'insert-characters');
      setEditorState(newEditorState);
    }
  };

  const handleEdit = (row) => {
    console.log(row, "rowrow")
    setSelectedTemplateType(row?.emailTemplateLayoutDetails?.templateName)
    setAllVariables(row?.emailTemplateLayoutDetails?.variables)
    setData(row);
    // Set the new editor state
    const newEditorState = htmlToEditorState(row.content);
    setEditorState(newEditorState);
    setHtmlCode(row?.content)
    setIsTamplateOpen(true)
  };

  const clearEmailState = () => {
    setAllVariables([])
    setSelectedTemplateType("")
    setData({})
    setSelectedVariables(new Set())
    setHtmlCode("")
    setEditorState(() => EditorState.createEmpty());
    _getAllEmailTemplate()
  }
  const validateEmailConfiguration = () => {
    let formIsValid = true;
    let errors = {};
    if (!selectedTemplateType) {
      formIsValid = false;
      errors["selectedTemplateType"] = "Please select template type.";
    }
    if (!data?.templateName) {
      formIsValid = false;
      errors["templateName"] = "Please enter template name.";
    }
    if (htmlCode === "<p><br></p>") {
      formIsValid = false;
      errors["htmlCode"] = "Please create formatted template.";
    }

    setError(errors);
    return formIsValid;
  }

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const _createTemplate = () => {
    if (validateEmailConfiguration()) {
      setLoading(true)
      let body = {
        layoutTemplateId: data?.selectedTemplateId,
        templateName: data?.templateName,
        content: htmlCode
      };
      if (data?._id) {
        body.id = data?._id;
      }
      axios.post(data?._id ? "email_template/update" : `email_template/create`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            setIsTamplateOpen(false)
            clearEmailState()
          }
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => setLoading(false));
    }
  };

  const _deleteTemplate = (templateId) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to delete template?",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .delete(`email_template/delete/${templateId}`)
          .then((res) => {
            if (res?.data?.data) {
              swal("Success", res?.data?.message, "success");
              _getAllEmailTemplate();
            }
          })
          .catch((err) => {
            OnUpdateError(err.data.message);
          });
      }
    });
  };

  const _getTemplateTypesAndVariables = () => {
    setLoading(true)
    axios.post(`/email_template_layout`, {})
      .then((res) => {
        if (res?.data?.data) {
          setTemplateTypes(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getAllEmailTemplate = () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
    };
    axios.post(`/email_template`, body)
      .then((res) => {
        if (res?.data?.data) {
          setAllEmailTemplates(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };


  useEffect(() => {
    isTamplateOpen && _getTemplateTypesAndVariables();
  }, [isTamplateOpen]);

  useEffect(() => {
    _getAllEmailTemplate();
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    !data?._id && setAllVariables(templateTypes?.response?.find(item => item?._id === data?.selectedTemplateId)?.variables)
  }, [selectedTemplateType])

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);
  return (
    <>
      <PaperContainer elevation={0} square={false} >
        <Grid container spacing={0} xs={12} smm={12} md={12} lg={12} p={1}>
          <Grid item xs={12} smm={12} md={12} lg={12}>
            <TableHeading
              title="Email Configuration"
              buttonText={permissions?.create ? `Add Template` : ""}
              onClick={() => {
                setIsTamplateOpen(true)
                clearEmailState()
              }}
              handleSearch={(value) => { setPage(1); setSearch(value); }}
            />
          </Grid>
          {isTamplateOpen && <Grid item xs={12} smm={12} md={12} lg={12}>
            <EmailTemplateConfiguration
              setSelectedVariables={setSelectedVariables}
              selectedVariables={selectedVariables}
              allVariables={allVariables}
              setAllVariables={allVariables}
              data={data}
              setData={setData}
              setSelectedTemplateType={setSelectedTemplateType}
              selectedTemplateType={selectedTemplateType}
              templateTypes={templateTypes}
              setIsTamplateOpen={setIsTamplateOpen}
              isTamplateOpen={isTamplateOpen}
              clearEmailState={clearEmailState}
              handleChange={handleChange}
              _createTemplate={_createTemplate}
              insertVariable={insertVariable}
              onEditorChange={onEditorChange}
              handleHtmlCodeChange={handleHtmlCodeChange}
              htmlCode={htmlCode}
              setHtmlCode={setHtmlCode}
              editorState={editorState}
              setEditorState={setEditorState}
              error={error}
            />
          </Grid>}
          {!isTamplateOpen &&
            <>
              <Grid item xs={12} smm={12} md={12} lg={12}>
                <TableContainer>
                  {!loading ? (
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className={classes.paddedRow}>No</StyledTableCell>
                          <StyledTableCell>Template Name</StyledTableCell>
                          <StyledTableCell align="center">Content</StyledTableCell>
                          <StyledTableCell>Type</StyledTableCell>
                          <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allEmailTemplates?.response?.length > 0 ?
                          allEmailTemplates?.response?.map((row, index) => {
                            console.log(row, "rowrow")
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                <StyledTableCell className={classes.paddedRow}>{row?.templateName}</StyledTableCell>
                                <StyledTableCell>
                                  <Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                    <TextLabel
                                      variant={"body2"}
                                      color={theme.palette.bgLightExtraLightGray.main}
                                      fontWeight={"400"}
                                      textAlign={'start'}
                                      style={{ height: "50px", maxWidth: '600px', overflowY: "auto" }}
                                      title={<Box dangerouslySetInnerHTML={{ __html: row?.content }} display={'flex'} justifyContent={'flex-start'} />}
                                    />
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell>{row?.emailTemplateLayoutDetails?.templateName}</StyledTableCell>
                                <StyledTableCell>
                                  <Box display={"flex"} justifyContent={"center"} gap={1}>
                                    {permissions?.update && <Assets
                                      className={classes.writeBox}
                                      src={"/assets/icons/write.svg"}
                                      absolutePath={true}
                                      onClick={() => { handleEdit(row); }}
                                    />}
                                    {permissions?.delete && (
                                      <Assets
                                        className={classes.deleteBox}
                                        src={"/assets/icons/delete.svg"}
                                        absolutePath={true}
                                        onClick={() => {
                                          _deleteTemplate(row?._id);
                                        }}
                                      />
                                    )}
                                  </Box>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }) :
                          <TableRow>
                            <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  ) : (
                    <WidgetLoader />
                  )}
                </TableContainer>
              </Grid>
              {allEmailTemplates?.count > 0 &&
                <Grid item xs={12}>
                  <CustomPagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={Math.ceil(parseInt(allEmailTemplates?.count) / rowsPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />
                </Grid>
              }
            </>
          }

        </Grid>
      </PaperContainer>
    </>
  );
};

export default EmailConfiguration;
