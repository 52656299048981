import React, { useState } from 'react';
import { makeStyles } from "tss-react/mui";
import { ExpandMore } from '@mui/icons-material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Avatar, Divider, Grid, useTheme } from '@mui/material';
import Assets from '../Common/ImageContainer';
import DataNotFound from '../Common/DataNotFound';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles()((theme) => {
    return {
        accordionList: {
            position: 'relative',
            display: 'block',
            width: '100%',
            height: 'auto',
            padding: '0px',
            margin: 0,
            listStyle: 'none',
        },
        listItem: {
            position: 'relative',
            display: 'block',
            width: '100%',
            height: 'auto',
            backgroundColor: '#F9FAFB',
            padding: '5px',
            border: '1px solid #eee',
            borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: 3
        },
        activeListItem: {
            backgroundColor: '#5d5fef14',
            '& > div > h3:after': {
                transform: 'rotate(45deg)',
            },
        },
        accordionTitle: {
            fontWeight: '580',
            position: 'relative',
            display: 'block',
            width: '100%',
            height: 'auto',
            padding: 0,
            margin: 0,
            fontSize: '14px',
            letterSpacing: '0.01em',
            cursor: 'pointer',
            color: theme.palette.bgDarkBlack.main,
        },
        accordionContent: {
            position: 'relative',
            display: 'block',
            width: '100%',
            height: 'auto',
            margin: 0,
            padding: 0,
            cursor: 'pointer',
        },
        userIcon: {
            borderRadius: "50%",
            padding: "6px",
            backgroundColor: theme.palette.bgLightExtraPrimary.main,
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
        usersIcon: {
            borderRadius: "50%",
            padding: "2px",
            backgroundColor: theme.palette.bgLightExtraPrimary.main,
            color: theme.palette.primary.main,
            cursor: "pointer",
        },
        deleteIcon: {
            borderRadius: "6px",
            padding: "4px",
            backgroundColor: theme.palette.bgLightExtraRed.main,
            color: theme.palette.primary.main,
            cursor: "pointer",
            ":hover": {
                backgroundColor: theme.palette.background.default,
            }
        },
        sendIcon: {
            borderRadius: "6px",
            padding: "5px",
            backgroundColor: theme.palette.bgLightExtraSuccess.main,
            color: theme.palette.primary.main,
            cursor: "pointer",
            ":hover": {
                backgroundColor: theme.palette.backgroundDefaultColor.main,
            }
        },
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '10px',
            fontSize: '12px',
            textAlign: 'end',
            marginTop: 10
        },
        emailContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            overflow: 'hidden',
            padding: 2,
        },
        emailText: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    };
});


const SentEmailList = ({ emailsList, _deleteEmail, _getEmailById }) => {

    const accordionData = emailsList?.response?.length > 0 ? emailsList?.response?.map(item => ({
        title: item.title,
        allUsers: item.emails,
        createdAt: item.createdAt,
        _id: item._id
    })) : [];

    const { classes } = useStyles();
    const [activeIndex, setActiveIndex] = useState(null);
    const theme = useTheme()
    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <ul className={classes.accordionList}>
            {accordionData?.length > 0 ? accordionData?.map((item, index) => {
                return (
                    <li key={index} className={`${classes.listItem} ${activeIndex === index ? classes.activeListItem : ''}`} >
                        <div style={{ display: 'flex' }} onClick={() => handleAccordionClick(index)}>
                            <h3 className={classes.accordionTitle}>{item.title}</h3>
                            {activeIndex === index ? (<ExpandLessOutlinedIcon />) : (<ExpandMoreOutlinedIcon />)}
                        </div>
                        <p style={{ color: theme.palette.backgroundDefaultColor.main, fontSize: '12px' }}>{item?.dateTime}</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginTop: 3 }}>
                                <Assets className={classes.userIcon} src={"/assets/icons/user.svg"} absolutePath={true} />
                                <p style={{ color: theme.palette.primary.main, fontWeight: 600, fontSize: '14px' }}>{item?.allUsers?.length > 5 ? `${item?.allUsers?.length}+` : item?.allUsers?.length}</p>
                                <p style={{ color: theme.palette.bgDarkBlack.main, fontWeight: 550, fontSize: '14px' }}>Users</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 3 }}>
                                <Assets className={classes.deleteIcon} src={"/assets/icons/delete.svg"} absolutePath={true} onClick={() => _deleteEmail(item?._id)} />
                                {/* <Assets className={classes.sendIcon} src={"/assets/icons/send.svg"} absolutePath={true} onClick={() => _getEmailById(item?._id)} /> */}
                            </div>
                        </div>
                        <div className={classes.accordionContent}>
                            {/* <div style={{ borderTop: '2px solid red', width: '100%' }}></div> */}
                            <div className={classes.container}>
                                {activeIndex === index &&
                                    <>
                                        {item?.allUsers?.map((email, i) => {
                                            return (
                                                <div className={classes.emailContainer} key={i}>
                                                    <Avatar src={email} alt={email} sx={{ height: '30px', width: '30px', fontSize: '12px' }} />
                                                    <span className={classes.emailText}>{email}</span>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </div>
                        </div>
                    </li>
                )
            }) : <DataNotFound title={'No Email Found!'} icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />}
        </ul>
    );
};



export default SentEmailList;
