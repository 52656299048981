import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, TableContainer, Box, Grid, useTheme, Chip, IconButton } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import { Regex } from "../../Utils/regex";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import swal from "sweetalert";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Roles, leadStatus, permissionStatus, roles } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import CommonModal from "../../Components/Common/CommonModel";
import Swal from "sweetalert2";
import WidgetLoader from "../../Components/Common/widgetLoader";
import AddLead from "../../Components/Lead";
import CommonButton from "../../Components/Common/Button/CommonButton";
import SendIcon from '@mui/icons-material/Send';
import AssignToReceptionalistModel from "../../Components/Lead/assignToReceptionalistModel";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import CustomPagination from "../../Components/Common/CustomPagination";
import dayjs from "dayjs";
import ConvertToVisitorModel from "../../Components/Lead/convertToVisitorModel";
import AudioFileIcon from '@mui/icons-material/AudioFile';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    padding: 8
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    paddedRow: {
      padding: "15px 10px",
    },
    writeBox: {
      borderRadius: "6px",
      padding: "8px",
      backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
      color: lightTheme.palette.primary.main,
      cursor: "pointer",
    },
    viewBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightSuccess.main,
      backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
      cursor: "pointer",
    },
    deleteBox: {
      borderRadius: "6px",
      padding: "8px",
      color: lightTheme.palette.bgLightRed.main,
      backgroundColor: lightTheme.palette.bgLightExtraRed.main,
      cursor: "pointer",
    },
  };
});

export const leadStatusConfig = [
  { label: "Assigned To Telecaller", status: 1, color: lightTheme.palette.bgGray.main },
  { label: "Assigned To Receptionist", status: 2, color: lightTheme.palette.bgBlue.main },
  { label: "Lead Closed", status: 3, color: lightTheme.palette.error.main },
]

const Lead = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader, user, menuList } = useAppContext();
  const location = useLocation()
  const { pathname } = location
  const theme = useTheme()
  //States
  const [model, setModel] = useState(false);
  const [isAssignReceptionalistModelOpen, setIsAssignReceptionalistModelOpen] = useState(false);
  const [convertToVisitorOpen, setConvertToVisitorOpen] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const [telecallerForLeads, setTelecallerForLeads] = useState([]);
  const [selectedTelecaller, setSelectedTelecaller] = useState("");
  const [receptionalistForLeads, setReceptionalistForLeads] = useState([]);
  const [selectedReceptionalist, setSelectedReceptionalist] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [page, setPage] = useState(1);
  const [permissions, setPermissions] = useState({})
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [isAudioView, setIsAudioView] = useState(false)
  //Validation

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!data?.name) {
      formIsValid = false;
      errors["name"] = "Please enter name.";
    }
    if (!data?.address) {
      formIsValid = false;
      errors["address"] = "Please enter address.";
    }
    if (!selectedCountry) {
      formIsValid = false;
      errors["country"] = "Please select country.";
    }
    if (!selectedState) {
      formIsValid = false;
      errors["state"] = "Please select state.";
    }
    if (!selectedCity) {
      formIsValid = false;
      errors["city"] = "Please select city.";
    }
    if (!data?.postalCode) {
      formIsValid = false;
      errors["postalCode"] = "Please enter Postal Code.";
    } else if (!data?.postalCode?.match(Regex.pinCodeRegex)) {
      formIsValid = false;
      errors["invalidPostalCode"] = "please enter valid postal code";
    }
    if (!data?.mobileNo) {
      formIsValid = false;
      errors["mobileNo"] = "Please enter Contact No.";
    } else if (!data?.mobileNo?.match(Regex.mobileNumberRegex)) {
      formIsValid = false;
      errors["invalidMobile"] = "Please enter valid Contact No.";
    }

    if (data?.email && !data?.email?.match(Regex.emailRegex)) {
      formIsValid = false;
      errors["invalidEmail"] = "* Invalid email Address";
    }
    if (user?.userType == Roles?.Marketing) {
      if (!selectedBranch) {
        formIsValid = false;
        errors["branchName"] = "Please select branch name.";
      }
      if (!selectedTelecaller) {
        formIsValid = false;
        errors["telecaller"] = "Please select telecaller.";
      }
    }
    if (!data?.reason) {
      formIsValid = false;
      errors["reason"] = "Please enter reason.";
    }
    if (!data?.reference) {
      formIsValid = false;
      errors["reference"] = "Please enter reference.";
    }
    setError(errors);
    return formIsValid;
  };

  const receptionlistValidation = () => {
    let formIsValid = true;
    let errors = {};


    if (!selectedReceptionalist) {
      formIsValid = false;
      errors["receptionalist"] = "Please select receptionalist.";
    }

    if (!data?.description) {
      formIsValid = false;
      errors["description"] = "Please enter description.";
    }

    setError(errors);
    return formIsValid;
  };

  const convertToVisitorValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!selectedBranch) {
      formIsValid = false;
      errors["branch"] = "Please select branch.";
    }
    setError(errors);
    return formIsValid;
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };


  const _getDefaultId = (data, name) => {
    return data?.length > 0 && data?.filter((e) => e?.name == name)?.[0]?.id;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };
  const handleClear = () => {
    setData({});
    setModel(false);
    setError({});
    setIsEdit(false);
    setSelectedBranch("");
    setSelectedState("");
    setSelectedCity("");
    _getLeads()
  };

  const _getLeads = async () => {
    setLoading(true)
    let body = {
      limit: rowsPerPage,
      page: page,
      search: search || "",
    };
    await axios.post("/lead", body)
      .then((res) => {
        if (res?.data?.data) { setLeadDetails(res?.data?.data); }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => setLoading(false));
  };

  const _getTelecallerForLead = async () => {
    let body = {
      branch: branches?.filter((e) => e?.branchName == selectedBranch)[0]?._id
    }
    await axios.post("/telecallerForLead", body)
      .then((res) => {
        if (res?.data?.data) {
          setTelecallerForLeads(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };

  const _getReceptionalistForLead = async () => {
    let body = {
      branch: data?.branch?.[0]
    }
    await axios.post("/receptionistForLead", body)
      .then((res) => {
        if (res?.data?.data) {
          setReceptionalistForLeads(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };

  const _assignToReceptionist = () => {
    if (receptionlistValidation()) {
      toggleLoader();
      let body = {
        "id": data?._id,
        "assignToReceptionist": receptionalistForLeads?.response?.filter((e) => e?.name == selectedReceptionalist)[0]?._id,
        "description": data?.description,
        "audioFile": data?.audioFile
      }

      axios.post(`/lead/assign/receptionist`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            setSelectedReceptionalist("");
            setData({});
            setIsAssignReceptionalistModelOpen(false)
            _getLeads();
          }
          toggleLoader();
        })
        .catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    }
  };

  const _convertToVisitor = () => {
    if (convertToVisitorValidation()) {
      setConvertToVisitorOpen(false)
      // e?.stopPropagation()
      Swal.fire({
        title: "<strong>Warning</strong>",
        icon: "warning",
        html: "Are you sure you want to convert to visitor?",
        showCancelButton: true,
        confirmButtonColor: "#0492c2",
        iconColor: "#0492c2",
        confirmButtonText: "Yes",
        cancelButtonColor: "#1A1B2F",
      }).then(async (result) => {
        if (result.isConfirmed) {
          convertToVisitor(data?._id)
        }
      })
    }
  };

  const convertToVisitor = (id) => {
    toggleLoader();
    const body = {
      id: id,
      branch: branches?.filter((e) => e?.branchName == selectedBranch)[0]?._id
    }
    axios.post(`leadConvertIntoVisitor`, body)
      .then((res) => {
        if (res?.data?.data) {
          swal("Success", res?.data?.message, "success");
          _getLeads()
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => { toggleLoader(); });
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    toggleLoader();
    if (file?.size <= 10000000) {
      axios
        .post("/upload/image/attachment", formData)
        .then((res) => {
          if (res?.data?.data) {
            setData((prevState) => ({
              ...prevState,
              audioFile: res?.data?.data?.image,
            }));
          }
        }).catch((err) => {
          toggleLoader();
          OnUpdateError(err.data.message);
        });
    } else {
      setError({ ...error, audioSizeValid: "Upload file allowed size is 10MB", });
    }
    toggleLoader();
  };

  const handleDeleteFile = () => {
    let body = {
      url: data?.audioFile,
    };
    axios
      .post(`/upload/delete_file`, body)
      .then((res) => {
        swal("Success", res?.data?.message, "success");
        setData((prevState) => ({
          ...prevState,
          audioFile: null,
        }));
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };
  const handleEdit = (row) => {
    setData(row);
    setSelectedBranch(row?.branchDetails?.[0]?.branchName || "");
    setSelectedTelecaller(row?.teleCallerDetails?.name || "")
    setSelectedCountry(row?.countryDetail?.name || "");
    setSelectedState(row?.stateDetail?.name || "");
    setSelectedCity(row?.cityDetail?.name || "");
    setIsEdit(true);
    setModel(true);
  };

  const _deleteLead = (deleteId) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to Delete User",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        toggleLoader();
        axios
          .delete(`/lead/delete/${deleteId}`)
          .then((res) => {
            swal("Success", res?.data?.message, "success");
            toggleLoader();
            setDeleteId("");
            _getLeads();
          })
          .catch((err) => {
            toggleLoader();
            OnUpdateError(err.data.message);
          });
      }
    });
  };

  const _addUpdateLead = () => {
    if (handleValidation()) {
      toggleLoader();
      let body = {
        name: data?.name,
        address: data?.address,
        country: _getDefaultId(countries?.response, selectedCountry),
        state: _getDefaultId(states?.response, selectedState),
        city: _getDefaultId(cities?.response, selectedCity),
        postalCode: data?.postalCode,
        mobileNo: data?.mobileNo,
        email: data?.email,
        reference: data?.reference,
        reason: data?.reason
      };
      if (user?.userType == Roles?.Marketing) {
        body.branch = [branches?.filter((e) => e?.branchName == selectedBranch)[0]?._id]
        body.assignToTelecaller = telecallerForLeads?.response?.filter((e) => e?.name == selectedTelecaller)[0]?._id
      }

      if (data?._id) {
        body.id = data?._id;
      }

      axios.post(`/lead/${data?._id ? "update" : "create"}`, body)
        .then((res) => {
          if (res?.data?.data) {
            swal("Success", res?.data?.message, "success");
            handleClear();
          }
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => toggleLoader());
    }
  };

  const _getBranches = (getAllBanches) => {
    let body = {
      limit: rowsPerPage,
      page: page
    };
    axios
      .post(`/branch`, body)
      .then((res) => {
        if (res?.data?.data?.response) {
          setBranches(res?.data?.data?.response);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };


  const _getCountries = () => {
    toggleLoader();
    axios.get("/countries")
      .then((res) => {
        if (res?.data?.data) {
          setCountries(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => {
        toggleLoader();
      });
  };

  const _getStates = () => {
    toggleLoader();
    axios
      .post("/states", {
        country_id: _getDefaultId(countries?.response, selectedCountry),
      })
      .then((res) => {
        if (res?.data?.data) {
          setStates(res?.data?.data);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };

  const _getCities = () => {
    toggleLoader();
    axios
      .post("/cities", {
        state_id: _getDefaultId(states?.response, selectedState),
        country_id: _getDefaultId(countries?.response, selectedCountry),
      })
      .then((res) => {
        if (res?.data?.data) {
          setCities(res?.data?.data);
        }
        toggleLoader();
      })
      .catch((err) => {
        toggleLoader();
        OnUpdateError(err.data.message);
      });
  };






  useEffect(() => {
    _getLeads();
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    _getBranches();
  }, []);

  useEffect(() => {
    if (selectedBranch && model) _getTelecallerForLead();
  }, [selectedBranch]);

  useEffect(() => {
    if (isAssignReceptionalistModelOpen && data?._id) _getReceptionalistForLead();
  }, [isAssignReceptionalistModelOpen]);

  useEffect(() => {
    if (model) {
      _getCountries();
    }
  }, [model]);

  useEffect(() => {
    if (countries?.response && selectedCountry) {
      _getStates();
    }
  }, [countries, selectedCountry]);

  useEffect(() => {
    if (states?.response && selectedCountry && selectedState) {
      _getCities();
    }
  }, [states, selectedState]);

  useEffect(() => {
    const menu = menuList?.find((e) => e?.path === pathname);
    if (menu) {
      const menuPermissions = menu.permissions;
      setPermissions({
        view: menuPermissions.includes(permissionStatus.view) ? true : false,
        create: menuPermissions.includes(permissionStatus.create) ? true : false,
        update: menuPermissions.includes(permissionStatus.update) ? true : false,
        delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
      });
    }
  }, [menuList, location]);
  return (
    <>
      <PaperContainer elevation={0} square={false}>
        <Grid container>
          <Grid item xs={12}>
            <TableHeading
              title="Lead List"
              handleSearch={(value) => { setPage(1); setSearch(value); }}
              buttonText={permissions?.create ? "Add Lead" : ""}
              onClick={() => {
                handleClear();
                setModel(true);
              }} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              {!loading ? (
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className={classes.paddedRow}>No.</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Lead Create Date</StyledTableCell>
                      <StyledTableCell>Address</StyledTableCell>
                      <StyledTableCell>Contact No.</StyledTableCell>
                      <StyledTableCell>Email Id</StyledTableCell>
                      <StyledTableCell>Branch</StyledTableCell>
                      <StyledTableCell>Telecaller</StyledTableCell>
                      <StyledTableCell>Audio File</StyledTableCell>
                      {user?.userType == Roles.Marketing && <StyledTableCell>Assign To</StyledTableCell>}
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leadDetails?.response?.length > 0 ? leadDetails?.response?.map((row, index) => {
                      const getStatusLabel = (status) => {
                        const statusObject = leadStatusConfig.find(item => item.status === status);
                        return statusObject
                      };
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell style={{ paddingLeft: "13px" }}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                          <StyledTableCell className={classes.paddedRow} component="th" scope="row">{row.name}</StyledTableCell>
                          <StyledTableCell>{row.createdAt ? dayjs(row.createdAt).format("DD/MM/YYYY") : "-"}</StyledTableCell>
                          <StyledTableCell>{row?.address}</StyledTableCell>
                          <StyledTableCell>{row?.mobileNo}</StyledTableCell>
                          <StyledTableCell>{row?.email ? row?.email : "-"}</StyledTableCell>
                          <StyledTableCell>{row?.branchDetails?.map((e) => e?.branchName)?.join(",")}</StyledTableCell>
                          <StyledTableCell>{row?.teleCallerDetails ? row?.teleCallerDetails?.name : "-"}</StyledTableCell>
                          <StyledTableCell>
                            {row?.audioFile ?
                              <IconButton
                                onClick={() => { setData(row); setIsAudioView(true) }}
                                sx={{ padding: { xs: '0px', sm: '5px' }, borderRadius: "5px", marginRight: '10px', fontSize: '13px' }}
                              >
                                <AudioFileIcon color="primary" />
                              </IconButton>
                              : "-"
                            }
                          </StyledTableCell>
                          {user?.userType == Roles.Marketing && <StyledTableCell>{row?.teleCallerDetails?.name}</StyledTableCell>}
                          <StyledTableCell align="center">
                            <Chip
                              variant={"outlined"}
                              label={getStatusLabel(row?.status)?.label}
                              style={{ height: '25px', fontWeight: 'normal', color: "white", backgroundColor: getStatusLabel(row?.status)?.color, border: `0.5px solid ${getStatusLabel(row?.status)?.color}` }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box display={"flex"} justifyContent={"center"} gap={1}>
                              {(row?.status === leadStatus?.leadAssignedToReceptionist && user?.userType === Roles?.Receptionist) && <CommonButton
                                width={'190px'}
                                text={`Convert To Visitor`}
                                padding={"5px 2px"}
                                fontSize='13px'
                                onClick={() => { setData(row); setConvertToVisitorOpen(true); _getBranches() }}
                                background={theme.palette.bgLightBlue2.main}
                                endIcon={<PersonPinIcon style={{ fontSize: '25px' }} />}
                              />}

                              {(row?.status === leadStatus?.leadAssignedToTelecaller && user?.userType === Roles?.TeleCaller) && <CommonButton
                                width={'190px'}
                                text={`Assign To Receptionlist`}
                                padding={"5px 2px"}
                                fontSize='12px'
                                onClick={(e) => { setData(row); setIsAssignReceptionalistModelOpen(true); }}
                                background={theme.palette.bgLightBlue2.main}
                                endIcon={<SendIcon fontSize="small" />}
                              />}

                              {(permissions?.update || permissions?.delete) &&
                                (row?.status === leadStatus?.leadAssignedToTelecaller && row?.createdBy === user?._id) && <Box display={"flex"} gap={1}>
                                  <Assets
                                    className={classes.writeBox}
                                    src={"/assets/icons/write.svg"}
                                    absolutePath={true}
                                    onClick={() => { handleEdit(row); }}
                                  />
                                  <Assets
                                    className={classes.deleteBox}
                                    src={"/assets/icons/delete.svg"}
                                    absolutePath={true}
                                    onClick={() => {
                                      _deleteLead(row?._id);
                                    }}
                                  />
                                </Box>}

                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }) :
                      <TableRow>
                        <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              ) : (
                <WidgetLoader />
              )}
            </TableContainer>
          </Grid>
          {leadDetails?.count > 0 &&
            <Grid item xs={12}>
              <CustomPagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={Math.ceil(leadDetails?.count / rowsPerPage)}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
            </Grid>
          }

        </Grid>

        {isAssignReceptionalistModelOpen && <CommonModal
          open={isAssignReceptionalistModelOpen}
          onClose={() => { setSelectedReceptionalist(""); setData({}); setIsAssignReceptionalistModelOpen(false) }}
          title={`Assign To Receptionalist`}
          maxWidth={'md'}
          content={
            <AssignToReceptionalistModel data={data}
              selectedReceptionalist={selectedReceptionalist}
              setSelectedReceptionalist={setSelectedReceptionalist}
              onSubmit={_assignToReceptionist}
              handleUpload={handleUpload}
              handleDeleteFile={handleDeleteFile}
              error={error}
              receptionalistForLeads={receptionalistForLeads}
              handleChange={handleChange} />
          }
        />}

        {convertToVisitorOpen && <CommonModal
          open={convertToVisitorOpen}
          onClose={() => { setSelectedReceptionalist(""); setData({}); setConvertToVisitorOpen(false) }}
          title={`Convert (${data?.name?.toUpperCase()}) To Visitor `}
          maxWidth={'xs'}
          content={
            <ConvertToVisitorModel
              data={data}
              branchList={branches}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              onSubmit={_convertToVisitor}
              error={error}
            />
          }
        />}

        {model && <CommonModal
          open={model}
          onClose={handleClear}
          title={`${isEdit ? "Update" : "Add"} Lead`}
          maxWidth={'md'}
          content={
            <AddLead
              data={data}
              setData={setData}
              error={error}
              handleChange={handleChange}
              branches={branches}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              roles={roles}
              cities={cities}
              states={states}
              onSubmit={_addUpdateLead}
              isEdit={isEdit}
              setSelectedState={setSelectedState}
              selectedState={selectedState}
              setSelectedCity={setSelectedCity}
              selectedCity={selectedCity}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              countries={countries}
              telecallerForLeads={telecallerForLeads}
              selectedTelecaller={selectedTelecaller}
              setSelectedTelecaller={setSelectedTelecaller}
              user={user}
            />
          }
        />}

        {isAudioView && <CommonModal
          open={isAudioView}
          onClose={() => { setData({}); setIsAudioView(false) }}
          title={`Audio File Of ${data?.name}`}
          maxWidth={'xs'}
          content={
            <Grid container spacing={2} p={2}>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <audio class='audioPlayer' controls style={{ height: '50px', width: '100%' }}>  <source src={`${Image_BASE_URL}${data?.audioFile}`} type="audio/mpeg" /></audio>
              </Grid>
            </Grid>
          }
        />}
      </PaperContainer >
    </>
  );
};

export default Lead;
