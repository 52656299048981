import React, { useEffect, useState } from "react";
import { Box, Grid, Pagination, useTheme } from "@mui/material";
import { lightTheme } from "../../theme";
import { makeStyles } from "tss-react/mui";
import axios, { Image_BASE_URL } from "../../APiSetUp/axios";
import CommonSearch from "../../Components/Common/CommonSearch";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import { useAppContext } from "../../Context/context";
import swal from "sweetalert";
import Swal from "sweetalert2";
import SendEmailTemplate from "../../Components/Email/sendEmailTemplate";
import SentEmailList from "../../Components/Email/sentEmailList";
import WidgetLoader from "../../Components/Common/widgetLoader";

const useStyles = makeStyles()((theme) => {
  return {
    emailsList: {
      background: "var(--LightGrey, #F9FAFB)",
      height: "81.7vh",
      overflow: "scroll",
      "::-webkit-scrollbar": {
        width: "0.5px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
    },
    listBox: {
      background: lightTheme.palette.bgWhite.main,
      borderRadius: "20px",
      border: "1px solid #F8F9FA",
      boxShadow: "0px 4px 20px 0px rgba(238, 238, 238, 0.50)",
      alignItems: "center",
    },
    listBoxText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 4,
      minHeight: "50px",
    },
    emailContain: {
      background: "var(--White, #FFF)",
      height: "81.7vh",
      overflow: "scroll",
      "::-webkit-scrollbar": {
        width: "0.5px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
    },
    wordBreak: {
      wordBreak: "break-all",
    },
    assets: {
      cursor: "pointer",
    },
    emailContainer: {
      background: theme.palette.bgWhite.main,
      borderRadius: '2px',
      height: '100%', /* Fallback for browsers that do not support calc */
      height: 'calc(93vh - 65px)', /* Adjust this value as needed */
      padding: 10
    }
  };
});

const Email = () => {
  const { classes } = useStyles();
  const { OnUpdateError, toggleLoader } = useAppContext();
  const theme = useTheme()
  const [data, setData] = useState({ anotherUser: [], selectedTemplateTypeId: "", selectedTemplateId: "", title: "" });
  const [users, setUsers] = useState([]);
  const [multiSelectedUser, setMultiSelectedUser] = useState([]);
  const [selectedTemplateType, setSelectedTemplateType] = useState("");
  const [templateTypes, setTemplateTypes] = useState([]);
  const [allEmailTemplates, setAllEmailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [getEmailData, setGetEmailData] = useState("");
  const [imageData, setImageData] = useState();
  const [pdfData, setPdfData] = useState();
  const [error, setError] = useState({});
  const [emailsList, setEmailsList] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (multiSelectedUser.length <= 0 && !data?.anotherUser) {
      formIsValid = false;
      errors["selectUser"] = "Please select any existing user or another user.";
    }

    if (!data?.title) {
      formIsValid = false;
      errors["title"] = "Please enter title.";
    }
    if (!selectedTemplateType) {
      formIsValid = false;
      errors["selectedTemplateType"] = "Please select template type.";
    }
    if (!selectedTemplate) {
      formIsValid = false;
      errors["selectedTemplate"] = "Please select template.";
    }
    if (!data?.title) {
      formIsValid = false;
      errors["title"] = "Please enter title.";
    }
    setError(errors);
    return formIsValid;
  };

  const _getUsers = async () => {
    await axios.post("users")
      .then((res) => {
        if (res?.data?.data) {
          setUsers(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      });
  };

  const handleClear = () => {
    setData({ anotherUser: [], selectedTemplateTypeId: "", selectedTemplateId: "", title: "" });
    setMultiSelectedUser([]);
    setSelectedTemplate("")
    setSelectedTemplateType("")
    setImageData(null);
    setPdfData(null);
    _getEmailsList();
  }
  console.log("Data after clearing:", data);

  const handleUpload = async (file, key) => {
    const formData = new FormData();
    formData.append("image", file);

    if (file?.size <= 10000000) {
      toggleLoader();
      axios
        .post("/upload/image/attachment", formData)
        .then((res) => {
          if (res?.data?.data) {
            if (key === "image") {
              setImageData(res?.data?.data?.image);
            } else {
              setPdfData(res?.data?.data?.image);
            }
          }
          setError({ ...error, imageSizeValid: "", pdfSizeValid: "" });
        })
        .catch((err) => {
          OnUpdateError(err.data.message);
        }).finally(() => toggleLoader());
    } else {
      if (key === "image") {
        setError({
          ...error,
          imageSizeValid: "Upload file allowed size is 10MB",
        });
      } else {
        setError({
          ...error,
          pdfSizeValid: "Upload file allowed size is 10MB",
        });
      }
    }
  };

  const handleDeleteFile = async (type) => {
    let body = {
      url: type === "image" ? imageData : pdfData,
    };
    toggleLoader();
    await axios.post(`/upload/delete_file`, body)
      .then((res) => {
        swal("Success", res?.data?.message, "success");
        type === "image" ? setImageData(null) : setPdfData(null);
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => toggleLoader());
  };
  const _sendEmail = async () => {
    if (handleValidation()) {
      toggleLoader();
      const selectedUserEmail = multiSelectedUser?.map((item) => item?.email);
      // const anotherUserEmail = data?.anotherUser ? data?.anotherUser?.split(",")?.map((email) => email?.trim()) : [];
      let body = {
        emails: [...selectedUserEmail, ...data?.anotherUser],
        title: data?.title,
        templateId: data?.selectedTemplateId,
        image: imageData ? imageData : "",
        pdf: pdfData ? pdfData : "",
      };
      await axios.post(`send_email`, body).then((res) => {
        if (res?.data?.status == 200) {
          swal("Success", res?.data?.message, "success");
          handleClear()
        }
      }).catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => toggleLoader());
    }
  };

  const _getEmailById = async (id) => {
    if (id) {
      await axios.get(`email/get/${id}`)
        .then((res) => {
          if (res?.data?.data) {
            setGetEmailData(res?.data?.data);
          }
        })
        .catch((err) => {

          OnUpdateError(err.data.message);
        }).finally(() => { });
    }
  };

  const _deleteEmail = async (id) => {
    Swal.fire({
      title: "<strong>Warning</strong>",
      icon: "error",
      html: "Are you sure you want to Delete Email?",
      showCancelButton: true,
      confirmButtonColor: "#0492c2",
      iconColor: "#ff0000",
      confirmButtonText: "Yes",
      cancelButtonColor: "#1A1B2F",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          await axios.delete(`email/delete/${id}`)
            .then((res) => {
              swal("Success", res?.data?.message, "success");
              _getEmailsList();
            })
            .catch((err) => {
              OnUpdateError(err.data.message);
            }).finally(() => { });
        }
      }
    });
  };

  const _getEmailsList = () => {
    setLoading(true)
    let body = {
      limit: 7,
      page: page,
      search: search || "",
    };
    axios.post(`emails_list`, body)
      .then((res) => {
        if (res?.data?.data) {
          setEmailsList(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => { setLoading(false) });
  };

  const _getTemplateTypesAndVariables = () => {
    axios.post(`/email_template_layout`, {})
      .then((res) => {
        if (res?.data?.data) {
          setTemplateTypes(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => { });
  };

  const _getAllEmailTemplate = () => {
    let body = {
      layoutTemplateId: data?.selectedTemplateTypeId
    };
    axios.post(`/email_template`, body)
      .then((res) => {
        if (res?.data?.data) {
          setAllEmailTemplates(res?.data?.data);
        }
      })
      .catch((err) => {
        OnUpdateError(err.data.message);
      }).finally(() => { });
  };

  useEffect(() => {
    _getTemplateTypesAndVariables();
  }, []);

  useEffect(() => {
    if (data?.selectedTemplateTypeId) _getAllEmailTemplate();
  }, [data?.selectedTemplateTypeId]);

  useEffect(() => {
    _getEmailsList();
  }, [page, search]);

  useEffect(() => {
    _getUsers();
  }, []);
  return (
    <>
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Box className={classes.emailContainer}>
            <Grid container spacing={1} item xs={12} sm={12} md={12} lg={12} marginBottom={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CommonSearch handleSearch={(value) => { setPage(1); setSearch(value); }} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <TextLabel title={'Emails'} variant={"h6"} fontWeight={"600"} />
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    <TextLabel title={'Inbox'} variant={"20px"} fontWeight={"400"} color="primary" />
                    <TextLabel title={emailsList?.count} variant={"body2"} fontWeight={"400"} style={{ background: theme.palette.primary.main, padding: '3px 7px', color: theme.palette.bgWhite.main, borderRadius: 10 }} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {loading ? <WidgetLoader /> :
                  <SentEmailList emailsList={emailsList} _deleteEmail={_deleteEmail} _getEmailById={_getEmailById} />}
              </Grid>
              {emailsList?.response?.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Pagination variant="outlined" color="primary" shape="rounded" size="small" count={Math.ceil(emailsList?.count / 7)} page={page} onChange={handleChangePage} />
                </Box>
              </Grid>}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Box className={classes.emailContainer}>
            <SendEmailTemplate
              error={error}
              data={data}
              setData={setData}
              setMultiSelectedUser={setMultiSelectedUser}
              multiSelectedUser={multiSelectedUser}
              users={users}
              handleChange={handleChange}
              setSelectedTemplateType={setSelectedTemplateType}
              setSelectedTemplate={setSelectedTemplate}
              selectedTemplate={selectedTemplate}
              selectedTemplateType={selectedTemplateType}
              allEmailTemplates={allEmailTemplates}
              templateTypes={templateTypes}
              handleDeleteFile={handleDeleteFile}
              handleUpload={handleUpload}
              _sendEmail={_sendEmail}
              imageData={imageData}
              pdfData={pdfData}
              _getEmailById={_getEmailById}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Email;
