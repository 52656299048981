import React from 'react'
import "../../Styles/pageNotFound.css"
const PageNotFound = () => {
    return (
        <>
            <div className='body1'>
                <div class='pageError'>
                    <h1 class='code'>404</h1>
                    <h3 class='not-found'>Page Not Found !!</h3>
                    <h2 class='desc'>Opps... There's something wrong.</h2>
                    <div class="text-btn">
                        <a href="/"><button name="" class="btn btn-outline-primary">BACK TO DASHBOARD</button></a>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PageNotFound