import { useTheme } from "@mui/material";
import React, { PureComponent } from "react";
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, Surface, Symbols, } from "recharts";

export default function CommonAreaChart({ visitorInsights }) {
    console.log(visitorInsights, "visitorInsights")
    const theme = useTheme()


    return (
        <>
            <ResponsiveContainer width="100%" height={340}>
                <AreaChart
                    width={500}
                    height={400}
                    data={visitorInsights}
                    margin={{
                        top: 25,
                        right: 20,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.info.light} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={theme.palette.info.main} stopOpacity={0.2} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="label" fontSize={'12px'} tick={{ fill: theme.palette.bgGray.main }} stroke={theme.palette.info.main} />
                    <YAxis fontSize={'12px'} tick={{ fill: theme.palette.bgGray.main }} stroke={theme.palette.info.main} />
                    <Tooltip contentStyle={{ fontSize: '14px', width: 'auto', backgroundColor: theme.palette.bgLightExtraGray.main, border: `1px solid ${theme.palette.bgLightGray.main}` }} />
                    <Legend iconType="square" verticalAlign='top'/>

                    <Area type="monotone" dataKey="value" name="Visitor" stroke={theme.palette.info.main} fill="url(#areaGradient)" />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
}
