import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Table, TableRow, TableHead, Box, Grid, useTheme, TableContainer, Typography, } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Assets from "../../Components/Common/ImageContainer";
import PaperContainer from "../../Components/Common/PaperContainer";
import TableHeading from "../../Components/Common/CommonTableHeading";
import CommonPagination from "../../Components/Common/Pagination";
import { lightTheme } from "../../theme";
import { useAppContext } from "../../Context/context";
import axios from "../../APiSetUp/axios";
import dayjs from "dayjs";
import CommonModal from "../../Components/Common/CommonModel";
import swal from "sweetalert";
import { Roles, permissionStatus } from "../../Utils/enum";
import { useLocation } from "react-router-dom";
import DataNotFound from "../../Components/Common/DataNotFound";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddNewFile from "../../Components/New File";

import { globalAmountConfig } from "../../Utils/globalConfig";

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TextLabel from "../../Components/Common/Fields/TextLabel";
import CommonButton from "../../Components/Common/Button/CommonButton";
import Swal from "sweetalert2";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import WidgetLoader from "../../Components/Common/widgetLoader";
import GenerateIdModel from "../../Components/New File/generateIdModel";
import CustomPagination from "../../Components/Common/CustomPagination";
import { Regex } from "../../Utils/regex";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `3px solid ${"#FFF"}`,
    '&:not(:last-child)': { borderBottom: 0, },
    '&::before': { display: 'none', },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.5rem' }} />}    {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : "#79797914",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    padding: theme.spacing(0)
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0.5),
    borderTop: '0px solid rgba(0, 0, 0, .125)',
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        fontSize: 12,
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        padding: 5,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        fontFamily: "Poppins",
        fontWeight: 500,
        padding: "8px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => {
    return {
        paddedRow: {
            padding: "15px 10px",
        },
        writeBox: {
            borderRadius: "6px",
            padding: "6px",
            backgroundColor: lightTheme.palette.bgLightExtraPrimary.main,
            color: lightTheme.palette.primary.main,
            cursor: "pointer",
            height: '26px'
        },
        viewBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightSuccess.main,
            backgroundColor: lightTheme.palette.bgLightExtraSuccess.main,
            cursor: "pointer",
            height: '26px'
        },
        deleteBox: {
            borderRadius: "6px",
            padding: "6px",
            color: lightTheme.palette.bgLightRed.main,
            backgroundColor: lightTheme.palette.bgLightExtraRed.main,
            cursor: "pointer",
            height: '26px'
        },
    };
});
const uploadDocuments = [
    {
        image: "",
        key: "aadharcard",
        title: "Aadhar Card"
    },
    {
        image: "",
        key: "pancard",
        title: "Pan Card"
    },
    {
        image: "",
        key: "nomineeAadharCard",
        title: "Nominee Aadhar Card"
    },
    {
        image: "",
        key: "paymentReceipt",
        title: "Payment Receipt"
    },
    {
        image: "",
        key: "bankDetails",
        title: "Bank Details"
    },
    {
        image: "",
        key: "passportPhoto",
        title: "Passport Photo"
    },
    {
        image: "",
        key: "concernLetter",
        title: "Concern Letter"
    },
]

const NewFile = () => {
    //Hooks
    const { classes } = useStyles();
    const { OnUpdateError, toggleLoader, menuList, user } = useAppContext();
    const location = useLocation();
    const { pathname } = location;
    const theme = useTheme()
    //States
    const [data, setData] = useState({ assignfilesDetails: [{ _id: null, investment: "", investmentDays: "", returnOfPercentage: "", planDate: null }] });
    const [newFileDetails, setNewFileDetails] = useState([]);
    const [model, setModel] = useState(false);
    const [generateIdModel, setGenerateIdModel] = useState(false);
    const [error, setError] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [newFileId, setNewFileId] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [permissions, setPermissions] = useState({});
    const [clients, setClients] = useState([]);
    const [accountants, setAccountants] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedAccountant, setSelectedAccountant] = useState("");
    const [expanded, setExpanded] = React.useState('panel1');
    const [loading, setLoading] = useState(false)
    const [documents, setDocuments] = useState(uploadDocuments)

    const handleAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const closeDate = (date, days) => {
        const initialDate = new Date(date);
        const newDate = new Date(initialDate);
        newDate.setDate(initialDate.getDate() + days);
        newDate.setUTCHours(0, 0, 0, 0);
        const formattedDate = newDate.toISOString();
        return dayjs(formattedDate).format("DD/MM/YYYY");
    };

    //Validation
    const handleValidation = () => {
        let formIsValid = true;
        let errors = {};
        if (!isEdit) {
            if (!selectedClient) {
                formIsValid = false;
                errors["selectedClient"] = "*Please select client.";
            }
            if (!selectedAccountant) {
                formIsValid = false;
                errors["selectedAccountant"] = "*Please select accountant.";
            }
        }
   
        if (!data?.nomineeMobileNo) {
            formIsValid = false;
            errors["nomineeMobileNo"] = "*Please enter nominee mobile no.";
        } else if (!data?.nomineeMobileNo?.match(Regex.mobileNumberRegex)) {
            formIsValid = false;
            errors["invalidMobile"] = "*Please enter valid contact number.";
        }

        if (!data?.nomineeName) {
            formIsValid = false;
            errors["nomineeName"] = "*Please enter nominee name.";
        }

        data?.assignfilesDetails?.map((e) => {

            if (!e?.investment) {
                formIsValid = false;
                errors["investment"] = "* Please enter investment.";
            }
            if (!e?.investmentDays) {
                formIsValid = false;
                errors["investmentDays"] = "* Please enter investment days.";
            }
            if (!e?.returnOfPercentage) {
                formIsValid = false;
                errors["returnOfPercentage"] = "* Please enter return of investment.";
            }
            if (generateIdModel) {
                if (!e?.planDate) {
                    formIsValid = false;
                    errors["planDate"] = "* Please select plan date";
                }
            }

        });
        const requiredDocs = ["aadharcard", "nomineeAadharCard"];
        documents?.forEach((doc) => {
            if (requiredDocs.includes(doc.key) && !doc.image) {
                formIsValid = false;
                errors[doc.key] = `* Please upload ${doc.title}.`;
            }
        });
        setError(errors);
        return formIsValid;
    };
    console.log(documents, error, "documents")

    const handleChange = (e, isInvestmentPlan, i) => {
        const { name, value } = e.target;

        if (isInvestmentPlan && name === 'returnOfPercentage') {
            if (value !== "" && (isNaN(value) || value < 0 || value > 100)) {
                return;
            }
        }
        if (isInvestmentPlan && name === 'investmentDays') {
            if (value !== "" && (isNaN(value) || value < 0 || value > 99999)) {
                return;
            }
        }
        if (isInvestmentPlan === true) {
            const modifyData = { ...data };
            if (modifyData.assignfilesDetails && modifyData.assignfilesDetails[i]) {
                modifyData.assignfilesDetails[i][name] = value?.replace(/,/g, '');
            }
            setData(modifyData);
        } else {
            setData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };


    const handleUpload = async (file, key) => {

        const formData = new FormData();
        formData.append("image", file);
        toggleLoader();
        if (file?.size <= 10000000) {
            axios
                .post("/upload/image/attachment", formData)
                .then((res) => {
                    console.log(res, "resresres")
                    if (res?.data?.data) {
                        setDocuments((prevDocs) =>
                            prevDocs.map((doc) =>
                                doc.key === key ? { ...doc, image: res.data.data.image } : doc
                            )
                        );
                        console.log(documents, "documents")

                    }
                }).catch((err) => {
                    toggleLoader();
                    OnUpdateError(err.data.message);
                });
        } else {
            setError({ ...error, audioSizeValid: "Upload file allowed size is 10MB", });
        }
        toggleLoader();
    };
    const handleDeleteFile = async (url, key) => {
        let body = {
            url: url
        };
        toggleLoader();
        await axios.post(`/upload/delete_file`, body)
            .then((res) => {
                swal("Success", res?.data?.message, "success");
                setDocuments((prevDocs) =>
                    prevDocs.map((doc) =>
                        doc.key === key ? { ...doc, image: "" } : doc
                    )
                );
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => toggleLoader());
    };
    const setUserPurchasePlanDelete = (i) => {
        const modifyData = { ...data };
        if (modifyData.assignfilesDetails && modifyData.assignfilesDetails.length > i) {
            modifyData.assignfilesDetails.splice(i, 1);
            setData(modifyData);
        }
    };

    const setUserPurchasePlanAdd = () => {
        setData({
            ...data,
            assignfilesDetails: [
                ...data?.assignfilesDetails,
                { _id: null, investment: "", investmentDays: "", returnOfPercentage: "" },
            ],
        });
    };

    const handleClear = () => {
        setModel(false);
        setData({ assignfilesDetails: [{ _id: null, investment: "", investmentDays: "", returnOfPercentage: "", planDate: null }] });
        setError({});
        setIsEdit(false);
        setGenerateIdModel(false)
        setNewFileId("");
        setSelectedClient("");
        setSelectedAccountant('')
        setDocuments(uploadDocuments)
        _getNewFiles()
    };

    const _getUsers = async () => {
        let body = {
            userType: [6]
        }
        await axios.post("/userPurchasePlan/userList", body)
            .then((res) => {
                if (res?.data?.data) {
                    setClients(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            });
    };
    const _getAccountants = async () => {
        await axios.post("/userPurchasePlan/accountantList")
            .then((res) => {
                if (res?.data?.data) {
                    setAccountants(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            });
    };

    const _getNewFiles = async () => {
        setLoading(true)
        let body = {
            limit: rowsPerPage,
            page: page,
            search: search || "",
        };
        await axios.post(`/assignFiles/get`, body)
            .then((res) => {
                if (res?.data?.data) {
                    setNewFileDetails(res?.data?.data);
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            }).finally(() => setLoading(false));
    };

    const _getNewFIleById = () => {
        axios
            .get(`assignFiles/${newFileId}`)
            .then((res) => {
                if (res?.data?.data) {
                    setIsEdit(true);
                    setData(res?.data?.data);
                    setSelectedClient(res?.data?.data?.name);
                    setSelectedAccountant(res?.data?.data?.accountantDetails?.name)
                    setDocuments(res?.data?.data?.documents)
                }
            })
            .catch((err) => {
                OnUpdateError(err.data.message);
            });
    };


    const _deleteUserPlanById = async (id) => {
        toggleLoader();
        await axios.delete(`assignFiles/delete/${id}`)
            .then(async (res) => {
                if (res?.data?.status === 200 && newFileId) {
                    await _getNewFIleById()
                    toggleLoader();
                    swal("Success", res?.data?.message, "success");
                    await _getNewFiles()
                }
            })
            .catch((err) => {
                toggleLoader();
                OnUpdateError(err.data.message);
            });
    }
    console.log(clients, data, "clients")
    const _generateCredential = () => {
        if (handleValidation()) {
            toggleLoader();
            const planData = data?.assignfilesDetails.map(plan => {
                return {
                    id: plan?._id,
                    investment: plan?.investment,
                    returnOfPercentage: plan?.returnOfPercentage,
                    investmentDays: plan?.investmentDays,
                    planDate: plan?.planDate,
                };
            });
            let body = {
                clientId: clients?.filter((e) => e?.name === selectedClient)[0]?._id,
                assignToAccountantId: accountants?.filter((e) => e?.name === selectedAccountant)[0]?._id,
                assignFileList: planData
            };
            axios.post(`generateId`, body)
                .then((res) => {
                    if (res?.data?.data) {
                        swal("Success", res?.data?.message, "success");
                        handleClear();
                    }
                })
                .catch((err) => {
                    OnUpdateError(err.data.message);
                }).finally(() => toggleLoader());
        }
    };
    const _addUpdateNewFile = () => {
        if (handleValidation()) {
            console.log(clients, selectedClient, "clients")
            toggleLoader();
            const planData = data?.assignfilesDetails.map(plan => {
                return {
                    id: plan?._id,
                    investment: plan?.investment,
                    returnOfPercentage: plan?.returnOfPercentage,
                    investmentDays: plan?.investmentDays,
                };
            });
            let body = {
                clientId: clients?.filter((e) => e?.name === selectedClient)[0]?._id,
                assignToAccountantId: accountants?.filter((e) => e?.name === selectedAccountant)[0]?._id,
                assignFileList: planData,
                documents: documents,
                nomineeName: data?.nomineeName,
                nomineeMobileNo: data?.nomineeMobileNo,

            };
            axios.post(`assignFiles/${data?._id ? "update" : "create"}`, body)
                .then((res) => {
                    if (res?.data?.data) {
                        swal("Success", res?.data?.message, "success");
                        handleClear();
                    }
                }).catch((err) => {
                    OnUpdateError(err.data.message);
                }).finally(() => toggleLoader());
        }
    };


    useEffect(() => {
        _getNewFiles();
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        if (newFileId) {
            _getNewFIleById();
        }
    }, [newFileId]);

    useEffect(() => {
        if (model || generateIdModel) {
            _getUsers();
            _getAccountants()
        }
    }, [model, generateIdModel]);

    useEffect(() => {
        const menu = menuList?.find((e) => e?.path === pathname);
        if (menu) {
            const menuPermissions = menu.permissions;
            setPermissions({
                view: menuPermissions.includes(permissionStatus.view) ? true : false,
                create: menuPermissions.includes(permissionStatus.create) ? true : false,
                update: menuPermissions.includes(permissionStatus.update) ? true : false,
                delete: menuPermissions.includes(permissionStatus.delete) ? true : false,
            });
        }
    }, [menuList, location]);
    return (
        <>
            <PaperContainer elevation={0} square={false}>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid item xs={12}>
                        <TableHeading
                            title={"New File"}
                            buttonText={permissions?.create ? `Add New File` : ""}
                            onClick={() => setModel(true)}
                            handleSearch={(value) => { setPage(1); setSearch(value); }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {!loading ?
                            newFileDetails?.response?.length > 0 ? newFileDetails?.response?.map((val, index) => {
                                const sumOfInvestment = val.assignfilesDetails?.reduce((total, plan) => { return total + plan.investment }, 0);
                                return <Accordion expanded={expanded === index + 'panel1'} onChange={handleAccordian(index + 'panel1')} >
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowDownwardIcon sx={{ marginLeft: 3, marginRight: 3, fontSize: '1rem', color: theme.palette.primary.main }} />}>
                                        <Box display={'flex'} alignItems={'center'} width="100%" justifyContent="space-between">
                                            <Box display={'flex'} alignItems={'center'} flexShrink={0} width={{ xl: '140px', lg: "180px", md: '140px', sm: '120px', xs: '120px' }}>
                                                <PersonOutlineIcon color="primary" fontSize="small" />
                                                <Typography style={{ color: theme.palette.primary.main, fontSize: '14px' }} variant="body2" noWrap>
                                                    {val?.name?.toUpperCase()}
                                                </Typography>
                                            </Box>

                                            <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={{ xl: 8, lg: 8, md: 4, sm: 3, xs: 2 }}>
                                                <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                                                    <Box>
                                                        <TextLabel fontSize={"12px"} title={"Total Plan"} textAlign={'start'} line />
                                                        <TextLabel fontSize={"12px"} title={val.assignfilesDetails?.length} textAlign={'start'} fontWeight={600} />
                                                    </Box>
                                                    <Box>
                                                        <TextLabel fontSize={"12px"} title={"Total Investment"} textAlign={'start'} line />
                                                        <TextLabel fontSize={"12px"} title={globalAmountConfig(sumOfInvestment) || 0} textAlign={'start'} fontWeight={600} />
                                                    </Box>
                                                    <Box>
                                                        <TextLabel fontSize={"12px"} title={"Status"} textAlign={'start'} line />
                                                        <TextLabel fontSize={"12px"} color={val?.isGenerateId ? theme.palette.bgLightSuccess.main : theme.palette.bgLightBlue2.main} title={val?.isGenerateId ? "Generated" : "Pending"} textAlign={'center'} />
                                                    </Box>
                                                    <Box display={"flex"} gap={1} alignItems={'center'} justifyContent={'right'}>
                                                        {permissions?.update && (
                                                            <Assets className={classes.writeBox}
                                                                src={"/assets/icons/write.svg"}
                                                                absolutePath={true}
                                                                onClick={() => {
                                                                    // setData(val);
                                                                    setNewFileId(val?._id);
                                                                    setIsEdit(true);
                                                                    setModel(true);
                                                                }}
                                                            />
                                                        )}
                                                        {user?.userType == Roles.Accountant && <CommonButton
                                                            width={'90px'}
                                                            text={`Generate ID`}
                                                            padding={"2px 2px"}
                                                            fontSize='11px'
                                                            onClick={(e) => {
                                                                setGenerateIdModel(true)
                                                                setNewFileId(val?._id);
                                                            }}
                                                        />}
                                                    </Box>
                                                </Box>

                                            </Box>

                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer>
                                            {val?.assignfilesDetails?.length > 0 ? (
                                                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>No.</StyledTableCell>
                                                            <StyledTableCell align="center"> Investment Days </StyledTableCell>
                                                            <StyledTableCell align="center"> Return Amount Of Interest </StyledTableCell>
                                                            <StyledTableCell align="center"> Investment Amount </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {val?.assignfilesDetails?.length > 0 ?
                                                            val?.assignfilesDetails?.map((row, index) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <StyledTableCell style={{ paddingLeft: "15px" }}>{(index % rowsPerPage) + 1 + (page - 1) * rowsPerPage}</StyledTableCell>
                                                                        <StyledTableCell align="center">{row?.investmentDays}</StyledTableCell>
                                                                        <StyledTableCell align="center">{`${row?.returnOfInvestment}(${row?.returnOfPercentage}%)`}</StyledTableCell>
                                                                        <StyledTableCell align="center">{row?.investment ? globalAmountConfig(row?.investment || "0") : 0}</StyledTableCell>
                                                                    </StyledTableRow>
                                                                );
                                                            }) : <TableRow>
                                                                <TableCell colSpan={12}> <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />
                                                                </TableCell>
                                                            </TableRow>}
                                                    </TableBody>
                                                </Table>
                                            ) : (<DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} elevation={0} />)}
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }) :
                                <Grid item xs={12}>
                                    <DataNotFound icon={<ErrorOutlineIcon color="primary" style={{ fontSize: "3rem" }} />} title={'No New File Found!'} elevation={0} />
                                </Grid> : <WidgetLoader />}
                    </Grid>
                </Grid>
                {newFileDetails?.count > 0 &&
                    <Grid item xs={12}>
                        <CustomPagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={Math.ceil(newFileDetails?.count / rowsPerPage)}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    </Grid>
                }
            </PaperContainer>
            {model && (
                <CommonModal
                    open={model}
                    onClose={handleClear}
                    title={`${isEdit ? "Update" : "Add"} New File`}
                    maxWidth={'md'}
                    content={
                        <AddNewFile
                            data={data}
                            setData={setData}
                            error={error}
                            handleChange={handleChange}
                            isEdit={isEdit}
                            onSubmit={_addUpdateNewFile}
                            setSelectedClient={setSelectedClient}
                            selectedClient={selectedClient}
                            setSelectedAccountant={setSelectedAccountant}
                            selectedAccountant={selectedAccountant}
                            clients={clients}
                            accountants={accountants}
                            user={user}
                            setUserPurchasePlanDelete={setUserPurchasePlanDelete}
                            setUserPurchasePlanAdd={setUserPurchasePlanAdd}
                            deleteUserPlan={_deleteUserPlanById}
                            documents={documents}
                            handleUpload={handleUpload}
                            handleDeleteFile={handleDeleteFile}
                        />
                    }
                />
            )}
            {generateIdModel && (
                <CommonModal
                    open={generateIdModel}
                    onClose={handleClear}
                    title={`Generate New File`}
                    maxWidth={'md'}
                    content={
                        <GenerateIdModel
                            data={data}
                            setData={setData}
                            error={error}
                            handleChange={handleChange}
                            isEdit={isEdit}
                            onSubmit={_generateCredential}
                            setSelectedClient={setSelectedClient}
                            selectedClient={selectedClient}
                            setSelectedAccountant={setSelectedAccountant}
                            selectedAccountant={selectedAccountant}
                            clients={clients}
                            accountants={accountants}
                            user={user}
                            setUserPurchasePlanDelete={setUserPurchasePlanDelete}
                            setUserPurchasePlanAdd={setUserPurchasePlanAdd}
                            deleteUserPlan={_deleteUserPlanById}
                        />
                    }
                />
            )}
        </>
    )
}

export default NewFile