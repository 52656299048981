import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from './Pages/Login';
import Loader from './Components/Loader';
import { ThemeProvider } from "@mui/material";
import { lightTheme } from "./theme";
import Register from "./Pages/Register";
import User from "./Pages/User";
import MeetingList from "./Pages/Meeting";
import OfferPage from "./Pages/Offer";
import Branches from "./Pages/Branches";
import Visitor from "./Pages/Visitor";
import FinancialData from "./Pages/FinancialData";
import Email from "./Pages/Email";
import Reminder from "./Pages/Reminder";
import NewFile from "./Pages/NewFile";
import Profile from "./Pages/Profile";
import Dashboard from "./Pages/Dashboard";
import { ProtectedRoutes } from "./Routes/ProtectedRoutes";
import Clients from "./Pages/Clients";
import OTPverification from "./Pages/OTPverification";
import ForgetPassword from "./Pages/ForgetPassword";
import Resetpassword from "./Pages/Resetpassword";
import VisitorHistory from "./Pages/VisitorHistory";
import Lead from "./Pages/Lead";
import Setting from "./Pages/EmailConfiguration";
import InitiateRefund from "./Pages/InitiateRefund";
import Expenses from "./Pages/Expenses";
import ClientDataForm from "./Pages/Client-Form";
import Feedback from "./Pages/Feedback";
import { useAppContext } from "./Context/context";
import { Roles } from "./Utils/enum";
import FeedbackDetails from "./Pages/FeedbackDetails";
import PageNotFound from "./Components/Common/pageNotFound";

function App() {
  const { user } = useAppContext();
  return (
    <BrowserRouter basename={"/"}>
      <ThemeProvider theme={lightTheme}>
        <Loader />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/otp-verification" element={<OTPverification />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={user?.userType === Roles.Feedback ? <Feedback /> : <Dashboard />} />
            <Route path="/user" element={<User />} />
            <Route path="/meeting" element={<MeetingList />} />
            <Route path="/offer" element={<OfferPage />} />
            <Route path="/branches" element={<Branches />} />
            <Route path="/visitor" element={<Visitor />} />
            <Route path="/financial-data" element={<FinancialData />} />
            <Route path="/email" element={<Email />} />
            <Route path="/reminder" element={<Reminder />} />
            <Route path="/new-file" element={<NewFile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/client" element={<Clients />} />
            <Route path="/visitor-history" element={<VisitorHistory />} />
            <Route path="/lead" element={<Lead />} />
            <Route path="/emailConfiguration" element={<Setting />} />
            <Route path="/initiateRefund" element={<InitiateRefund />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path="/client-form" element={<ClientDataForm />} />
            <Route path="/feedback" element={<FeedbackDetails />} />
          </Route>
          <Route path="/not-found" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;