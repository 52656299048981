import React from "react";
import { Autocomplete, Box, Chip, Grid, InputLabel, TextField, useTheme, } from "@mui/material";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import CommonButton from "../../Components/Common/Button/CommonButton";
import CommonTextField from "../../Components/Common/Fields/TextField";
import FileUpload from "../../Components/Common/uploadButton";
import AutoCompleteMultiSelect from "../../Components/Common/AutoCompleteMultiSelect";
import AutoCompleteSearch from "../../Components/Common/commonAutoComplete";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
    return {
        main: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '12px',
            minWidth: "50px",
            ".MuiInputBase-input": {
                height: "26px",
                fontSize: '14px',
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.bgLightBlack?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: '8px',
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-formControl.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
                opacity: 0.8
            }
        },
    };
});
const SendEmailTemplate = ({ error, data, setData, setMultiSelectedUser, multiSelectedUser, users, setSelectedTemplateType, setSelectedTemplate, selectedTemplate, selectedTemplateType,
    allEmailTemplates, templateTypes, handleDeleteFile, handleUpload, _sendEmail, imageData, pdfData, handleChange, _getEmailById }) => {
    const theme = useTheme()
    const { classes, cx } = useStyles();
    return (
        <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutoCompleteMultiSelect
                    fullWidth
                    text="Select User"
                    options={users?.response || []}
                    placeholder={"Select User"}
                    handleChange={(e, newValue) => setMultiSelectedUser(newValue)}
                    name="name"
                    getOptionLabel={(option) => option.name}
                    defaultValue={multiSelectedUser || {}}
                    mappingLabel="name"
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={(multiSelectedUser?.length <= 0 && !data?.anotherUser) ? error?.selectUser : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel sx={{ marginRight: "3px", marginTop: 0.7, fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Another User</InputLabel>
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={data?.anotherUser || []}
                    className={cx(classes.main)}
                    freeSolo
                    value={data?.anotherUser}
                    onChange={(e, newValue) => {
                        setData({ ...data, anotherUser: newValue })
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (<Chip variant="outlined" color="primary" size="small" style={{ background: "#d6d7ff" }} label={option} {...getTagProps({ index })} />))
                    }
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={"ex. example@gmail.com ↵"}
                                value={data?.anotherUser}
                                defaultValue={data?.anotherUser}
                            />
                        );
                    }}
                    sx={{ borderRadius: '1px', backgroundColor: "white", }}
                    clearOnSelect
                    size="small"

                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <CommonTextField
                    fontWeight={400}
                    text={"Title"}
                    placeholder={"Please enter title"}
                    type="text"
                    name="title"
                    value={data?.title}
                    onChange={(e) => handleChange(e, false)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.title ? error?.title : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutoCompleteSearch
                    fullWidth
                    backgroundColor="white"
                    text="Select Template Type"
                    handleChange={(e, newValue) => {
                        setSelectedTemplateType(newValue);
                        setData((prevState) => ({
                            ...prevState,
                            selectedTemplateTypeId: templateTypes?.response?.length > 0 ? templateTypes?.response?.filter((e) => e?.templateName == newValue)[0]?._id : null,
                        }));
                        setSelectedTemplate("")
                        setData((prevState) => ({ ...prevState, selectedTemplateId: null, }));
                    }}
                    options={templateTypes?.response?.map((e) => e?.templateName) || []}
                    name="label"
                    defaultValue={selectedTemplateType}
                    freeSolo
                    blurOnSelect
                    placeholder={"Select Template Type"}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedTemplateType ? error?.selectedTemplateType : ""} />
            </Grid>
            {selectedTemplateType && <Grid item xs={12} sm={12} md={6} lg={6}>
                <AutoCompleteSearch
                    fullWidth
                    backgroundColor="white"
                    text="Select Template"
                    handleChange={(e, newValue) => {
                        setSelectedTemplate(newValue);
                        setData((prevState) => ({
                            ...prevState,
                            selectedTemplateId: allEmailTemplates?.response?.length > 0 ? allEmailTemplates?.response?.filter((e) => e?.templateName == newValue)[0]?._id : null,
                        }));
                    }}
                    options={allEmailTemplates?.response?.map((e) => e?.templateName) || []}
                    name="label"
                    defaultValue={selectedTemplate}
                    freeSolo
                    blurOnSelect
                    placeholder={"Select Template"}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!selectedTemplate ? error?.selectedTemplate : ""} />
            </Grid>}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputLabel sx={{ fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Upload Image</InputLabel>
                </Grid>
                <FileUpload
                    handleFileChange={(e) => {
                        console.log(e.target.files, "e.target.files")
                        handleUpload(e.target.files[0], "image");
                    }}
                    selectedFile={imageData}
                    OnDelate={() => handleDeleteFile("image")}
                    acceptFile="image/png, image/gif, image/jpeg"
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.imageSizeValid} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputLabel sx={{ fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>Upload PDF</InputLabel>
                </Grid>
                <FileUpload
                    text={"Upload PDF"}
                    handleFileChange={(e) => {
                        handleUpload(e.target.files[0], "pdf");
                    }}
                    selectedFile={pdfData}
                    OnDelate={() => handleDeleteFile("pdf")}
                    acceptFile="application/pdf , application/msword"
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={error?.pdfSizeValid} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box display={"flex"} justifyContent={"center"} mt={"35px"}>
                    <CommonButton
                        width={"200px"}
                        text={`Send`}
                        type="submit"
                        onClick={() => _sendEmail()}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default SendEmailTemplate