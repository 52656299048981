import React from 'react'
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { useTheme } from '@mui/styles';
import { makeStyles } from "tss-react/mui";
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import CommonButton from '../../Components/Common/Button/CommonButton';
import { globalAmountConfig } from '../../Utils/globalConfig';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
const useStyles = makeStyles()((theme) => {
    return {
        dateBox: {
            background: theme?.palette?.bgWhite?.main,
            borderRadius: '8px',
            minWidth: "50px",
            "& .MuiOutlinedInput-root": {
                borderRadius: "5px !important",
            },
            "& .MuiOutlinedInput-input": {
                padding: "12.5px 14px",
                fontSize: "14px !important",
                height: "18px",
            },
            "&:hover": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.backgroundDefaultColor?.main} !important`,
                borderRadius: "8px",
            },
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme?.palette?.primary?.main} !important`,
                borderWidth: "1px !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                borderRadius: '8px',
            }
        },
        customLabel: {
            "& .MuiTypography-root": {
                fontSize: "15px",
                color: "#151D48",
            },
        },
    };
});
const AddExpenses = ({ data, error, handleChange, isEdit, onSubmit, setData }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    return (
        <Grid container spacing={4} xs={12} md={12} lg={12} sm={12} p={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box mt={0.5}>
                        <InputLabel sx={{ marginRight: "3px", fontWeight: 400, fontSize: "14px", color: theme.palette.bgDarkPrimary.main, padding: '3px', }}>
                            Select Month
                        </InputLabel>
                    </Box>
                    <DesktopDatePicker
                        className={classes.dateBox}
                        inputFormat="MM/DD/YYYY"
                        views={['year', 'month']}
                        value={dayjs(data?.month)}
                        onChange={(newValue) => { setData({ ...data, month: newValue }); }}
                        renderInput={(params) => <TextField {...params} disabled />}
                        disablePast
                    />
                </LocalizationProvider>
                <TextLabel fontSize={"10px"} color={"red"} fontWeight={"400"} title={!data?.month ? error?.month : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} >
                <CommonTextField
                    fontWeight={400}
                    text={'Cash Balance'}
                    placeholder={"Enter Cash Balance"}
                    type='text'
                    name='cashExpense'
                    value={globalAmountConfig(data?.cashExpense)}
                    onChange={(e) => handleChange(e)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.cashExpense ? error?.cashExpense : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} >
                <CommonTextField
                    fontWeight={400}
                    text={'Bank Balance'}
                    placeholder={"Enter Bank Balance"}
                    type='text'
                    name='bankExpense'
                    value={globalAmountConfig(data?.bankExpense)}
                    onChange={(e) => handleChange(e)}
                />
                <TextLabel fontSize={"12px"} color={"red"} fontWeight={"400"} title={!data?.bankExpense ? error?.bankExpense : ""} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CommonButton
                        width={'160px'}
                        text={`${isEdit ? "Update" : "Add"} Expense`}
                        type="submit"
                        onClick={onSubmit}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddExpenses